const abi = [
  {
    inputs: [{ internalType: 'address', name: 'target', type: 'address' }],
    name: 'AddressEmptyCode',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'AddressInsufficientBalance',
    type: 'error',
  },
  { inputs: [], name: 'FailedInnerCall', type: 'error' },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'SafeERC20FailedOperation',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'actual', type: 'uint256' },
      { internalType: 'uint256', name: 'expected', type: 'uint256' },
    ],
    name: 'StrykeAssetActions__RedeemAmount0BelowMin',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'actual', type: 'uint256' },
      { internalType: 'uint256', name: 'expected', type: 'uint256' },
    ],
    name: 'StrykeAssetActions__RedeemAmount1BelowMin',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'actual', type: 'uint256' },
      { internalType: 'uint256', name: 'expected', type: 'uint256' },
    ],
    name: 'StrykeAssetActions__RedeemReceiveTokenAmountBelowMin',
    type: 'error',
  },
  { inputs: [], name: 'StrykeAssetActions__RedeemReceiveTokenCannotBeNativeToken', type: 'error' },
  { inputs: [], name: 'StrykeAssetActions__RedeemSwapReceiveTokenNotTokenPair', type: 'error' },
  {
    inputs: [
      { internalType: 'uint256', name: 'actual', type: 'uint256' },
      { internalType: 'uint256', name: 'expected', type: 'uint256' },
    ],
    name: 'SwapActions__AmountInMaxExceeded',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'actual', type: 'uint256' },
      { internalType: 'uint256', name: 'expected', type: 'uint256' },
    ],
    name: 'SwapActions__InsufficientAmountOut',
    type: 'error',
  },
  { inputs: [], name: 'SwapActions__SameTokenInAndOut', type: 'error' },
  {
    inputs: [{ internalType: 'address', name: 'swapper', type: 'address' }],
    name: 'SwapActions__SwapperNotWhitelisted',
    type: 'error',
  },
  { inputs: [], name: 'T', type: 'error' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'receiver', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'shares', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'amount0', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'amount1', type: 'uint256' },
      { indexed: false, internalType: 'bytes', name: 'additionalContext', type: 'bytes' },
    ],
    name: 'Redeem',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'receiver', type: 'address' },
      { indexed: true, internalType: 'address', name: 'receiveToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'receiveTokenAmount', type: 'uint256' },
      { indexed: false, internalType: 'bytes', name: 'redeemAdditionalContext', type: 'bytes' },
      { indexed: false, internalType: 'uint256', name: 'swapAmountIn', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'swapAmountOut', type: 'uint256' },
      { indexed: true, internalType: 'bool', name: 'isNativeTokenRedeem', type: 'bool' },
    ],
    name: 'RedeemAndSwap',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'contract IOrangeMetaVault', name: 'vault', type: 'address' },
      {
        components: [
          { internalType: 'uint256', name: 'shares', type: 'uint256' },
          { internalType: 'uint256', name: 'amount0Min', type: 'uint256' },
          { internalType: 'uint256', name: 'amount1Min', type: 'uint256' },
          { internalType: 'address', name: 'recipient', type: 'address' },
          { internalType: 'bool', name: 'receiveNativeToken', type: 'bool' },
        ],
        internalType: 'struct RedeemParams',
        name: 'params',
        type: 'tuple',
      },
    ],
    name: 'strykeRedeem',
    outputs: [
      { internalType: 'uint256', name: 'amount0', type: 'uint256' },
      { internalType: 'uint256', name: 'amount1', type: 'uint256' },
      {
        components: [
          {
            components: [
              { internalType: 'StrykeTokenID', name: 'id', type: 'uint256' },
              { internalType: 'int24', name: 'tickLower', type: 'int24' },
              { internalType: 'uint128', name: 'shares', type: 'uint128' },
              { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
            ],
            internalType: 'struct LockedToken[]',
            name: 'lockedTokens',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct StrykeAdditionalContext',
        name: 'additionalContext',
        type: 'tuple',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IOrangeMetaVault', name: 'vault', type: 'address' },
      {
        components: [
          { internalType: 'uint256', name: 'shares', type: 'uint256' },
          { internalType: 'uint256', name: 'amount0Min', type: 'uint256' },
          { internalType: 'uint256', name: 'amount1Min', type: 'uint256' },
          { internalType: 'address', name: 'receiveToken', type: 'address' },
          { internalType: 'uint256', name: 'receiveTokenAmountMin', type: 'uint256' },
          { internalType: 'address', name: 'recipient', type: 'address' },
          { internalType: 'bytes[]', name: 'swapActionPayloads', type: 'bytes[]' },
          { internalType: 'bool', name: 'isNativeTokenRedeem', type: 'bool' },
        ],
        internalType: 'struct RedeemSwapParams',
        name: 'params',
        type: 'tuple',
      },
    ],
    name: 'strykeRedeemAndSwap',
    outputs: [
      { internalType: 'uint256', name: 'receiveTokenAmount', type: 'uint256' },
      {
        components: [
          {
            components: [
              { internalType: 'StrykeTokenID', name: 'id', type: 'uint256' },
              { internalType: 'int24', name: 'tickLower', type: 'int24' },
              { internalType: 'uint128', name: 'shares', type: 'uint128' },
              { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
            ],
            internalType: 'struct LockedToken[]',
            name: 'lockedTokens',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct StrykeAdditionalContext',
        name: 'additionalContext',
        type: 'tuple',
      },
      { internalType: 'uint256', name: 'swapAmountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'swapAmountOut', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const

export default abi
