import { ConnectModalProps, MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import {
  AccountModalProps,
  ConnectAlphaModalProps,
  TxModalProps,
  WithdrawAndReserveModalContainerProps,
  V2WithdrawAndReserveModalContainerProps,
  ReserveAllLPModalContainerProps,
  SwitchChainModalProps,
  InvalidAddressModalProps,
  LockModalContainerProps,
  ExtendModalContainerProps,
  UnlockModalContainerProps,
} from '@apps-orangefi/ui/organisms/modals'
import { atom } from 'jotai'

export type AccountAlphaModalType = {
  modalType: typeof MODAL_TYPES.AccountAlphaModal
  modalProps: AccountModalProps
}

export type ConnectAlphaModalType = {
  modalType: typeof MODAL_TYPES.ConnectAlphaModal
  modalProps: ConnectAlphaModalProps
}

export type TxAlphaModalType = {
  modalType: typeof MODAL_TYPES.TxAlphaModal
  modalProps: TxModalProps
}

export type SwitchChainAlphaModalType = {
  modalType: typeof MODAL_TYPES.SwitchChainAlphaModal
  modalProps: SwitchChainModalProps
}

export type AccountModalType = {
  modalType: typeof MODAL_TYPES.AccountModal
  modalProps: AccountModalProps
}

export type ConnectModalType = {
  modalType: typeof MODAL_TYPES.ConnectModal
  modalProps: ConnectModalProps
}

export type TxModalType = {
  modalType: typeof MODAL_TYPES.TxModal
  modalProps: TxModalProps
}

export type WithdrawAndReserveModalContainerType = {
  modalType: typeof MODAL_TYPES.WithdrawAndReserveModalContainer
  modalProps: WithdrawAndReserveModalContainerProps
}

export type V2WithdrawAndReserveModalContainerType = {
  modalType: typeof MODAL_TYPES.V2WithdrawAndReserveModalContainer
  modalProps: V2WithdrawAndReserveModalContainerProps
}

export type ReserveAllLPModalContainerType = {
  modalType: typeof MODAL_TYPES.ReserveAllLPModalContainer
  modalProps: ReserveAllLPModalContainerProps
}

export type SwitchChainModalType = {
  modalType: typeof MODAL_TYPES.SwitchChainModal
  modalProps: SwitchChainModalProps
}

export type InvalidAddressModalType = {
  modalType: typeof MODAL_TYPES.InvalidAddressModal
  modalProps: InvalidAddressModalProps
}

export type LockModalContainerType = {
  modalType: typeof MODAL_TYPES.LockModalContainer
  modalProps: LockModalContainerProps
}

export type ExtendModalContainerType = {
  modalType: typeof MODAL_TYPES.ExtendModalContainer
  modalProps: ExtendModalContainerProps
}

export type UnlockModalContainerType = {
  modalType: typeof MODAL_TYPES.UnlockModalContainer
  modalProps: UnlockModalContainerProps
}

export type ModalType =
  | AccountAlphaModalType
  | ConnectAlphaModalType
  | TxAlphaModalType
  | SwitchChainAlphaModalType
  | AccountModalType
  | ConnectModalType
  | TxModalType
  | WithdrawAndReserveModalContainerType
  | V2WithdrawAndReserveModalContainerType
  | ReserveAllLPModalContainerType
  | SwitchChainModalType
  | InvalidAddressModalType
  | LockModalContainerType
  | ExtendModalContainerType
  | UnlockModalContainerType

export const modalState = atom<ModalType | null>(null)
