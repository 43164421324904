import CopyIcon from '@apps-orangefi/assets/images/icons/copy.svg'
import OrangeSliceIcon from '@apps-orangefi/assets/images/icons/orange-slice.svg'
import { BN } from '@apps-orangefi/lib'
import { MerklRewardStatus, merklRewardStatus, RewardAPR } from '@apps-orangefi/lib/types'
import { StrykeAPR } from '@apps-orangefi/ui/molecules/list-item'
import Image from 'next/image'
import { Tooltip } from 'react-tippy'
import { twMerge } from 'tailwind-merge'

type AprParams = {
  totalAPR: BN | null
  feeAPR: BN | null
  strykeReward:
    | {
        totalApr: BN
        rewardList: RewardAPR[]
      }
    | undefined
  rewardStatus: MerklRewardStatus
  showStrykeReward: boolean
  className?: string
}

type Props = AprParams

export const TotalAPR = ({
  totalAPR,
  feeAPR,
  strykeReward,
  rewardStatus,
  showStrykeReward,
  className,
}: Props) => {
  const tooltipContent = (
    <div className="flex flex-col dark:bg-gray-900 p-4 pt-3 rounded-xl w-72">
      <div className="flex flex-row justify-between pb-3">
        <div className="type-sm-medium dark:text-gray-200">Fee APR</div>
        <div className="type-sm-semibold dark:text-white font-mono">
          {feeAPR ? `${feeAPR.toFixed(2)}%` : '-'}
        </div>
      </div>
      {!!strykeReward && strykeReward.rewardList.length > 0 && (
        <div className="flex flex-col py-3 border-t border-gray-800">
          {strykeReward.rewardList.map((reward, index) => (
            <RewardAPRRow key={`reward-${index}`} {...reward} />
          ))}
        </div>
      )}
      <div className="flex flex-row justify-between border-t border-gray-800 pt-3">
        <div className="type-sm-medium dark:text-gray-200">Total APR</div>
        <div className="type-sm-semibold dark:text-orange-500 font-mono">
          {totalAPR ? `${totalAPR.toFixed(2)}%` : '-'}
        </div>
      </div>
    </div>
  )

  const rewardIcons = strykeReward?.rewardList.map(reward => reward.iconUrl) ?? []

  return (
    <div
      className={twMerge('flex flex-row items-baseline justify-between sm:justify-end', className)}
    >
      <span className="type-sm-caption dark:text-gray-300 sm:hidden">TOTAL APR</span>
      <div className="flex flex-row items-center w-full justify-start">
        <div className="underline decoration-dashed underline-offset-4 type-sm-caption font-mono dark:text-white">
          {totalAPR?.toFixed(2) ?? 0}%
        </div>
        {showStrykeReward || rewardStatus === merklRewardStatus.Active ? (
          <>
            <Tooltip html={tooltipContent} position="bottom" arrow theme="dark" interactive>
              <Image
                src={OrangeSliceIcon}
                alt="Orange Slice"
                width={20}
                height={20}
                className="ml-2 mt-2"
              />
            </Tooltip>
            {!!strykeReward && (
              <StrykeAPR
                rewardAPR={strykeReward.totalApr}
                rewardIcons={rewardIcons}
                className="ml-2"
              />
            )}
          </>
        ) : (
          <div className="w-5 h-5 ml-2 mt-2"></div>
        )}
      </div>
    </div>
  )
}

const RewardAPRRow = ({ iconUrl, symbol, tokenAddress, apr }: RewardAPR) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center justify-start gap-2">
        <Image src={iconUrl} alt={symbol} width={20} height={20} />
        <div className="type-sm-medium dark:text-white">{symbol}</div>
        <CopyAddress address={tokenAddress} />
      </div>
      <div className="ml-2 type-sm-semibold dark:text-white font-mono">{apr.toFixed(2)}%</div>
    </div>
  )
}

const CopyAddress = ({ address }: { address: AddressType }) => {
  const handleCopyAccount = () => {
    if (!!address) {
      navigator.clipboard.writeText(address)
    }
  }
  return (
    <button className="hover:opacity-90" onClick={() => handleCopyAccount()}>
      <Image src={CopyIcon} alt="copy address" width={16} height={16} />
    </button>
  )
}
