import { useStrykeQuery } from '@apps-orangefi/hooks'
import {
  getDopexLpPositionsListQuery,
  getDopexDailyStrikeEarningsListQuery,
} from '@apps-orangefi/lib/subgraph/queries'
import {
  type GetDopexLpPositionsListQuery,
  type GetDopexDailyStrikeEarningsListQuery,
} from '@apps-orangefi/lib/subgraph/types/dopex/graphql'
import { chain as _chain, isEmpty } from 'lodash'

export const useStrykeQueries = (vaultAddresses: AddressType[], startTime: number) => {
  const [resultStryke1st, reexecuteQuery] = useStrykeQuery<GetDopexLpPositionsListQuery>({
    query: getDopexLpPositionsListQuery,
    variables: {
      userIds: vaultAddresses.map(address => address.toLowerCase()),
    },
    pause: isEmpty(vaultAddresses),
  })

  const tokenIds = resultStryke1st.data?.lppositions.map(lp => lp.strike.id) ?? []

  const [resultStryke2nd] = useStrykeQuery<GetDopexDailyStrikeEarningsListQuery>({
    query: getDopexDailyStrikeEarningsListQuery,
    variables: {
      tokenIds,
      tokenIdsCount: tokenIds.length,
      startTime: startTime.toString(),
    },
    pause: isEmpty(tokenIds),
  })

  return {
    strykeFirstData: resultStryke1st.data,
    strykeSecondData: resultStryke2nd.data,
    fetching: {
      first: resultStryke1st.fetching,
      second: resultStryke2nd.fetching,
    },
    reexecuteQuery,
  }
}
