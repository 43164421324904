import { LinkButton } from '@apps-orangefi/ui/atoms/buttons'
import { LottieOptions } from 'lottie-react'
import dynamic from 'next/dynamic'
import Image, { StaticImageData } from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { memo } from 'react'
import { twMerge } from 'tailwind-merge'

const Lottie = dynamic(() => import('lottie-react'), { ssr: false })

type Props = {
  title: string
  description: string
  figureSrc?: StaticImageData
  lottieOptions?: LottieOptions
  readMoreLink?: string
  className?: string
}

export const Strategy = memo(function Feature({
  title,
  description,
  figureSrc,
  lottieOptions,
  readMoreLink,
  className,
}: Props) {
  const { t } = useTranslation()

  return (
    <div
      className={twMerge(
        'flex flex-col w-full rounded-2xl dark:bg-gray-850 bg-cards p-5',
        className
      )}
    >
      <span className="type-lg-semibold">{title}</span>
      <div className="my-4 type-base-medium h-fit min-h-[140px]">{description}</div>
      <>
        {!!readMoreLink && (
          <LinkButton label={t('DOCS.ACTION.READ_MORE')} href={readMoreLink} className="mb-5" />
        )}
      </>
      {!!figureSrc ? (
        <Image src={figureSrc} alt={title} />
      ) : (
        <>
          {!!lottieOptions && (
            <div className="w-full dark:bg-gray-850 bg-cards rounded-2xl">
              <Lottie {...lottieOptions} />
            </div>
          )}
        </>
      )}
    </div>
  )
})
