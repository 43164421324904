import { assetTokenList } from '@apps-orangefi/lib/constants'
import { TokenPairs } from '@apps-orangefi/wagmi/hooks/v2'
import { StaticImageData } from 'next/image'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { zeroAddress } from 'viem'

export const withdrawAssets = ['token0', 'token1', 'dual'] as const
export type WithdrawAsset = typeof withdrawAssets[number]

const initialAssetList = {
  token0: { name: '', logoUrl: '' },
  token1: { name: '', logoUrl: '' },
  dual: { name: '', logoUrl: [] },
}

const DEFAULT_TOKEN = {
  symbol: '',
  decimals: 18,
  address: zeroAddress,
}

export const useWithdrawAssetSelector = (tokenPairs: TokenPairs | undefined) => {
  const [selectedAsset, setSelectedAsset] = useState<WithdrawAsset>('token0')

  const assetList: Record<
    WithdrawAsset,
    { name: string; logoUrl: string | string[] | StaticImageData | StaticImageData[] }
  > = useMemo(() => {
    if (!tokenPairs?.token0 || !tokenPairs?.token1) return initialAssetList
    const token0LogoUrl = assetTokenList.find(a => a.symbol === tokenPairs.token0.symbol)?.logoUrl
    const token1LogoUrl = assetTokenList.find(a => a.symbol === tokenPairs.token1.symbol)?.logoUrl

    if (!token0LogoUrl || !token1LogoUrl) return initialAssetList

    return {
      token0: {
        name: tokenPairs.token0.symbol,
        logoUrl: token0LogoUrl,
      },
      token1: {
        name: tokenPairs.token1.symbol,
        logoUrl: token1LogoUrl,
      },
      dual: {
        name: `${tokenPairs.token0.symbol}-${tokenPairs.token1.symbol}`,
        logoUrl: [token0LogoUrl, token1LogoUrl],
      },
    }
  }, [tokenPairs])

  // NOTE: dualのとき見直し
  const { tokenIn, tokenOut } = useMemo(() => {
    if (!tokenPairs) return { tokenIn: DEFAULT_TOKEN, tokenOut: DEFAULT_TOKEN }
    return selectedAsset === 'token0'
      ? { tokenIn: tokenPairs.token1, tokenOut: tokenPairs.token0 }
      : { tokenIn: tokenPairs.token0, tokenOut: tokenPairs.token1 }
  }, [selectedAsset, tokenPairs])

  const isNativeToken = useMemo(() => {
    // TODO: native token symbol should be defined in the config
    const selected = selectedAsset === 'token0' ? tokenPairs?.token0 : tokenPairs?.token1
    return selected?.symbol === 'ETH'
  }, [selectedAsset, tokenPairs])

  const onSelectAsset = useCallback(
    (assetType: WithdrawAsset) => {
      if (assetType !== selectedAsset) {
        setSelectedAsset(assetType)
      }
    },
    [assetList, selectedAsset]
  )

  return {
    selectedAsset,
    onSelectAsset,
    assetList,
    mode: (selectedAsset === 'dual' ? 'dual' : 'single') as 'single' | 'dual',
    tokenIn,
    tokenOut,
    isNativeToken,
  }
}
