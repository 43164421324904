import { NextRouter } from 'next/router'
import { arbitrum, berachain, Chain } from 'wagmi/chains'

export const getBlockExplorer = (chain: Chain | undefined) => {
  if (!chain?.blockExplorers) return undefined
  return chain.blockExplorers['etherscan'] ?? chain.blockExplorers.default
}

export const txLink = (chain: Chain | undefined, tx: AddressType): string => {
  const blockExplorer = getBlockExplorer(chain)
  if (!blockExplorer) return ''
  return `${blockExplorer.url}/tx/${tx}`
}

export const addressLink = (chain: Chain | undefined, address: AddressType): string => {
  const blockExplorer = getBlockExplorer(chain)
  if (!blockExplorer) return ''
  return `${blockExplorer.url}/address/${address}`
}

const ARB_DEXSCREENER_PAIR_ID = '0xe83b6714f7b8d94187d8457592ce6fcf82453cf4' as AddressType
const BERA_DEXSCREENER_PAIR_ID = '0x2c4a603a2aa5596287a06886862dc29d56dbc354' as AddressType

export const getDexScreenParam = (
  chainId: number
): { chainKey: string; pairId: AddressType } | null => {
  switch (chainId) {
    case arbitrum.id:
      return { chainKey: 'arbitrum', pairId: ARB_DEXSCREENER_PAIR_ID }
    case berachain.id:
      return { chainKey: 'berachain', pairId: BERA_DEXSCREENER_PAIR_ID }
    default:
      return null
  }
}

export const genLinkChainAction = (router: NextRouter, selectedChain: Chain | undefined) => {
  return (chain: Chain) => {
    if (chain.id === selectedChain?.id) return
    if (chain.id === arbitrum.id) {
      process.env.NODE_ENV === 'development'
        ? router.push('http://localhost:3000')
        : router.push('https://app.orangefinance.io')
    }
    if (chain.id === berachain.id) {
      process.env.NODE_ENV === 'development'
        ? router.push('http://localhost:3003')
        : router.push('https://bera.orangefinance.io')
    }
  }
}
