import { useStrykeStats } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { usdceAddressAtom } from '@apps-orangefi/lib/store'
import {
  VaultInfo,
  ContractProp,
  StrategyVaultInfo,
  Token,
  Vault,
  Pool,
} from '@apps-orangefi/lib/types'
import { getTokenPair, calculatePosition } from '@apps-orangefi/lib/utils'
import { convertUSDCSymbol, addHex } from '@apps-orangefi/lib/utils'
import { useAtomValue } from 'jotai'
import { chain as _chain, isEmpty, isEqual, uniqBy } from 'lodash'
import { useEffect, useState, useMemo } from 'react'

// Main hook
export const useDopexVault = (account: AddressType | undefined, vaultInfo: VaultInfo) => {
  const usdceAddress = useAtomValue(usdceAddressAtom)
  const [productContract, setProductContract] = useState<StrategyVaultInfo>()
  const [contractProps, setContractProps] = useState<ContractProp[]>([])
  const [baseToken, setBaseToken] = useState<Token>()
  const [quoteToken, setQuoteToken] = useState<Token>()
  const [vaultData, setVaultData] = useState<Vault>()

  const startTime = useMemo(() => Math.floor(Date.now() / 1000) - 60 * 60 * 24, [])

  const {
    stats,
    orangeData,
    uniV3Data,
    strykeFirstData,
    isFetchingMain,
    isFetchingStryke,
    errorVault: error,
    reexecuteQueryVault,
  } = useStrykeStats({
    account,
    vaultAddress: vaultInfo.VAULT_ADDRESS,
    startTime,
  })

  // Update product contract
  useEffect(() => {
    if (!orangeData?.dopexVault || !vaultInfo) return

    const vault = orangeData.dopexVault
    const currentEthPrice = new BN(uniV3Data?.bundle?.ethPriceUSD ?? 0)
    const myPosition = orangeData.user?.positions.find(pos => pos.vault === vaultInfo.VAULT_ADDRESS)

    const [_baseToken, _quoteToken] = getTokenPair(vault, uniV3Data?.pool as Pool)

    if (_baseToken && !isEqual(baseToken, _baseToken)) {
      setBaseToken(_baseToken)
    }
    if (_quoteToken && !isEqual(quoteToken, _quoteToken)) {
      setQuoteToken(_quoteToken)
    }

    const symbol =
      convertUSDCSymbol(baseToken?.id as AddressType, usdceAddress) ?? baseToken?.symbol ?? ''

    const myPositionAmount = calculatePosition(
      myPosition?.share,
      vault.totalAssets,
      vault.totalSupply,
      Number(baseToken?.decimals)
    )

    const newProductContract: StrategyVaultInfo = {
      ...vaultInfo,
      YIELD_START: Number(vault.yieldStart) * 1000,
      poolAddress: vault.pool.toLowerCase() as AddressType,
      totalDeposit: new BN(vault.totalAssets).pow10ofMinus(Number(vault.decimals)),
      maxCapacity: new BN(vault.depositCap).pow10ofMinus(Number(vault.decimals)),
      apr: null,
      feeApr: stats?.dopexApr.multipliedBy(100) ?? new BN(0),
      myPosition: myPositionAmount,
      myPositionUSD: myPositionAmount
        .multipliedBy(new BN(baseToken?.derivedETH ?? 0))
        .multipliedBy(currentEthPrice),
      baseTokenPriceUSD: new BN(baseToken?.derivedETH ?? 0).multipliedBy(currentEthPrice),
      symbol,
      baseToken: _baseToken,
      quoteToken: _quoteToken,
      allocation: {
        amm: vaultInfo.info?.platform?.amm,
        derivative: vaultInfo.info?.platform?.derivative,
      },
    }

    if (!isEqual(productContract, newProductContract)) {
      setProductContract(newProductContract)
    }
  }, [orangeData, uniV3Data, usdceAddress, vaultInfo, baseToken, stats])

  // Update contract props and vault data
  useEffect(() => {
    if (!orangeData?.dopexVault || !vaultInfo || !baseToken?.id || !quoteToken?.id) return

    const vault = orangeData.dopexVault
    const newContractProps: ContractProp[] = [
      {
        symbol: convertUSDCSymbol(baseToken.id as AddressType, usdceAddress) ?? baseToken.symbol,
        address: baseToken.id.toLowerCase() as AddressType,
        url: `https://arbiscan.io/address/${baseToken.id}`,
      },
      {
        symbol: convertUSDCSymbol(quoteToken.id as AddressType, usdceAddress) ?? quoteToken.symbol,
        address: quoteToken.id.toLowerCase() as AddressType,
        url: `https://arbiscan.io/address/${quoteToken.id}`,
      },
      {
        symbol: 'Vault',
        address: vault.id.toLowerCase() as AddressType,
        url: `https://arbiscan.io/address/${vault.id}`,
      },
      {
        symbol: 'Pool',
        address: vault.pool.toLowerCase() as AddressType,
        url: `https://arbiscan.io/address/${vault.pool}`,
      },
    ]

    if (!isEqual(contractProps, newContractProps)) {
      setContractProps(newContractProps)
    }

    if (vault && uniV3Data?.pool) {
      setVaultData({
        ...vault,
        pool: uniV3Data.pool as Pool,
        baseToken,
        quoteToken,
      })
    }
  }, [orangeData, uniV3Data, vaultInfo, baseToken, quoteToken])

  const almAddressList = useMemo(() => {
    if (!vaultData) return []
    const strykePoolId = addHex(vaultData.pool.id as AddressType, 9)
    return [
      {
        vaultAddress: vaultData.id as AddressType,
        poolAddress: strykePoolId as AddressType,
      },
    ]
  }, [vaultData])

  const ethPriceUSD = useMemo(() => {
    return uniV3Data?.bundle?.ethPriceUSD ? new BN(uniV3Data.bundle.ethPriceUSD) : undefined
  }, [uniV3Data?.bundle?.ethPriceUSD])

  return {
    productContract,
    myVault: orangeData?.user?.positions.find(position => position.vault as AddressType),
    contractProps,
    vaultData,
    almAddressList,
    fetching: {
      subgraph1: isFetchingMain,
      subgraph2: isFetchingStryke,
      subgraphAll: isFetchingMain || isFetchingStryke,
    },
    ethPriceUSD,
    dataUniV3: uniV3Data,
    baseToken,
    dopexData: strykeFirstData,
    error,
    reexecuteQuery: reexecuteQueryVault,
  }
}
