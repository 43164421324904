import { Price, Token as UniswapSDKToken } from '@uniswap/sdk-core'
import { tickToPrice } from '@uniswap/v3-sdk'

export const convertTicksToPriceRange = (
  tickLower: number,
  tickUpper: number,
  baseToken: UniswapSDKToken,
  quoteToken: UniswapSDKToken
) => {
  const priceLower = tickToPrice(baseToken, quoteToken, tickLower)
  const priceUpper = tickToPrice(baseToken, quoteToken, tickUpper)

  return { priceLower, priceUpper }
}

export const getToken = (chainId: number, address: AddressType, decimals: number) => {
  return new UniswapSDKToken(chainId, address, decimals)
}
