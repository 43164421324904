/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query getPoolQuery($poolId: ID!) {\n    pool(id: $poolId) {\n      id\n      tick\n      sqrtPrice\n      token0 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token1 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token0Price\n      token1Price\n    }\n    bundle(id: \"1\") {\n      ethPriceUSD\n      id\n    }\n  }\n": types.GetPoolQueryDocument,
    "\n  query getPoolListQuery($poolIds: [ID!]!) {\n    pools(where: { id_in: $poolIds }) {\n      id\n      tick\n      sqrtPrice\n      token0 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token1 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token0Price\n      token1Price\n    }\n    bundle(id: \"1\") {\n      ethPriceUSD\n      id\n    }\n  }\n": types.GetPoolListQueryDocument,
    "\n  query getPoolListWithBlockNumberQuery($poolIds: [ID!]!, $blockNumber: Int) {\n    pools(where: { id_in: $poolIds }, block: { number: $blockNumber }) {\n      id\n      tick\n      sqrtPrice\n      token0 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token1 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token0Price\n      token1Price\n    }\n    bundle(id: \"1\", block: { number: $blockNumber }) {\n      ethPriceUSD\n      id\n    }\n  }\n": types.GetPoolListWithBlockNumberQueryDocument,
    "\n  query getTokenList($tokenAddresses: [ID!]!) {\n    tokens(where: { id_in: $tokenAddresses }) {\n      id\n      name\n      symbol\n      decimals\n      derivedETH\n    }\n    bundle(id: \"1\") {\n      ethPriceUSD\n      id\n    }\n  }\n": types.GetTokenListDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPoolQuery($poolId: ID!) {\n    pool(id: $poolId) {\n      id\n      tick\n      sqrtPrice\n      token0 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token1 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token0Price\n      token1Price\n    }\n    bundle(id: \"1\") {\n      ethPriceUSD\n      id\n    }\n  }\n"): (typeof documents)["\n  query getPoolQuery($poolId: ID!) {\n    pool(id: $poolId) {\n      id\n      tick\n      sqrtPrice\n      token0 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token1 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token0Price\n      token1Price\n    }\n    bundle(id: \"1\") {\n      ethPriceUSD\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPoolListQuery($poolIds: [ID!]!) {\n    pools(where: { id_in: $poolIds }) {\n      id\n      tick\n      sqrtPrice\n      token0 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token1 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token0Price\n      token1Price\n    }\n    bundle(id: \"1\") {\n      ethPriceUSD\n      id\n    }\n  }\n"): (typeof documents)["\n  query getPoolListQuery($poolIds: [ID!]!) {\n    pools(where: { id_in: $poolIds }) {\n      id\n      tick\n      sqrtPrice\n      token0 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token1 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token0Price\n      token1Price\n    }\n    bundle(id: \"1\") {\n      ethPriceUSD\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPoolListWithBlockNumberQuery($poolIds: [ID!]!, $blockNumber: Int) {\n    pools(where: { id_in: $poolIds }, block: { number: $blockNumber }) {\n      id\n      tick\n      sqrtPrice\n      token0 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token1 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token0Price\n      token1Price\n    }\n    bundle(id: \"1\", block: { number: $blockNumber }) {\n      ethPriceUSD\n      id\n    }\n  }\n"): (typeof documents)["\n  query getPoolListWithBlockNumberQuery($poolIds: [ID!]!, $blockNumber: Int) {\n    pools(where: { id_in: $poolIds }, block: { number: $blockNumber }) {\n      id\n      tick\n      sqrtPrice\n      token0 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token1 {\n        id\n        name\n        symbol\n        decimals\n        derivedETH\n      }\n      token0Price\n      token1Price\n    }\n    bundle(id: \"1\", block: { number: $blockNumber }) {\n      ethPriceUSD\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTokenList($tokenAddresses: [ID!]!) {\n    tokens(where: { id_in: $tokenAddresses }) {\n      id\n      name\n      symbol\n      decimals\n      derivedETH\n    }\n    bundle(id: \"1\") {\n      ethPriceUSD\n      id\n    }\n  }\n"): (typeof documents)["\n  query getTokenList($tokenAddresses: [ID!]!) {\n    tokens(where: { id_in: $tokenAddresses }) {\n      id\n      name\n      symbol\n      decimals\n      derivedETH\n    }\n    bundle(id: \"1\") {\n      ethPriceUSD\n      id\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;