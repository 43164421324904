import { useDebounce } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { useReadContractWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo, useEffect } from 'react'
import { erc20Abi } from 'viem'

import '@apps-orangefi/lib/extensions'

type Result = {
  data: BN
  isApproveNeeded: boolean | undefined
  isLoaded: boolean
  isRefetching: boolean
  refetch: () => void
}

const useAllowance = (
  tokenAddress: AddressType | undefined,
  owner: AddressType | undefined,
  spender: AddressType | undefined | null,
  decimals: number | undefined,
  amountToUse?: BN | undefined
): Result => {
  const args = useMemo(() => {
    return [owner!, spender!] as const
  }, [owner, spender])

  const enabled = useDebounce(!!tokenAddress && !!owner && !!spender && !!decimals, 300)

  const { data, isFetching, isFetched, isSuccess, isRefetching, queryKey, refetch } =
    useReadContractWithErrorHandling({
      address: tokenAddress!,
      abi: erc20Abi,
      functionName: 'allowance',
      args,
      query: {
        enabled,
      },
    })

  const allowance = bigintToBN(data).pow10ofMinus(decimals ?? 0)

  return {
    data: allowance,
    isApproveNeeded: !!amountToUse && amountToUse.gt(0) ? allowance.lt(amountToUse) : undefined,
    isLoaded: !isFetching && isFetched && isSuccess,
    isRefetching,
    refetch,
  }
}

export default useAllowance
