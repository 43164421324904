import BigNumber from 'bignumber.js'

declare module 'bignumber.js' {
  interface BigNumber {
    pow10: (decimals: number) => BigNumber
    pow10ofMinus: (decimals: number) => BigNumber
    convertBigint: () => bigint
    toFixedWithDp: (buf?: number) => string
  }
}

BigNumber.prototype.pow10 = function (decimals: number): BigNumber {
  return this.times(10 ** decimals)
}

BigNumber.prototype.pow10ofMinus = function (decimals: number): BigNumber {
  return this.div(10 ** decimals)
}

BigNumber.prototype.convertBigint = function (): bigint {
  return BigInt(this.toFixed(0))
}

BigNumber.prototype.toFixedWithDp = function (buf: number = 2): string {
  const dp = this.dp()
  const sd = this.sd()
  if (!dp || dp < sd) {
    return this.toFixed(buf)
  }
  return this.toFixed(dp - this.sd() + buf)
}
