import {
  useReserveLPFormWithHelper,
  useWithdrawSingleAssetForm,
  useOnceEffect,
  useWithdrawDualAssetForm,
} from '@apps-orangefi/hooks'
import { txListAtom } from '@apps-orangefi/lib/store'
import { AssetSummary } from '@apps-orangefi/lib/types/v2-redeem'
import { TxModal } from '@apps-orangefi/ui/organisms/modals'
import { RedeemAndSwapParams, RedeemParams } from '@apps-orangefi/wagmi/hooks/v2'
import { useSetAtom } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useMemo, useState } from 'react'
import { zeroAddress } from 'viem'
import { useAccount } from 'wagmi'

export type V2SimulateWithdrawProps = {
  redeemParams?: RedeemParams
  redeemAndSwapParams?: RedeemAndSwapParams
  assetSummary: AssetSummary | undefined
  hasUtilizedLP: boolean
  resetResultWithdrawSimulation: () => void
}

export type V2WithdrawAndReserveModalContainerProps = {
  routerAddress: AddressType | undefined
  vaultAddress: AddressType | undefined
  reserveProxyAddress: AddressType | undefined
  handlerAddress: AddressType | undefined
  simulateProps: V2SimulateWithdrawProps
  handleClose: () => void
  handleCallback: (isEnd: boolean) => void
  mode: 'single' | 'dual'
}

const dafaultRedeemAndSwapParams = {
  shares: BigInt(0),
  recipient: zeroAddress,
  receiveToken: zeroAddress,
  amount0Min: BigInt(0),
  amount1Min: BigInt(0),
  swapData: zeroAddress,
  isNativeTokenRedeem: false,
}
const defaultRedeemParams = {
  shares: BigInt(0),
  recipient: zeroAddress,
  receiveNativeToken: false,
}

export const V2WithdrawAndReserveModalContainer = ({
  routerAddress,
  vaultAddress,
  reserveProxyAddress,
  handlerAddress,
  simulateProps,
  handleClose,
  handleCallback,
  mode,
}: V2WithdrawAndReserveModalContainerProps) => {
  const { t } = useTranslation()
  const { address: account, chain } = useAccount()
  const [isWithdrawStarted, setIsWithdrawStarted] = useState(false)

  const setTxList = useSetAtom(txListAtom)
  const resetTxList = useResetAtom(txListAtom)

  const singleAssetWithdrawForm = useWithdrawSingleAssetForm({
    routerAddress,
    vaultAddress,
    redeemAndSwapParams: simulateProps.redeemAndSwapParams ?? dafaultRedeemAndSwapParams,
    hasUtilizedLP: simulateProps.hasUtilizedLP,
  })

  const dualAssetWithdrawForm = useWithdrawDualAssetForm({
    routerAddress,
    vaultAddress,
    redeemParams: simulateProps.redeemParams ?? defaultRedeemParams,
    hasUtilizedLP: simulateProps.hasUtilizedLP,
  })

  const {
    lastWithdrawnLPs,
    onWithdrawLP,
    isWithdrawTransactionEnd,
    isWithdrawReady,
    txWithdrawAtom,
    resetWithdrawTx,
  } = useMemo(() => {
    return mode === 'dual' ? dualAssetWithdrawForm : singleAssetWithdrawForm
  }, [mode, singleAssetWithdrawForm, dualAssetWithdrawForm])

  // hasReserveLP: similar to simulateProps.hasUtilizedLP
  const {
    onReserveLP,
    isReservedLiquidity,
    hasReserveLP,
    hasNotReservedLiquidity,
    isSubmitted: isReserveLPSubmitted,
    isWaiting: isReserveLPWaiting,
    txReserveLiquidityAtom,
    resetTx: resetReserveTx,
  } = useReserveLPFormWithHelper({
    lastWithdrawnLPs,
    isEnabledReserve: isWithdrawTransactionEnd,
    reserveProxyAddress,
    handlerAddress,
  })

  useOnceEffect(() => {
    resetWithdrawTx()
    resetReserveTx()

    let _txList = [txWithdrawAtom]
    if (simulateProps.hasUtilizedLP) {
      _txList.push(txReserveLiquidityAtom)
    }
    setTxList(_txList)

    return () => {
      resetTxList()
    }
  }, [])

  useEffect(() => {
    if (isReservedLiquidity && !!simulateProps.assetSummary) {
      simulateProps.resetResultWithdrawSimulation()
    }
  }, [isReservedLiquidity, simulateProps.resetResultWithdrawSimulation])

  useEffect(() => {
    if (isWithdrawReady && !isWithdrawTransactionEnd && !isWithdrawStarted) {
      onWithdrawLP()
      setIsWithdrawStarted(true)
    }
  }, [isWithdrawReady, isWithdrawTransactionEnd])

  useEffect(() => {
    if (isWithdrawTransactionEnd && (isReservedLiquidity || !hasReserveLP)) {
      handleCallback(true)
    }
  }, [isWithdrawTransactionEnd, isReservedLiquidity, hasReserveLP])

  useEffect(() => {
    if (isWithdrawTransactionEnd && !isReservedLiquidity && hasReserveLP && !isReserveLPSubmitted) {
      onReserveLP()
    }
  }, [
    onReserveLP,
    isWithdrawTransactionEnd,
    isReservedLiquidity,
    hasReserveLP,
    isReserveLPSubmitted,
  ])

  return (
    <>
      <TxModal
        title={'Withdraw & Reserve transaction'}
        chain={chain}
        handleClose={handleClose}
        messages={[]}
      >
        <div className="mt-3">
          {hasNotReservedLiquidity && isReservedLiquidity && (
            <div className="type-base-medium p-2">
              <Trans
                i18nKey="MODAL.TX.NOT_RESERVED_POSITIONS_EXIST"
                components={{
                  link: (
                    <a
                      href="https://www.stryke.xyz/en/dashboard"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="underline"
                    />
                  ),
                }}
              />
            </div>
          )}
        </div>
      </TxModal>
    </>
  )
}
