import { useTx, useModal } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import {
  Tx,
  txListAtom,
  txStatus,
  isAllowedCloseModalAtom,
  needReloadAtom,
} from '@apps-orangefi/lib/store'
import { ERC20Token, vaultVersion, VaultVersion } from '@apps-orangefi/lib/types'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { useAllowance, useApprove } from '@apps-orangefi/wagmi/hooks'
import { usePoolState } from '@apps-orangefi/wagmi/hooks/common'
import { useV2Deposit } from '@apps-orangefi/wagmi/hooks/v2'
import { useVaultEntity } from '@apps-orangefi/wagmi/hooks/v2'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { chain as _chain, compact, every, some } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAccount } from 'wagmi'

const txApproveDefault: Tx = {
  title: 'Approve',
  hash: undefined,
  status: txStatus.Wait,
}

const txDepositDefault: Tx = {
  title: 'Deposit',
  hash: undefined,
  status: txStatus.Wait,
}

const txApprove0Atom = atom<Tx>(txApproveDefault)
const txApprove1Atom = atom<Tx>(txApproveDefault)
const txDepositAtom = atom<Tx>(txDepositDefault)

type Props = {
  routerAddress: AddressType | undefined
  vaultAddress: AddressType | undefined
  token0: ERC20Token | undefined
  token1: ERC20Token | undefined
}

export const useDualAssetDepositForm = ({ routerAddress, vaultAddress, token0, token1 }: Props) => {
  const { t } = useTranslation()
  const [txList, setTxList] = useAtom(txListAtom)
  const [isAllowedCloseModal, setIsAllowedCloseModal] = useAtom(isAllowedCloseModalAtom)

  const { chain, address: account } = useAccount()
  const [amount0, setAmount0] = useState(new BN(0))
  const [amount1, setAmount1] = useState(new BN(0))
  // const setNeedReload = useSetAtom(needReloadAtom)

  const {
    tx: txApprove0,
    setTx: setTxApprove0,
    moveToPending: txApprove0Pending,
    moveToError: txApprove0Error,
    moveToSuccess: txApprove0Success,
  } = useTx(txApprove0Atom)

  const {
    tx: txApprove1,
    setTx: setTxApprove1,
    moveToPending: txApprove1Pending,
    moveToError: txApprove1Error,
    moveToSuccess: txApprove1Success,
  } = useTx(txApprove1Atom)

  const {
    tx: txDeposit,
    setTx: setTxDeposit,
    moveToPending: txDepositPending,
    moveToError: txDepositError,
    moveToSuccess: txDepositSuccess,
  } = useTx(txDepositAtom)

  const [isToken0Native, isToken1Native] = useMemo(() => {
    return [token0?.symbol === 'ETH', token1?.symbol === 'ETH']
  }, [token0, token1])

  const resetTx = useCallback(() => {
    if (txList.length !== 0) return
    setTxApprove0({ ...txApproveDefault, title: `Approve ${token0?.symbol}` })
    setTxApprove1({ ...txApproveDefault, title: `Approve ${token1?.symbol}` })
    setTxDeposit(txDepositDefault)
  }, [token0, token1])

  const initTxList = useCallback(() => {
    if (txList.length === 0) {
      const _txList = compact([
        !isToken0Native ? txApprove0Atom : undefined,
        !isToken1Native ? txApprove1Atom : undefined,
        txDepositAtom,
      ])
      setTxList(_txList)
    }
  }, [isToken0Native, isToken1Native])

  const allowance0 = useAllowance(
    token0?.address,
    account,
    routerAddress,
    token0?.decimals,
    amount0
  )
  const approve0 = useApprove(token0?.address, account, routerAddress, amount0, token0?.decimals, {
    success: () => {
      txApprove0Success()
      allowance0.refetch()
    },
  })

  const allowance1 = useAllowance(
    token1?.address,
    account,
    routerAddress,
    token1?.decimals,
    amount1
  )
  const approve1 = useApprove(token1?.address, account, routerAddress, amount1, token1?.decimals, {
    success: () => {
      txApprove1Success()
      allowance1.refetch()
    },
    fail: txApprove1Error,
  })

  const isEnabled = useMemo(
    () =>
      (isToken0Native || !allowance0.isApproveNeeded) &&
      (isToken1Native || !allowance1.isApproveNeeded),
    [allowance0.isApproveNeeded, allowance1.isApproveNeeded, isToken0Native, isToken1Native]
  )

  const deposit = useV2Deposit({
    routerAddress,
    vaultAddress,
    params: {
      amount0Max: amount0.pow10(token0?.decimals ?? 0).convertBigint(),
      amount1Max: amount1.pow10(token1?.decimals ?? 0).convertBigint(),
      recipient: account,
      isToken0Native,
      isToken1Native,
    },
    isEnabled,
    callback: {
      success: () => {
        txDepositSuccess()
        // setNeedReload(true)
      },
      fail: () => {
        txDepositError()
      },
    },
  })

  useEffect(() => {
    if (!approve0.hash || !!txApprove0.hash) return
    setTxApprove0(prev => {
      return { ...prev, hash: approve0.hash }
    })
  }, [approve0.hash])

  useEffect(() => {
    if (!approve1.hash || !!txApprove1.hash) return
    setTxApprove1(prev => {
      return { ...prev, hash: approve1.hash }
    })
  }, [approve1.hash])

  useEffect(() => {
    if (!deposit.hash || !!txDeposit.hash) return
    setTxDeposit(prev => {
      return { ...prev, hash: deposit.hash }
    })
  }, [deposit.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (txApprove0.status === txStatus.Wait) {
      if (amount0.lte(0)) return
      if (approve0.waitLoading) return
      if (allowance0.isApproveNeeded && approve0.isReady) {
        txApprove0Pending()
        approve0.onApprove()
      }
    }

    if (txApprove0.status === txStatus.Wait || txApprove0.status === txStatus.Pending) {
      if (amount0.gt(0) && !allowance0.isApproveNeeded) {
        txApprove0Success()
      }
    }
  }, [
    txList,
    txApprove0,
    amount0.toFixed(),
    allowance0.isApproveNeeded,
    approve0.isSuccess,
    approve0.isReady,
    approve0.waitLoading,
  ])

  useEffect(() => {
    if (txList.length === 0) return
    if (!isToken0Native && allowance0.isApproveNeeded) return // approve0が不要になったからといってapprove1の処理に進んでよいか？
    if (txApprove1.status === txStatus.Wait) {
      if (amount1.lte(0)) return
      if (approve1.waitLoading) return
      if (allowance1.isApproveNeeded && approve1.isReady) {
        txApprove1Pending()
        approve1.onApprove()
      }
    }
    if (txApprove1.status === txStatus.Wait || txApprove1.status === txStatus.Pending) {
      if (amount1.gt(0) && !allowance1.isApproveNeeded) {
        txApprove1Success()
      }
    }
  }, [
    txList,
    txApprove0,
    isToken0Native,
    allowance0.isApproveNeeded,
    txApprove1,
    amount1.toFixed(),
    allowance1.isApproveNeeded,
    approve1.isSuccess,
    approve1.isReady,
    approve1.waitLoading,
  ])

  // useEffect(() => {
  //   if (!approve0.isSuccess) return
  //   if (allowance0.isRefetching || !allowance0.isLoaded) return
  //   if (txApprove0.status !== txStatus.Pending) return
  //   if (allowance0.data.lt(amount0)) {
  //     txApprove0Error(t('WIDGET.ERROR.ALLOWANCE_NOT_ENOUGH'))
  //   }
  // }, [JSON.stringify(allowance0.data), allowance0.isLoaded])

  useEffect(() => {
    if (txList.length === 0) return
    if (!deposit.isWriteReady) return // -> 本来はtxのステータス管理に含めないほうがいい
    if (
      (isToken0Native || txApprove0.status === txStatus.Success) &&
      (isToken1Native || txApprove1.status === txStatus.Success) &&
      txDeposit.status === txStatus.Wait
    ) {
      txDepositPending()
      deposit.onV2Deposit()
    }
  }, [
    txList,
    txApprove0.status,
    txApprove1.status,
    txDeposit.status,
    deposit.isWriteReady,
    isToken0Native,
    isToken1Native,
  ])

  const onSetMaxBalance = useCallback((tokenKey: 'token0' | 'token1', balance: BN) => {
    return () => {
      if (tokenKey === 'token0') {
        setAmount0(balance)
      } else {
        setAmount1(balance)
      }
    }
  }, [])

  const onSetDepositAmount = useCallback((tokenKey: 'token0' | 'token1', amount: BN) => {
    if (tokenKey === 'token0') {
      setAmount0(amount)
    } else {
      setAmount1(amount)
    }
  }, [])

  const { showModal, hideModal } = useModal()

  useEffect(() => {
    const allTx = compact([
      !isToken0Native ? txApprove0 : undefined,
      !isToken1Native ? txApprove1 : undefined,
      txDeposit,
    ])

    const isTransactionEnd =
      every(allTx, ['status', txStatus.Success]) || some(allTx, ['status', txStatus.Error])
    if (isTransactionEnd !== isAllowedCloseModal) {
      setIsAllowedCloseModal(isTransactionEnd)
    }
  }, [txApprove0, txApprove1, txDeposit, isToken0Native, isToken1Native])

  const onDeposit = useCallback(() => {
    resetTx()
    initTxList()
    setIsAllowedCloseModal(false)
    showModal({
      modalType: MODAL_TYPES.TxModal,
      modalProps: {
        title: 'Deposit transaction',
        chain,
        handleClose: () => {
          // setNeedReload(true)
          hideModal()
        },
        messages: [],
      },
    })
  }, [resetTx, initTxList])

  return { amount0, amount1, onSetMaxBalance, onSetDepositAmount, onDeposit }
}
