import { BN } from '@apps-orangefi/lib'
import { SimulateWithdrawProps } from '@apps-orangefi/lib/types'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { TextField, Button, TagButton, ErrorMessage, Position } from '@apps-orangefi/ui/atoms'
import { RecievingAassetsSimulation } from '@apps-orangefi/ui/molecules/lpdfi'
import { WalletProps } from '@apps-orangefi/ui/molecules/strategy'
import { zodResolver } from '@hookform/resolvers/zod'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'

export type WithdrawProps = {
  withdrawableAmount: BN
  onChangeRate: (rate: BN) => void
  onWithdraw: () => void
  simulateProps?: SimulateWithdrawProps
  isClosed: boolean
  tokenSymbol: string | undefined
  statusKey?: string
  isAllStepsEnd?: boolean
  isFormAvailable: boolean
}
type Props = { withdraw: WithdrawProps; wallet: WalletProps }

export const WithdrawForm = ({
  withdraw: {
    withdrawableAmount,
    onChangeRate: onChange,
    onWithdraw: onSubmit,
    simulateProps,
    isClosed,
    tokenSymbol,
    statusKey = '',
    isAllStepsEnd,
    isFormAvailable,
  },
  wallet: { onConnect, onSwitchChainModal, isConnected, isActiveChainSupported },
}: Props) => {
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)

  const isHighlight = statusKey === 'RESERVE_LP'

  const estimatedAmount = withdrawableAmount.isNaN()
    ? '0'
    : numberWithShortScale(withdrawableAmount)

  const schema = z.object({
    rate: z.preprocess(
      v => Number(v),
      z
        .number()
        .min(0, { message: 'rate must be greater than 0' })
        .max(100, { message: 'rate must be less than or equarl to 100' })
    ),
  })

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      rate: 0,
    },
    resolver: zodResolver(schema),
  })

  const watchRate = watch('rate')

  const onClickMax = useCallback(() => {
    setValue('rate', 100)
  }, [setValue])

  useEffect(() => {
    const newRate = new BN(watchRate)
    if (!newRate.isNaN() && newRate.gt(0) && newRate.lte(100)) {
      onChange(newRate)
    } else {
      onChange(new BN(0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchRate])

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    setEnabled(isConnected && isActiveChainSupported)
  }, [isConnected, isActiveChainSupported])

  useEffect(() => {
    const available = enabled && isValid && isFormAvailable
    if (available !== isAvailable) {
      setIsAvailable(available)
    }
  }, [enabled, isValid, withdrawableAmount, simulateProps, isFormAvailable])

  const buttonLabel = t(`WIDGET.ACTION.${statusKey}`)

  return (
    <div className="flex flex-col p-5">
      <>
        {isClosed && (
          <div className="bg-gray-700 rounded-2xl text-style-sub px-6 py-3 mb-8">
            <div className="">{t('MODEL.WITHDRAW.WITHDRAW_ONLY')}</div>
          </div>
        )}
        <div className="w-full flex flex-col">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <div className="flex flex-col">
              <label className="type-base-semibold mb-2">{t('MODEL.WITHDRAW.HEADING_V1')}</label>
              <Controller
                name="rate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="flex flex-col justify-center relative">
                    <TextField
                      value={value.toString()}
                      prefix={tokenSymbol}
                      disabled={!enabled}
                      onChange={onChange}
                      className="dark:bg-gray-800 border-gray-750 font-mono text-md font-medium text-left px-4 py-3 md:pr-5 pl-24 rounded-lg md:rounded-xl"
                    />
                    <div className="absolute right-0 mr-5 ">
                      <span className="mr-5">%</span>
                      <TagButton
                        label="MAX"
                        disabled={false}
                        onClick={onClickMax}
                        className="mr-2 md:mr-0 py-1.5 px-3 rounded-lg"
                      />
                    </div>
                  </div>
                )}
              />
              <ErrorMessage message={errors.rate?.message} className="mt-6" />
            </div>
            {simulateProps ? (
              <>
                {!isAllStepsEnd && (
                  <RecievingAassetsSimulation
                    isAvailable={isAvailable}
                    tokenSymbol={tokenSymbol}
                    simulateProps={simulateProps}
                  />
                )}
              </>
            ) : (
              <div className={`flex flex-row justify-between mt-2 ${!enabled ? 'opacity-60' : ''}`}>
                <div className="text-style-sub">{t('MODEL.WITHDRAW.ESTIMATED_AMOUNT')}</div>
                <div className="text-style-value">
                  <span className="font-mono">
                    {estimatedAmount}
                    {!!tokenSymbol && <span className="ml-1.5">{tokenSymbol}</span>}
                  </span>
                </div>
              </div>
            )}
            {mounted && isConnected && isActiveChainSupported && (
              <Button
                label={buttonLabel}
                disabled={!isAvailable}
                animate={isHighlight}
                className="self-center mt-6 w-full px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
              />
            )}
          </form>
          {mounted && isConnected && !isActiveChainSupported && (
            <Button
              className="self-center w-full mt-2 px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
              label={t('WIDGET.ACTION.SWITCH_NETWORK')}
              onSubmit={onSwitchChainModal}
            />
          )}
          {mounted && !isConnected && (
            <Button
              onSubmit={onConnect}
              className="self-center mt-6 w-full px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
              label={t('WIDGET.ACTION.CONNECT')}
            />
          )}
        </div>
      </>
    </div>
  )
}
