import { getHistoricalVaultDataQuery } from '@apps-orangefi/lib/subgraph/queries'
import { useMemo } from 'react'
import { useQuery } from 'urql'

type ResponseHistoricalVaultData = Record<string, { id: string; derivedETH: string }> &
  Record<string, { totalAssets: string; totalSupply: string }> &
  Record<string, { ethPriceUSD: string }>

/**
 * Custom hook to fetch token prices and vault data at multiple blocks
 *
 * @param {string[]} tokens - Array of token contract addresses
 * @param {string} vaultId - Vault contract address
 * @param {number[]} blocks - Array of block numbers to query
 */
export const useHistoricalVaultData = (
  vaultId: string,
  tokens: (string | undefined)[],
  ppsToken: string | undefined,
  blocks: number[]
) => {
  // Generate the dynamic GraphQL query
  const query = useMemo(
    () => getHistoricalVaultDataQuery(vaultId, blocks, tokens),
    [tokens, vaultId, blocks]
  )

  // Execute the query using urql
  const [result] = useQuery<ResponseHistoricalVaultData>({
    query,
    pause: !query,
  })

  // Parse and transform the results into simple arrays of token USD prices and vault price per share
  const processedData = useMemo(() => {
    if (!result.data || !query) return null

    // Store ETH prices by block for easier access
    const ethPrices: { [block: number]: number } = {}

    // Extract all ETH prices first
    Object.entries(result.data).forEach(([key, value]) => {
      if (key.startsWith('eth_block')) {
        const matches = key.match(/eth_block(\d+)/)
        if (!matches) return

        const blockIndexStr = matches[1]
        const blockIndex = parseInt(blockIndexStr)
        const block = blocks[blockIndex]

        ethPrices[block] = parseFloat(value?.ethPriceUSD || '0') || 2500
      }
    })

    // Initialize the temporary storage structures
    const pricesMap: { [token: string]: { [block: number]: number } } = {}
    tokens.forEach(tokenId => {
      pricesMap[tokenId!] = {}
    })

    const vaultDataMap: { [block: number]: number } = {}

    // Calculate USD prices for each token at each block
    Object.entries(result.data).forEach(([key, value]) => {
      if (key.startsWith('token')) {
        // Parse token data
        const matches = key.match(/token(\d+)_block(\d+)/)
        if (!matches) return

        const tokenIndexStr = matches[1]
        const blockIndexStr = matches[2]
        const tokenIndex = parseInt(tokenIndexStr)
        const blockIndex = parseInt(blockIndexStr)

        const tokenId = tokens[tokenIndex]
        const block = blocks[blockIndex]
        const derivedETH = parseFloat(value.derivedETH)

        // Calculate USD price if we have both the token's ETH price and the ETH USD price
        if (derivedETH && ethPrices[block]) {
          pricesMap[tokenId!][block] = derivedETH * ethPrices[block]
        }
      } else if (key.startsWith('vault_block')) {
        // Parse vault data
        const matches = key.match(/vault_block(\d+)/)
        if (!matches) return

        const blockIndexStr = matches[1]
        const blockIndex = parseInt(blockIndexStr)
        const block = blocks[blockIndex]

        const totalAssets = parseFloat(value.totalAssets)
        const totalSupply = parseFloat(value.totalSupply)

        // Calculate price per share (totalAssets/totalSupply)
        if (totalSupply > 0) {
          vaultDataMap[block] = totalAssets / totalSupply
        } else {
          vaultDataMap[block] = 0
        }
      }
    })

    // Convert to simple arrays of prices sorted by block order
    const tokenPrices: { [token: string]: number[] } = {}

    tokens.forEach(tokenId => {
      // Create array of prices following the order of blocks
      tokenPrices[tokenId!] = blocks.map(block => {
        return pricesMap[tokenId!][block]
      })
    })

    // Create array of vault price per share following the order of blocks
    const vaultPricePerShareInPpsToken = blocks.map(block => {
      return vaultDataMap[block]
    })

    const vaultPricePerShare = vaultPricePerShareInPpsToken.map(
      (price, i) => price * tokenPrices[ppsToken!][i]
    )

    return {
      tokenPrices,
      vaultPricePerShare,
    }
  }, [result.data, tokens, vaultId, blocks])

  return {
    loading: result.fetching,
    error: result.error,
    data: {
      historicalTokenPrices: processedData?.tokenPrices,
      historicalVaultPps: processedData?.vaultPricePerShare,
    },
  }
}
