import { BN } from '@apps-orangefi/lib'

type Customizer = (objValue: any, othValue: any) => boolean | undefined

export const bnComparator: Customizer = (objValue: any, othValue: any) => {
  if (objValue instanceof BN && othValue instanceof BN) {
    return objValue.isEqualTo(othValue)
  }
}

export const dateCustomizer: Customizer = (objValue, othValue) => {
  if (objValue instanceof Date && othValue instanceof Date) {
    return objValue.getTime() === othValue.getTime()
  }
  return undefined
}

export const createCombinedCustomizer = (customizers: Customizer[]): Customizer => {
  return (objValue, othValue) => {
    for (const customizer of customizers) {
      const result = customizer(objValue, othValue)
      if (result !== undefined) {
        return result
      }
    }
    return undefined
  }
}
