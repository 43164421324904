import { useRef, useEffect, EffectCallback, DependencyList } from 'react'

/**
 * useOnceEffect: 一度だけ実行されるuseEffect
 *
 * @param effect - 実行する副作用関数
 * @param dependencies - 依存関係の配列
 */
function useOnceEffect(effect: EffectCallback, dependencies: DependencyList): void {
  const hasRun = useRef(false)

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true
      return effect()
    }
  }, dependencies)
}

export default useOnceEffect
