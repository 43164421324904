import { useOrangeQuery, useUniV3Query } from '@apps-orangefi/hooks'
import { getDopexVaultQuery, getUniV3PoolQuery } from '@apps-orangefi/lib/subgraph/queries'
import { type GetDopexVaultQuery } from '@apps-orangefi/lib/subgraph/types/orange/graphql'
import { type GetPoolQueryQuery } from '@apps-orangefi/lib/subgraph/types/uniswap/graphql'
import { VaultInfo } from '@apps-orangefi/lib/types'
import { chain as _chain } from 'lodash'

export const useVaultQueries = (account: AddressType | undefined, vaultAddress: AddressType) => {
  const [result, reexecuteQuery] = useOrangeQuery<GetDopexVaultQuery>({
    query: getDopexVaultQuery,
    variables: {
      account: account?.toLowerCase() ?? '',
      vaultAddress: vaultAddress.toLowerCase(),
    },
  })

  const [resultUniV3] = useUniV3Query<GetPoolQueryQuery>({
    query: getUniV3PoolQuery,
    variables: {
      poolId: result.data?.dopexVault?.pool.toLowerCase() ?? '',
    },
    pause: !result.data || !result.data?.dopexVault?.pool,
  })

  return {
    orangeData: result.data,
    uniV3Data: resultUniV3.data,
    fetching: {
      orange: result.fetching,
      uniV3: resultUniV3.fetching,
    },
    error: result.error,
    reexecuteQuery,
  }
}
