import Logo from '@apps-orangefi/assets/images/logo/logo-white.svg'
import { shortAddress } from '@apps-orangefi/lib/utils'
import { ChainSelector } from '@apps-orangefi/ui/molecules'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { Chain } from 'wagmi/chains'

const ConnectWalletButton = dynamic(
  () => import('@apps-orangefi/ui/molecules/buttons/ConnectWalletButton'),
  { ssr: false }
)

type Props = {
  networkStatus: {
    isConnected: boolean
    isActiveChainSupported: boolean
    isActiveChainIsSelected: boolean
  }
  account: AddressType | undefined
  openConnectWalletModal: () => void
  openAccountModal: () => void
  openSwitchChainModal: () => void
  chainSelectorProps: {
    selectedChain: Chain | undefined
    supportedChains: { [key: string]: Chain }
    onSelectChain: (chain: Chain) => void
  }
}

export const Header = ({
  networkStatus,
  account,
  openConnectWalletModal,
  openAccountModal,
  openSwitchChainModal,
  chainSelectorProps,
}: Props) => {
  const { t } = useTranslation()
  const shortenAddress = shortAddress(account)

  return (
    <div className="bg-main py-6 fixed top-0 right-0 left-0 z-30">
      <div className="flex flex-row justify-between items-center container px-8">
        <Link href="/">
          <Image src={Logo} alt="Orange Finance" width={184} height={40} />
        </Link>
        <div className="flex flex-row text-style-sub items-center">
          <div className="flex-row hidden md:flex">
            <Link href="/">
              <span>{t('MENU.PRODUCTS')}</span>
            </Link>
            <a
              href="https://orange-finance.gitbook.io/orange-finance/"
              target="_blank"
              rel="noreferrer"
              className="ml-10"
            >
              {t('MENU.DOCUMENTATION')}
            </a>
            <a
              href="https://dune.com/orangefinance/home"
              target="_blank"
              rel="noreferrer"
              className="mx-10"
            >
              {t('MENU.ANALYSIS')}
            </a>
          </div>
          {/* {networkStatus.isConnected && chainSelectorProps.selectedChain && (
            <ChainSelector {...chainSelectorProps} className="mr-3" />
          )} */}
          <ConnectWalletButton
            networkStatus={networkStatus}
            shortenAddress={shortenAddress}
            openConnectWalletModal={openConnectWalletModal}
            openAccountModal={openAccountModal}
            openSwitchChainModal={openSwitchChainModal}
            className="md:p-5 md:rounded-4xl"
          />
        </div>
      </div>
    </div>
  )
}
