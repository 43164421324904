import { selectedChainAtom } from '@apps-orangefi/lib/store'
import { useAtomValue } from 'jotai'
import { arbitrum, berachainTestnetbArtio, berachain } from 'viem/chains'

export const usePathByChain = () => {
  const selectedChain = useAtomValue(selectedChainAtom)
  switch (selectedChain?.id) {
    case arbitrum.id:
      return 'arbitrum'
    case berachainTestnetbArtio.id:
      return 'berachainTestnetbArtio'
    case berachain.id:
      return 'berachain'
    default:
      return ''
  }
}
