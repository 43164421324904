import { useModal } from '@apps-orangefi/hooks'
import { selectedChainAtom, targetChainAtom } from '@apps-orangefi/lib/store'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { useAtomValue } from 'jotai'
import { useCallback, useMemo } from 'react'
import { Connector, useConnect } from 'wagmi'

export const modalVersion = {
  StrategyVault: 'strategyVault',
} as const

export type ModalVersion = typeof modalVersion[keyof typeof modalVersion]

export const useConnectWalletModal = (version?: ModalVersion) => {
  const selectedChain = useAtomValue(selectedChainAtom)
  const { connect } = useConnect()
  const { showModal, hideModal } = useModal()

  // Modalの種類を決定する
  const modalType = useMemo(() => {
    return version === modalVersion.StrategyVault
      ? MODAL_TYPES.ConnectModal
      : MODAL_TYPES.ConnectAlphaModal
  }, [version])

  // handleConnectを安定化
  const handleConnect = useCallback(
    (connector: Connector) => {
      connect({ connector, chainId: selectedChain?.id })
      setTimeout(() => hideModal(), 150)
    },
    [connect, selectedChain, hideModal]
  )

  // Modalを開く関数を安定化
  const openConnectWalletModal = useCallback(() => {
    showModal({
      modalType,
      modalProps: {
        handleClose: hideModal,
        handleConnect,
      },
    })
  }, [modalType, showModal, handleConnect, hideModal])

  return {
    openConnectWalletModal,
  }
}
