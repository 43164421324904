import { VaultInfoList, productKey } from '@apps-orangefi/lib/types'
import { zeroAddress } from 'viem'
import { arbitrum } from 'wagmi/chains'

export const CONTRACT_ADDRESSES: VaultInfoList = {
  [arbitrum.id]: [
    {
      key: productKey.StrykePancakeWethUsdcVault,
      VAULT_ADDRESS: '0x8b20087Bb0580bCB827d3ebDF06485aF86ea16cB',
    },
    {
      key: productKey.StrykePancakeWbtcUsdcVault,
      VAULT_ADDRESS: '0xa3899444a955Fb1DdDbd7Aea385771DB0a67fB12',
    },
    {
      key: productKey.StrykePancakeArbUsdcVault,
      VAULT_ADDRESS: '0x9338a4c3De7082E27802DCB6AC5A4502C93D1808',
    },
  ],
}

export const STRYKE_VAULT_INSPECTOR_ADDRESS: AddressType =
  '0x8366389255d87423543b2Afb7d19814889BE73E6'

export const RESERVE_PROXY_ADDRESS: AddressType = '0xb1ac04Ad57bDC6FA5649295682590E0922bD0b4a'

export const SPACESHIP_DISTRIBUTOR_ADDRESS: AddressType | undefined = undefined

export const ASTRONAUT_NFT_ADDRESS: AddressType = zeroAddress
export const ASTRONAUT_PROXY_ADDRESS: AddressType = zeroAddress

export const STRYKE_DISTRIBUTOR_ADDRESS: AddressType = '0x9451A017a8532C2966248FaecFC2ab9449B41AD6'
export const STRYKE_REWARD_TOKEN_ADDRESS: AddressType = zeroAddress

export const STRYKE_POSITION_MANAGER_V2_ADDRESS: AddressType =
  '0x8f6C063c4B7d6037730f984C6dC38febdACE9eb8'
