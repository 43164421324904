import { useUniV3Query, useStrykeQuery } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import {
  getMultiPoolReservedLiquidityListQuery,
  uniV3PoolListQuery,
  getStrykeLiquidityListQuery,
} from '@apps-orangefi/lib/subgraph/queries'
import { type GetStrykeLiquidityListQuery } from '@apps-orangefi/lib/subgraph/types/dopex/graphql'
import { ReservedLiquidity } from '@apps-orangefi/lib/subgraph/types/orange/graphql'
import { type GetPoolListQueryQuery } from '@apps-orangefi/lib/subgraph/types/uniswap/graphql'
import { type Pool, type Strike } from '@apps-orangefi/lib/types'
import { getAmountsForLiquidity, getToken } from '@apps-orangefi/lib/utils'
import {
  calcReservedLpValue,
  ReservedAmount,
  ReservedLpValue,
} from '@apps-orangefi/lib/utils/reserved'
import { TickMath } from '@uniswap/v3-sdk'
import JSBI from 'jsbi'
import { isEmpty, groupBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery, UseQueryExecute } from 'urql'

const FETCH_LIMIT = 1000
type PoolReservedAmounts = { [key: string]: ReservedAmount[] }
type PoolReservedLpValues = { [key: string]: ReservedLpValue }

// 1. fetch reserved liquidities from orange subgraph
// 2. fetch current tick, sqrtPrice from uniswap subgraph
// 3. calculate reserved token pair amounts with reserved liquidities and current tick and sqrtPrice, tickLower, tickUpper
// 4. fetch a whole reserved liquidity and total liquidity, used liquidity from stryke subgraph
// 5. calculate tick withdrawable liquidity with a whole reserved liquidity and total liquidity, used liquidity
// 6. calculate withdrawable token pair amounts with tick withdrawable liquidity and current tick, sqrtPrice, tickLower, tickUpper
// 7. return withdrawable token pair amounts, reseved token pair amounts

export const useMultiPoolReservedLPStatus = (
  account: AddressType | undefined,
  poolIds: AddressType[],
  handlerIds: AddressType[],
  chainId: number | undefined
) => {
  const [poolReservedAmounts, setPoolReservedAmounts] = useState<PoolReservedAmounts>({})
  const [poolReservedLpValues, setPoolReservedLpValues] = useState<PoolReservedLpValues>({})

  // 1. fetch reserved liquidities from orange subgraph
  const {
    reservedLiquidityTokenIds,
    poolReservedLiquidities,
    isLoading: fetchingOrange,
    error,
    reexecuteQuery,
  } = useAllReservedLiquidities(account, poolIds, handlerIds)

  // 2. fetch current tick, sqrtPrice from uniswap subgraph
  const [resultUniV3] = useUniV3Query<GetPoolListQueryQuery>({
    query: uniV3PoolListQuery,
    variables: {
      poolIds: poolIds.map(id => id.toLowerCase()),
    },
    pause: isEmpty(poolIds),
    requestPolicy: 'network-only',
  })
  const {
    data: dataUniV3,
    fetching: fetchingUniV3,
    error: errorUniV3,
  } = useMemo(() => resultUniV3, [resultUniV3])

  // 3. calculate reserved token pair amounts with reserved liquidities and current tick and sqrtPrice, tickLower, tickUpper
  useEffect(() => {
    if (!dataUniV3 || isEmpty(dataUniV3.pools)) return
    // TODO: wrap function JSBI.BigInt
    const _poolReservedAmounts = Object.keys(poolReservedLiquidities).reduce((acc, poolId) => {
      const reservedLiquidities = poolReservedLiquidities[poolId]
      const pool = dataUniV3.pools.find(pool => pool.id === poolId)
      const sqrtPriceX96 = JSBI.BigInt(pool?.sqrtPrice ?? 0)

      const amountPairByTokenId = reservedLiquidities.map(reservedLiquidity => {
        const sqrtRatioAX96 = TickMath.getSqrtRatioAtTick(Number(reservedLiquidity.tickLower))
        const sqrtRatioBX96 = TickMath.getSqrtRatioAtTick(Number(reservedLiquidity.tickUpper))
        const liquidity = JSBI.BigInt(reservedLiquidity.liquidity)

        const { amount0, amount1 } = getAmountsForLiquidity(
          sqrtPriceX96,
          sqrtRatioAX96,
          sqrtRatioBX96,
          liquidity
        )
        return {
          id: reservedLiquidity.id,
          tokenId: reservedLiquidity.tokenId,
          amount0: new BN(amount0.toString()),
          amount1: new BN(amount1.toString()),
          withdrawableAmount0: new BN(amount0.toString()),
          withdrawableAmount1: new BN(amount1.toString()),
          liquidity: new BN(reservedLiquidity.liquidity),
        }
      })
      return {
        ...acc,
        [poolId]: amountPairByTokenId,
      }
    }, {} as PoolReservedAmounts)
    setPoolReservedAmounts(_poolReservedAmounts)
  }, [dataUniV3, poolReservedLiquidities])

  // 4. fetch a whole reserved liquidity and total liquidity, used liquidity from stryke subgraph

  const { allStrikes, isLoading: fetchingStryke } = useAllStrikes(reservedLiquidityTokenIds)

  // 5. calculate tick withdrawable liquidity with a whole reserved liquidity and total liquidity, used liquidity
  // 6. calculate withdrawable token pair amounts with tick withdrawable liquidity and current tick, sqrtPrice, tickLower, tickUpper

  useEffect(() => {
    if (isEmpty(allStrikes) || !dataUniV3 || !chainId || isEmpty(dataUniV3.pools)) return

    const _poolReservedLpValues = Object.keys(poolReservedAmounts).reduce((acc, poolId) => {
      const reservedAmounts = poolReservedAmounts[poolId]
      const pool = dataUniV3.pools.find(pool => pool.id === poolId) as Pool
      if (!pool) return acc

      const poolBaseToken = getToken(
        chainId,
        pool.token0.id as AddressType,
        Number(pool.token0.decimals)
      )
      const poolQuoteToken = getToken(
        chainId,
        pool.token1.id as AddressType,
        Number(pool.token1.decimals)
      )
      if (!poolBaseToken || !poolQuoteToken) return acc

      const reservedLpValue = calcReservedLpValue(
        allStrikes,
        pool,
        reservedAmounts,
        poolBaseToken,
        poolQuoteToken,
        new BN(dataUniV3.bundle?.ethPriceUSD ?? 0)
      )

      acc[poolId] = reservedLpValue
      return acc
    }, {} as PoolReservedLpValues)

    setPoolReservedLpValues(_poolReservedLpValues)
  }, [poolReservedAmounts, allStrikes, dataUniV3])

  return {
    poolReservedLpValues,
    tokenIds: reservedLiquidityTokenIds,
    fetching: fetchingOrange || fetchingUniV3 || fetchingStryke,
    refetch: reexecuteQuery,
  }
}

const useAllReservedLiquidities = (
  account: AddressType | undefined,
  poolIds: AddressType[],
  handlerIds: AddressType[]
) => {
  const [allReservedLiquidities, setAllReservedLiquidities] = useState<ReservedLiquidity[]>([])
  const [skip, setSkip] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [hasMore, setHasMore] = useState(true)
  const reexecuteQueryRef = useRef<UseQueryExecute | null>(null)

  const [result, reexecuteQuery] = useQuery({
    query: getMultiPoolReservedLiquidityListQuery,
    variables: {
      account: account?.toLowerCase() ?? '',
      poolIds: poolIds.map(id => id.toLowerCase()),
      handlerIds: handlerIds.map(id => id.toLowerCase()),
      skip,
    },
    pause: true,
    requestPolicy: 'network-only',
  })

  useEffect(() => {
    reexecuteQueryRef.current = reexecuteQuery
  }, [reexecuteQuery])

  const fetchNextPage = useCallback(async () => {
    if (hasMore && !isLoading && reexecuteQueryRef.current) {
      setIsLoading(true)
      reexecuteQueryRef.current({ requestPolicy: 'network-only' })
    }
  }, [hasMore, isLoading])

  useEffect(() => {
    if (result.data) {
      const newReservedLiquidities = result.data.reservedLiquidities
      setAllReservedLiquidities(prev => [...prev, ...newReservedLiquidities])
      setIsLoading(false)

      if (newReservedLiquidities.length < FETCH_LIMIT) {
        setHasMore(false)
      } else {
        setSkip(prev => prev + FETCH_LIMIT)
      }
    } else if (result.error) {
      setError(result.error.message)
      setIsLoading(false)
      setHasMore(false)
    }
  }, [result.data, result.error])

  const queryKey = useMemo(
    () => JSON.stringify({ account, poolIds, handlerIds }),
    [account, poolIds, handlerIds]
  )

  const initFetch = useCallback(() => {
    if (!account || poolIds.length === 0 || handlerIds.length === 0) {
      setAllReservedLiquidities([])
      setHasMore(false)
      return
    }
    setAllReservedLiquidities([])
    setSkip(0)
    setHasMore(true)
    setError(null)
    setIsLoading(true)
    if (reexecuteQueryRef.current) {
      reexecuteQueryRef.current({ requestPolicy: 'network-only' })
    }
  }, [account, JSON.stringify(poolIds), JSON.stringify(handlerIds)])

  useEffect(() => {
    initFetch()
  }, [queryKey, initFetch])

  useEffect(() => {
    if (hasMore && !isLoading && allReservedLiquidities.length > 0 && skip > 0) {
      fetchNextPage()
    }
  }, [hasMore, isLoading, allReservedLiquidities.length, skip, fetchNextPage])

  const reservedLiquidityTokenIds = useMemo(() => {
    return allReservedLiquidities.map(reservedLiquidity => reservedLiquidity.tokenId) ?? []
  }, [allReservedLiquidities])

  const poolReservedLiquidities = useMemo(() => {
    return groupBy(allReservedLiquidities, 'pool')
  }, [allReservedLiquidities])

  return {
    allReservedLiquidities,
    reservedLiquidityTokenIds,
    poolReservedLiquidities,
    isLoading,
    error,
    reexecuteQuery: initFetch,
  }
}

const useAllStrikes = (reservedLiquidityTokenIds: string[]) => {
  const [allStrikes, setAllStrikes] = useState<Strike[]>([])
  const [skip, setSkip] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [hasMore, setHasMore] = useState(true)
  const reexecuteQueryRef = useRef<UseQueryExecute | null>(null)

  const [result, reexecuteQuery] = useStrykeQuery<GetStrykeLiquidityListQuery>({
    query: getStrykeLiquidityListQuery,
    variables: {
      tokenIds: reservedLiquidityTokenIds,
      skip,
    },
    pause: true,
    requestPolicy: 'network-only',
  })

  useEffect(() => {
    reexecuteQueryRef.current = reexecuteQuery
  }, [reexecuteQuery])

  const fetchNextPage = useCallback(async () => {
    if (hasMore && !isLoading && reexecuteQueryRef.current) {
      setIsLoading(true)
      reexecuteQueryRef.current({ requestPolicy: 'network-only' })
    }
  }, [hasMore, isLoading])

  useEffect(() => {
    if (result.data) {
      const newStrikes = result.data.strikes
      setAllStrikes(prev => [...prev, ...(newStrikes as Strike[])])
      setIsLoading(false)

      if (newStrikes.length < FETCH_LIMIT) {
        setHasMore(false)
      } else {
        setSkip(prev => prev + FETCH_LIMIT)
      }
    } else if (result.error) {
      setError(result.error.message)
      setIsLoading(false)
      setHasMore(false)
    }
  }, [result.data, result.error])

  const queryKey = useMemo(
    () => JSON.stringify({ reservedLiquidityTokenIds }),
    [reservedLiquidityTokenIds]
  )

  const initFetch = useCallback(() => {
    if (reservedLiquidityTokenIds.length === 0) {
      setAllStrikes([])
      setHasMore(false)
      return
    }
    setAllStrikes([])
    setSkip(0)
    setHasMore(true)
    setError(null)
    setIsLoading(true)
    if (reexecuteQueryRef.current) {
      reexecuteQueryRef.current({ requestPolicy: 'network-only' })
    }
  }, [queryKey])

  useEffect(() => {
    initFetch()
  }, [queryKey, initFetch])

  useEffect(() => {
    if (hasMore && !isLoading && allStrikes.length > 0 && skip > 0) {
      fetchNextPage()
    }
  }, [hasMore, isLoading, allStrikes.length, skip, fetchNextPage])

  return {
    allStrikes,
    isLoading,
    error,
    reexecuteQuery: initFetch,
  }
}
