import { VaultInfoList, productKey } from '@apps-orangefi/lib/types'
import { zeroAddress } from 'viem'
import { arbitrum } from 'wagmi/chains'

export const CONTRACT_ADDRESSES: VaultInfoList = {
  [arbitrum.id]: [
    {
      key: productKey.StrykePancakeWethUsdcVault,
      VAULT_ADDRESS: '0x8b20087Bb0580bCB827d3ebDF06485aF86ea16cB',
    },
    {
      key: productKey.StrykePancakeWbtcUsdcVault,
      VAULT_ADDRESS: '0xa3899444a955Fb1DdDbd7Aea385771DB0a67fB12',
    },
    {
      key: productKey.StrykePancakeArbUsdcVault,
      VAULT_ADDRESS: '0x9338a4c3De7082E27802DCB6AC5A4502C93D1808',
    },
  ],
}

export const STRYKE_VAULT_INSPECTOR_ADDRESS: AddressType =
  '0x8366389255d87423543b2Afb7d19814889BE73E6'

export const RESERVE_PROXY_ADDRESS: AddressType = '0xDaDeD9891a65f19E10e67287eE27a7140113E621'

export const SPACESHIP_DISTRIBUTOR_ADDRESS: AddressType =
  '0x889a2802b2b3393b009Ec611529cDd98f0668A2f'

export const ASTRONAUT_NFT_ADDRESS: AddressType = '0xD148f43b4e50D40D961CF6A1d930BCA7C2b3F74e'
export const ASTRONAUT_PROXY_ADDRESS: AddressType = '0x5Cf35C3C5656dDC36b61Db1d90F5941ceE9a6C42'

export const STRYKE_DISTRIBUTOR_ADDRESS: AddressType = '0x37a3c59D17c94126D2aD55EeBf658CAebDe83D90'
// export const STRYKE_REWARD_TOKEN_ADDRESS: AddressType = '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1' // WETH
export const STRYKE_REWARD_TOKEN_ADDRESS: AddressType = '0xACC51FFDeF63fB0c014c882267C3A17261A5eD50' // SYK

export const STRYKE_POSITION_MANAGER_V2_ADDRESS: AddressType =
  '0x8f6C063c4B7d6037730f984C6dC38febdACE9eb8'
