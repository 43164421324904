import { useTx } from '@apps-orangefi/hooks'
import { Tx, txStatus, vaultDecimalsAtom, isAllowedCloseModalAtom } from '@apps-orangefi/lib/store'
import { RedeemAndSwapParams, useRedeemAndSwap } from '@apps-orangefi/wagmi/hooks/v2'
import { decodeStrykeRedeemContext } from '@orange-finance/v2-sdk'
import { atom, useAtom, useAtomValue } from 'jotai'
import { chain as _chain, isEmpty, every, some } from 'lodash'
import { useState, useEffect, useCallback, useMemo } from 'react'

type Props = {
  routerAddress: AddressType | undefined
  vaultAddress: AddressType | undefined
  redeemAndSwapParams: RedeemAndSwapParams
  hasUtilizedLP: boolean
}

const txWithdrawDefault: Tx = {
  title: 'Withdraw',
  hash: undefined,
  status: txStatus.Wait,
}

const txWithdrawAtom = atom<Tx>(txWithdrawDefault)

// 1. withdraw lp shares from vault
// 2. polling orange subgraph to get last withdrawn lp shares

export const useWithdrawSingleAssetForm = ({
  routerAddress,
  vaultAddress,
  redeemAndSwapParams,
  hasUtilizedLP,
}: Props) => {
  const [isWithdrawTransactionEnd, setIsWithdrawTransactionEnd] = useState(false)

  const vaultDecimals = useAtomValue(vaultDecimalsAtom)
  const [isAllowedCloseModal, setIsAllowedCloseModal] = useAtom(isAllowedCloseModalAtom)

  const {
    tx: txWithdraw,
    setTx: setTxWithdraw,
    moveToPending: txWithdrawPending,
    moveToError: txWithdrawError,
    moveToSuccess: txWithdrawSuccess,
  } = useTx(txWithdrawAtom)

  const resetTx = () => {
    setTxWithdraw(txWithdrawDefault)
  }

  const {
    onRedeemAndSwap,
    redeemResult: { receiveAmount, lockedTokens },
    hash,
    isWriteReady: isWithdrawReady,
  } = useRedeemAndSwap({
    routerAddress,
    vaultAddress,
    params: redeemAndSwapParams,
    isEnabled: true,
    callback: {
      success: () => {
        txWithdrawSuccess()
      },
      fail: txWithdrawError,
    },
  })
  const lastWithdrawnLPs = useMemo(() => {
    return lockedTokens.map(locked => {
      return {
        tokenId: locked.id.toString(),
        share: locked.shares,
      }
    })
  }, [lockedTokens])

  useEffect(() => {
    if (!hash || !!txWithdraw.hash) return
    setTxWithdraw(prev => {
      return { ...prev, hash }
    })
  }, [hash])

  useEffect(() => {
    const isTransactionSuccess = every([txWithdraw], ['status', txStatus.Success])
    const isTransactionFail = some([txWithdraw], ['status', txStatus.Error])

    const conditionHasUtilizedLP =
      hasUtilizedLP && isTransactionSuccess && lastWithdrawnLPs && lastWithdrawnLPs.length > 0
    const conditionNoUtilzedLP = !hasUtilizedLP && isTransactionSuccess
    const isClosableModal = conditionNoUtilzedLP || isTransactionFail

    if (isClosableModal !== isAllowedCloseModal) {
      setIsAllowedCloseModal(isClosableModal)
    }
    if (conditionHasUtilizedLP || conditionNoUtilzedLP) {
      setIsWithdrawTransactionEnd(conditionHasUtilizedLP || conditionNoUtilzedLP)
    }

    return () => {
      setIsAllowedCloseModal(false)
      setIsWithdrawTransactionEnd(false)
    }
  }, [txWithdraw, setIsAllowedCloseModal, hasUtilizedLP, lastWithdrawnLPs])

  const onWithdrawSingleAsset = useCallback(() => {
    setIsWithdrawTransactionEnd(false)
    txWithdrawPending()
    onRedeemAndSwap()
  }, [onRedeemAndSwap])

  return {
    lastWithdrawnLPs,
    onWithdrawLP: onWithdrawSingleAsset,
    isWithdrawTransactionEnd,
    isWithdrawReady,
    txWithdrawAtom,
    resetWithdrawTx: resetTx,
  }
}
