import axios from 'axios'
import { indexOf } from 'lodash'

type FetchSingleDepositSimulationyKey = {
  queryKey: [
    string,
    {
      userWallet: AddressType
      routerAddress: AddressType
      vaultAddress: AddressType
      token0Address: AddressType
      token1Address: AddressType
      token0Balance: string
      token1Balance: string
      swapAndDepositParams: {
        tokenIn: AddressType
        amountDepositMax: string
        amount0Max: string
        amount1Max: string
        amount0Min: string
        amount1Min: string
        sharesMin: string
        recipient: AddressType
        swapActionPayloads: AddressType[]
        isNativeTokenDeposit: boolean
      }
      networkId: number
    }
  ]
}

type SingleDepositSimulationResult = {
  shares: string
  amount0: string
  amount1: string
  swapAmountIn: string
  swapAmountOut: string
}

const url = process.env.NEXT_PUBLIC_ORANGE_API_URL

export const fetchSingleDepositSimulation = async ({
  queryKey,
}: FetchSingleDepositSimulationyKey) => {
  if (!url) {
    return undefined
  }
  const [_, params] = queryKey

  try {
    const res = await axios.post<SingleDepositSimulationResult>(`${url}/simulate/deposit`, params)

    return res.data
  } catch (error) {
    console.debug('Error fetching single deposit simulation', error)
    throw error
  }
}

type FetchSingleAssetRedeemSimulationKey = {
  queryKey: [
    string,
    {
      userWallet: AddressType
      routerAddress: AddressType
      vaultAddress: AddressType
      vaultBalance: string
      strykeRedeemAndSwapParams: {
        shares: string
        amount0Min: string
        amount1Min: string
        receiveToken: AddressType
        receiveTokenAmountMin: string
        recipient: AddressType
        swapActionPayloads: AddressType[]
        isNativeTokenRedeem: boolean
      }
      networkId: number
    }
  ]
}

type SingleAssetRedeemSimulationResult = {
  receiveTokenAmount: string
  lockedTokens: {
    id: string
    tickLower: number
    shares: string
    liquidity: string
  }[]
  swapAmountIn: string
  swapAmountOut: string
}

export const fetchSingleAssetRedeemSimulation = async ({
  queryKey,
}: FetchSingleAssetRedeemSimulationKey) => {
  if (!url) {
    return undefined
  }
  const [_, params] = queryKey

  try {
    const res = await axios.post<SingleAssetRedeemSimulationResult>(
      `${url}/simulate/redeem`,
      params
    )

    return {
      receiveTokenAmount: BigInt(res.data.receiveTokenAmount),
      lockedTokens: res.data.lockedTokens.map(token => ({
        id: BigInt(token.id),
        tickLower: token.tickLower,
        shares: BigInt(token.shares),
        liquidity: BigInt(token.liquidity),
      })),
      swapAmountIn: BigInt(res.data.swapAmountIn),
      swapAmountOut: BigInt(res.data.swapAmountOut),
    }
  } catch (error) {
    console.debug('Error fetching single redeem simulation', error)
    throw error
  }
}
