import { useTx } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { Tx, txStatus, vaultDecimalsAtom, isAllowedCloseModalAtom } from '@apps-orangefi/lib/store'
import { useWithdrawLPDfi } from '@apps-orangefi/wagmi/hooks'
import { atom, useAtom, useAtomValue } from 'jotai'
import { chain as _chain, isEmpty, every, some } from 'lodash'
import { useState, useEffect, useCallback, useMemo } from 'react'

const txWithdrawDefault: Tx = {
  title: 'Withdraw',
  hash: undefined,
  status: txStatus.Wait,
}

const txWithdrawAtom = atom<Tx>(txWithdrawDefault)

// 1. withdraw lp shares from vault
// 2. polling orange subgraph to get last withdrawn lp shares

export const useWithdrawLPForm = (
  fromWithdrawAddress: AddressType | undefined,
  account: AddressType | undefined,
  withdrawableLpAmount: BN,
  minAssets: BN | undefined,
  handlerAddress: AddressType | undefined,
  hasUtilzedLP: boolean
) => {
  useEffect(() => {
    console.debug('withdraw parameters', {
      shares: withdrawableLpAmount.toFixed(),
      minAssets: minAssets?.toFixed(),
    })
  }, [minAssets])

  const [isWithdrawTransactionEnd, setIsWithdrawTransactionEnd] = useState(false)

  const vaultDecimals = useAtomValue(vaultDecimalsAtom)
  const [isAllowedCloseModal, setIsAllowedCloseModal] = useAtom(isAllowedCloseModalAtom)

  const {
    tx: txWithdraw,
    setTx: setTxWithdraw,
    moveToPending: txWithdrawPending,
    moveToError: txWithdrawError,
    moveToSuccess: txWithdrawSuccess,
  } = useTx(txWithdrawAtom)

  const resetTx = () => {
    setTxWithdraw(txWithdrawDefault)
  }

  const withdraw = useWithdrawLPDfi({
    fromWithdrawAddress,
    account,
    handlerAddress,
    shares: withdrawableLpAmount,
    minAssets,
    vaultDecimals,
    callback: {
      success: () => {
        txWithdrawSuccess()
      },
      fail: txWithdrawError,
    },
  })

  useEffect(() => {
    if (!withdraw.hash || !!txWithdraw.hash) return
    setTxWithdraw(prev => {
      return { ...prev, hash: withdraw.hash }
    })
  }, [withdraw.hash])

  const lastWithdrawnLPs = withdraw.withdrawnLPs

  useEffect(() => {
    const isTransactionSuccess = every([txWithdraw], ['status', txStatus.Success])
    const isTransactionFail = some([txWithdraw], ['status', txStatus.Error])

    const conditionHasUtilzedLP =
      hasUtilzedLP && isTransactionSuccess && lastWithdrawnLPs && lastWithdrawnLPs.length > 0
    const conditionNoUtilzedLP = !hasUtilzedLP && isTransactionSuccess
    // NOTE: Add condtion `conditionHasUtilzedLP` for v1 relaunch
    const isClosableModal = conditionNoUtilzedLP || isTransactionFail || conditionHasUtilzedLP

    if (isClosableModal !== isAllowedCloseModal) {
      setIsAllowedCloseModal(isClosableModal)
    }
    if (conditionHasUtilzedLP || conditionNoUtilzedLP) {
      setIsWithdrawTransactionEnd(conditionHasUtilzedLP || conditionNoUtilzedLP)
    }

    return () => {
      setIsAllowedCloseModal(false)
      setIsWithdrawTransactionEnd(false)
    }
  }, [txWithdraw, setIsAllowedCloseModal, hasUtilzedLP, lastWithdrawnLPs])

  const onWithdrawLP = useCallback(() => {
    setIsWithdrawTransactionEnd(false)
    txWithdrawPending()
    withdraw.onWithdraw()
  }, [withdraw.onWithdraw])

  return {
    lastWithdrawnLPs,
    onWithdrawLP,
    isWithdrawTransactionEnd,
    isWithdrawReady: withdraw.isWithdrawReady,
    txWithdrawAtom,
    resetTx,
  }
}
