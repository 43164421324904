import { ModalType, modalState } from '@apps-orangefi/ui/state'
import { useAtom } from 'jotai'
import { useCallback } from 'react'

export function useModal() {
  const [modal, setModal] = useAtom(modalState)

  const showModal = useCallback(
    (modalType: ModalType) => {
      setModal(modalType)
    },
    [setModal]
  )

  const hideModal = useCallback(() => {
    setModal(null)
  }, [setModal])

  return {
    modal,
    setModal,
    showModal,
    hideModal,
  }
}
