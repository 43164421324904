import { Amm, Derivative, VaultVersion } from '@apps-orangefi/lib/types'
import { Platform, SourceRevenue } from '@apps-orangefi/ui/atoms'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { memo } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  allocation: {
    amm: Amm | undefined
    derivative?: Derivative | undefined
  }
  version: VaultVersion
  featureFigure?: any
  className?: string
}

export const VaultFeatures = memo(function VaultFeatures({
  allocation,
  featureFigure,
  version,
  className,
}: Props) {
  const { t } = useTranslation()

  return (
    <div className={twMerge('flex flex-col w-fit', className)}>
      <div className="flex flex-col gap-y-0.5 max-w-3xl w-full mt-6">
        <div className="flex flex-col sm:flex-row dark:bg-gray-850 bg-cards p-4 rounded-t-2xl">
          <h3 className="w-full sm:w-1/2 text-lg type-base-semibold dark:text-gray-200 sm:dark:text-white">
            {t('DOCS.VAULT_FEATURES.ASSET_SECTION.TITLE')}
          </h3>
          <Platform amm={allocation.amm} derivative={allocation.derivative} size={20} />
        </div>
        <div className="flex flex-col sm:flex-row dark:bg-gray-850 bg-cards p-4 rounded-b-2xl">
          <h3 className="w-full sm:w-1/2 text-lg type-base-semibold dark:text-gray-200 sm:dark:text-white">
            {t('DOCS.VAULT_FEATURES.REVENUE_SECTION.TITLE')}
          </h3>
          <SourceRevenue
            amm={allocation.amm}
            derivative={allocation.derivative}
            version={version}
            size={20}
          />
        </div>
      </div>
      {!!featureFigure && (
        // <div className="flex flex-col items-center p-10 dark:bg-gray-850 bg-cards rounded-2xl mt-10">
        //   <Image src={featureFigure} alt="How it works" className="object-contain" />
        //   <div className="mt-6 type-sm-medium dark:text-gray-500">
        //     * boost coefficient was measured by WETH-USDC vault from 1 Jun 2024
        //   </div>
        // </div>
        <Image src={featureFigure} alt="How it works" className="object-contain mt-10" />
      )}
    </div>
  )
})
