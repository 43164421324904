import { useTx, useModal, useSingleAssetDeposit } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { Tx, txListAtom, txStatus, isAllowedCloseModalAtom } from '@apps-orangefi/lib/store'
import { vaultVersion, VaultVersion, ERC20Token } from '@apps-orangefi/lib/types'
import { bigintToBN, calculatePriceImpact, getSwapCondition } from '@apps-orangefi/lib/utils'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { useAllowance, useApprove } from '@apps-orangefi/wagmi/hooks'
import { usePoolState } from '@apps-orangefi/wagmi/hooks/common'
import { useVaultEntity } from '@apps-orangefi/wagmi/hooks/v2'
import { atom, useAtom, useAtomValue } from 'jotai'
import { chain as _chain, every, some, merge, compact } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { zeroAddress } from 'viem'
import { useAccount } from 'wagmi'

const txApproveDefault: Tx = {
  title: 'Approve',
  hash: undefined,
  status: txStatus.Wait,
}

const txDepositDefault: Tx = {
  title: 'Deposit',
  hash: undefined,
  status: txStatus.Wait,
}

const txApproveAtom = atom<Tx>(txApproveDefault)
const txDepositAtom = atom<Tx>(txDepositDefault)

type UseSingleAssetDepositFormProps = {
  routerAddress: AddressType | undefined
  tokenAddress: AddressType | undefined
  vaultAddress: AddressType | undefined
  account: AddressType | undefined
  tokenBalance: BN | undefined
  tokenIn: ERC20Token | undefined
  tokenOut: ERC20Token | undefined
  isNativeToken: boolean
  version?: VaultVersion
}

const defaultDepositParams = {
  swapper: zeroAddress,
  amountInMax: { value: new BN(0), decimals: 0 },
  tokenIn: zeroAddress,
  tokenOut: zeroAddress,
  recipient: zeroAddress,
  sqrtPriceX96: BigInt(0),
  tolerance: { value: new BN(0.01), decimals: 2 }, // fixed value 0.01%
  slippageBuffer: { value: new BN(0.05), decimals: 2 },
  poolFee: 0,
  isNativeTokenDeposit: false,
}

export const useSingleAssetDepositForm = ({
  routerAddress,
  tokenAddress,
  vaultAddress,
  account,
  tokenBalance,
  tokenIn,
  tokenOut,
  isNativeToken,
  version,
}: UseSingleAssetDepositFormProps) => {
  const { t } = useTranslation()
  const [txList, setTxList] = useAtom(txListAtom)
  const [isAllowedCloseModal, setIsAllowedCloseModal] = useAtom(isAllowedCloseModalAtom)
  // const setNeedReload = useSetAtom(needReloadAtom)

  const { chain } = useAccount()
  const [amount, setAmount] = useState(new BN(0))
  const [slippageTolerance, setSlippageTolerance] = useState(0.5) // 0.5%

  const {
    tx: txApprove,
    setTx: setTxApprove,
    moveToPending: txApprovePending,
    moveToError: txApproveError,
    moveToSuccess: txApproveSuccess,
  } = useTx(txApproveAtom)

  const {
    tx: txDeposit,
    setTx: setTxDeposit,
    moveToPending: txDepositPending,
    moveToError: txDepositError,
    moveToSuccess: txDepositSuccess,
  } = useTx(txDepositAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxApprove(txApproveDefault)
    setTxDeposit(txDepositDefault)
  }

  const initTxList = useCallback(() => {
    if (txList.length === 0) {
      const _txList = compact([!isNativeToken ? txApproveAtom : undefined, txDepositAtom])
      setTxList(_txList)
    }
  }, [isNativeToken])

  const isTokenInNative = useMemo(() => {
    // TODO: native token of berachain is BERA
    return tokenIn?.symbol === 'ETH'
  }, [tokenIn])

  const allowance = useAllowance(tokenAddress, account, routerAddress, tokenIn?.decimals)
  const approve = useApprove(tokenAddress, account, routerAddress, amount, tokenIn?.decimals, {
    success: allowance.refetch,
    fail: txApproveError,
  })

  const isEnabled = useMemo(() => {
    return amount.gt(0) ? isNativeToken || allowance.data.gte(amount) : false
  }, [amount, allowance.data, isNativeToken])

  const { vaultEntity } = useVaultEntity(vaultAddress)
  const { poolState } = usePoolState({ poolAddress: vaultEntity?.poolAddress })

  const { swapRouteAction, ...deposit } = useSingleAssetDeposit({
    router: routerAddress,
    vault: vaultEntity?.vault,
    params: merge(defaultDepositParams, {
      amountInMax: { value: amount, decimals: tokenIn?.decimals ?? 0 },
      tokenIn: tokenIn?.address,
      tokenOut: tokenOut?.address,
      recipient: account,
      sqrtPriceX96: poolState?.sqrtPriceX96,
      tolerance: { value: new BN(0.01), decimals: 2 }, // fixed value 0.01%
      slippageBuffer: { value: new BN(slippageTolerance), decimals: 2 },
      isNativeTokenDeposit: isNativeToken,
    }),
    isEnabled,
    isTokenInNative,
    chainId: chain?.id,
    callback: {
      success: txDepositSuccess,
      fail: txDepositError,
    },
  })

  const priceImpact = useMemo(() => {
    const { swapAmountIn, swapAmountOut } = deposit
    if (!swapAmountIn || !swapAmountOut) return null
    if (!tokenIn || !tokenOut) return null
    if (!deposit.priceImpact) return null

    const swapConditions = getSwapCondition(
      new BN(swapAmountIn).pow10ofMinus(tokenIn.decimals),
      new BN(swapAmountOut).pow10ofMinus(tokenOut.decimals),
      tokenIn,
      tokenOut
    )
    return {
      priceImpact: new BN(deposit.priceImpact),
      ...swapConditions,
    }
  }, [deposit.swapAmountIn, deposit.swapAmountOut, deposit.priceImpact, tokenIn, tokenOut])

  useEffect(() => {
    if (!swapRouteAction.isFetched) return
    swapRouteAction.onFetch()
  }, [slippageTolerance])

  useEffect(() => {
    if (!approve.hash || !!txApprove.hash) return
    setTxApprove(prev => {
      return { ...prev, hash: approve.hash }
    })
  }, [approve.hash])

  useEffect(() => {
    if (!deposit.hash || !!txDeposit.hash) return
    setTxDeposit(prev => {
      return { ...prev, hash: deposit.hash }
    })
  }, [deposit.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (isNativeToken) return
    if (txApprove.status === txStatus.Wait) {
      if (amount.lte(0)) return
      if (approve.waitLoading) return
      if (allowance.data.lt(amount) && !!approve.writeContract && !!approve.data?.request) {
        txApprovePending()
        approve.onApprove()
      }
    }

    if (txApprove.status === txStatus.Wait || txApprove.status === txStatus.Pending) {
      if (amount.gt(0) && allowance.data.gte(amount)) {
        txApproveSuccess()
      }
    }
  }, [
    txList,
    txApprove,
    amount.toFixed(),
    allowance.data.toFixed(),
    approve.isSuccess,
    approve.writeContract,
    approve.data?.request,
    isNativeToken,
  ])

  useEffect(() => {
    if (!approve.isSuccess) return
    if (allowance.isRefetching || !allowance.isLoaded) return
    if (txApprove.status !== txStatus.Pending) return
    if (allowance.data.lt(amount)) {
      txApproveError(t('WIDGET.ERROR.ALLOWANCE_NOT_ENOUGH'))
    }
  }, [JSON.stringify(allowance.data), allowance.isLoaded])

  useEffect(() => {
    if (txList.length === 0 || !deposit.isWriteReady) return
    const canDeposit = isNativeToken
      ? txDeposit.status === txStatus.Wait
      : txApprove.status === txStatus.Success && txDeposit.status === txStatus.Wait

    if (canDeposit) {
      txDepositPending()
      deposit.onSwapAndDeposit()
    }
  }, [
    txList,
    txApprove,
    txDeposit,
    deposit.isWriteReady,
    deposit.onSwapAndDeposit,
    amount.toFixed(),
    isNativeToken,
  ])

  const { showModal, hideModal } = useModal()

  const onSetMaxBalance = useCallback(() => {
    if (!!tokenBalance) {
      setAmount(tokenBalance)
    }
  }, [tokenBalance?.toFixed()])

  useEffect(() => {
    const txs = compact([!isNativeToken ? txApprove : undefined, txDeposit])
    const isTransactionEnd =
      every(txs, ['status', txStatus.Success]) || some(txs, ['status', txStatus.Error])
    if (isTransactionEnd !== isAllowedCloseModal) {
      setIsAllowedCloseModal(isTransactionEnd)
    }
  }, [txApprove, txDeposit, setIsAllowedCloseModal])

  const onDeposit = useCallback(
    (data: { amount: string }) => {
      const messages =
        version === vaultVersion.LPDfi || version === vaultVersion.V1
          ? []
          : [t('MODAL.TX.DEPOSIT_CAUTION_MESSAGE')]
      resetTx()
      initTxList()
      setIsAllowedCloseModal(false)
      showModal({
        modalType: MODAL_TYPES.TxModal,
        modalProps: {
          title: 'Deposit transaction',
          chain,
          handleClose: () => {
            // setNeedReload(true)
            hideModal()
          },
          // FIXME: view resposibility
          messages,
        },
      })
    },
    [version, initTxList, resetTx]
  )

  return {
    amount,
    slippageTolerance,
    setAmount,
    setSlippageTolerance,
    allowance: allowance.data,
    initTxList,
    onDeposit,
    onSetMaxBalance,
    swapRouteAction,
    priceImpact,
  }
}
