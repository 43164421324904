const base = require('./base')
const colors = require('./src/styles/colors')

module.exports = {
  ...base,
  theme: {
    extend: {
      ...base.theme.extend,
      colors,
    },
  },
}
