import { BN } from '@apps-orangefi/lib'
import { SimulateWithdrawProps } from '@apps-orangefi/lib/types'
import { LineLoader } from '@apps-orangefi/ui/atoms'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'

type Props = {
  isAvailable: boolean
  tokenSymbol: string | undefined
  simulateProps: SimulateWithdrawProps
}

export const RecievingAassetsSimulation = ({ isAvailable, tokenSymbol, simulateProps }: Props) => {
  const { t } = useTranslation()

  const resultWithdrawSimulation = simulateProps.resultWithdrawSimulation

  return (
    <>
      {!!resultWithdrawSimulation && (
        <div className="flex flex-col mt-4 items-start">
          {simulateProps.isSimulating ? (
            <LineLoader />
          ) : (
            <div className="px-3 py-2 w-full">
              <div className="flex flex-row justify-between items-baseline">
                <div className="type-lg-medium dark:text-gray-250">
                  {t('MODEL.WITHDRAW.SIMULATION.TOTAL_ASSETS')}
                </div>
                <div className="type-md-medium font-mono dark:text-white">
                  {resultWithdrawSimulation.totalValue?.toFixed(4) ?? '-'}&nbsp;{tokenSymbol}
                </div>
              </div>
              <div className="flex flex-col border-l-[1px] border-gray-700 pl-4 mt-4">
                <div className="flex flex-row justify-between items-baseline">
                  <div className="type-base-medium dark:text-gray-250">
                    {t('MODEL.WITHDRAW.SIMULATION.REDEEMABLE_LP')}
                  </div>
                  <div className="type-md-medium dark:text-white">
                    <span className="font-mono">
                      {(resultWithdrawSimulation.withdrawnAssets as BN).toFixed(5)}
                    </span>{' '}
                    {tokenSymbol}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-baseline mt-2">
                  <div className="type-base-medium dark:text-gray-250">
                    {t('MODEL.WITHDRAW.SIMULATION.LP_POSITIONS')}
                  </div>
                  {isEmpty(resultWithdrawSimulation.totalUtilizedLP) ? (
                    <span className="type-md-medium dark:text-white">-</span>
                  ) : (
                    <div className="flex flex-col">
                      {resultWithdrawSimulation.totalUtilizedLP?.token0 && (
                        <div className="type-md-medium dark:text-white">
                          <span className="font-mono">
                            {resultWithdrawSimulation.totalUtilizedLP.token0.size.toFixed(5)}
                          </span>{' '}
                          {resultWithdrawSimulation.totalUtilizedLP.token0.symbol}
                        </div>
                      )}
                      {resultWithdrawSimulation.totalUtilizedLP?.token1 && (
                        <div className="type-md-medium dark:text-white">
                          <span className="font-mono">
                            {resultWithdrawSimulation.totalUtilizedLP.token1.size.toFixed(5)}
                          </span>{' '}
                          {resultWithdrawSimulation.totalUtilizedLP.token1.symbol}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <>
                  {simulateProps.isLpPositionFetching ? (
                    <LineLoader />
                  ) : (
                    <>
                      {resultWithdrawSimulation.withdrawnLP.length > 0 && (
                        <div className="fles flex-col mt-2">
                          <div className="flex flex-col mt-3 w-full">
                            <div className="flex flex-row justify-between mb-2">
                              <div className="type-xs-medium dark:text-gray-500 w-1/2">
                                {t('MODEL.WITHDRAW.SIMULATION.PRICE')}
                              </div>
                              <div className="type-xs-medium dark:text-gray-500 w-1/2">
                                {t('MODEL.WITHDRAW.SIMULATION.SIZE')}
                              </div>
                            </div>
                            <div className="[&>*:first-child]:mt-0 max-h-32 overflow-y-scroll">
                              {resultWithdrawSimulation.withdrawnLP.map((lp, i) => (
                                <div
                                  className="flex flex-row justify-between items-baseline w-full mt-2"
                                  key={i}
                                >
                                  <div className="type-sm-medium dark:text-white w-1/2">
                                    $<span className="font-mono">{lp.strikePrice.toFixed(2)}</span>
                                  </div>
                                  <div className="flex flex-row justify-start w-1/2">
                                    {lp.token0 && (
                                      <div className="type-sm-medium dark:text-white">
                                        <span className="font-mono">
                                          {lp.token0.size.toFixed(6)}
                                        </span>{' '}
                                        {lp.token0.symbol}
                                      </div>
                                    )}
                                    {lp.token0 && lp.token1 && <span>&nbsp;/&nbsp;</span>}
                                    {lp.token1 && (
                                      <div className="type-sm-medium dark:text-white">
                                        <span className="font-mono">
                                          {lp.token1.size.toFixed(2)}
                                        </span>{' '}
                                        {lp.token1.symbol}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
