import { InfoIcon } from '@apps-orangefi/ui/atoms/icons'
import Image from 'next/image'
import { Tooltip, Position } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import { twMerge } from 'tailwind-merge'

type Props = {
  iconUrl?: string
  tooltipText?: string
  size?: number
  place?: Position
  arrow?: boolean
  className?: string
}

export const IconInfo = ({
  iconUrl,
  tooltipText,
  size,
  place = 'top',
  arrow = true,
  className,
}: Props) => {
  return (
    <div className={twMerge('text-[#9EA0A8]', className)}>
      <Tooltip html={<div className="p-2">{tooltipText}</div>} position={place} arrow={arrow}>
        {iconUrl ? (
          <Image src={iconUrl} width={size} height={size} alt="info icon" />
        ) : (
          <InfoIcon size={size} />
        )}
      </Tooltip>
    </div>
  )
}
