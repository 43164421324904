import { BN } from '@apps-orangefi/lib'
import axios from 'axios'

type FetchSYKPairQueryKey = {
  queryKey: [string, { chainKey: string | null; pairId: AddressType }]
}

type PairResult = {
  pair: {
    chainId: string
    dexId: string
    url: string
    pairAddress: string
    baseToken: {
      address: string
      name: string
      symbol: string
    }
    quoteToken: {
      address: string
      name: string
      symbol: string
    }
    priceNative: BN
    priceUsd: BN
  }
}

export const fetchDexPair = async ({ queryKey }: FetchSYKPairQueryKey) => {
  const [_, { chainKey, pairId }] = queryKey
  if (chainKey === null || !pairId) {
    return undefined
  }

  const url = `https://api.dexscreener.com/latest/dex/pairs/${chainKey}/${pairId}`
  const res = await axios.get<PairResult>(url, {})

  return {
    ...res.data.pair,
    priceNative: new BN(res.data.pair.priceNative),
    priceUsd: new BN(res.data.pair.priceUsd),
  }
}
