import { BN } from '@apps-orangefi/lib'
import {
  WithdrawableReserveLiquidity,
  TotalWithdrawableReserveLiquidity,
  type Pool,
  type Strike,
} from '@apps-orangefi/lib/types'
import {
  convertTotalAmountEthAndUsd,
  convertTicksToPriceRange,
  getAmountsForLiquidity,
} from '@apps-orangefi/lib/utils'
import { Token } from '@uniswap/sdk-core'
import { TickMath, tickToPrice } from '@uniswap/v3-sdk'
import JSBI from 'jsbi'

export type ReservedAmount = {
  tokenId: string
  amount0: BN
  amount1: BN
  withdrawableAmount0: BN
  withdrawableAmount1: BN
  liquidity: BN
}

export type ReservedLpValue = {
  withdrawableReserveLiquidities: WithdrawableReserveLiquidity[]
  totalWithdrawableReserveLiquidity: TotalWithdrawableReserveLiquidity
  totalAmountUSD: BN
}

export const calcReservedLpValue = (
  strikes: Strike[],
  pool: Pool,
  userReservedList: ReservedAmount[],
  poolBaseToken: Token,
  poolQuoteToken: Token,
  ethPriceUSD: BN
) => {
  const sqrtPriceX96 = JSBI.BigInt(pool.sqrtPrice)
  const currentPrice = tickToPrice(poolBaseToken, poolQuoteToken, Number(pool.tick))

  const _withdrawableReserveLiquidities = strikes.map(strike => {
    const reservedLiquidity = new BN(strike.reservedLiquidity)
    const totalLiquidity = new BN(strike.totalLiquidity)
    const usedLiquidity = new BN(strike.usedLiquidity)

    const userReserved = userReservedList.find(rsvd => rsvd.tokenId === strike.id) ?? {
      amount0: new BN(0),
      amount1: new BN(0),
      withdrawableAmount0: new BN(0),
      withdrawableAmount1: new BN(0),
      liquidity: new BN(0),
    }

    const withdrawableLiquidity = reservedLiquidity.plus(totalLiquidity).gt(usedLiquidity)
      ? reservedLiquidity.plus(totalLiquidity).minus(usedLiquidity)
      : new BN(0)

    const sqrtRatioAX96 = TickMath.getSqrtRatioAtTick(Number(strike.tickLower))
    const sqrtRatioBX96 = TickMath.getSqrtRatioAtTick(Number(strike.tickUpper))
    const liquidity = JSBI.BigInt(withdrawableLiquidity.toFixed())

    const { amount0, amount1 } = getAmountsForLiquidity(
      sqrtPriceX96,
      sqrtRatioAX96,
      sqrtRatioBX96,
      liquidity
    )
    const [tickWithdrawbleAmount0, tickWithdrawbleAmount1] = [
      new BN(amount0.toString()),
      new BN(amount1.toString()),
    ]
    const { priceLower, priceUpper } = convertTicksToPriceRange(
      strike.tickLower,
      strike.tickUpper,
      poolBaseToken,
      poolQuoteToken
    )

    const withdrawableAmount0 = tickWithdrawbleAmount0.gte(userReserved.withdrawableAmount0)
      ? userReserved.withdrawableAmount0
      : new BN(0)
    const withdrawableAmount1 = tickWithdrawbleAmount1.gte(userReserved.withdrawableAmount1)
      ? userReserved.withdrawableAmount1
      : new BN(0)

    if (currentPrice.greaterThan(priceLower) && currentPrice.lessThan(priceUpper)) {
      const deltaLower = currentPrice.subtract(priceLower)
      const deltaUpper = priceUpper.subtract(currentPrice)
      const strikePrice = deltaLower.lessThan(deltaUpper) ? priceLower : priceUpper

      // size: reserved amount
      // withdrawable: withdraowable amount of reserved
      // amount: for calculating total amount USD (not decimalized size)
      return {
        id: strike.id,
        strikePrice: new BN(strikePrice.toSignificant()),
        token0: {
          size: userReserved.amount0.pow10ofMinus(strike.token0.decimals),
          withdrawable: withdrawableAmount0.pow10ofMinus(strike.token0.decimals),
          symbol: strike.token0.symbol,
          amount: userReserved.amount0,
        },
        token1: {
          size: userReserved.amount1.pow10ofMinus(strike.token1.decimals),
          withdrawable: withdrawableAmount1.pow10ofMinus(strike.token1.decimals),
          symbol: strike.token1.symbol,
          amount: userReserved.amount1,
        },
      }
    } else if (currentPrice.lessThan(priceLower)) {
      return {
        id: strike.id,
        strikePrice: new BN(priceUpper.toSignificant()),
        token0: {
          size: userReserved.amount0.pow10ofMinus(strike.token0.decimals),
          withdrawable: withdrawableAmount0.pow10ofMinus(strike.token0.decimals),
          symbol: strike.token0.symbol,
          amount: userReserved.amount0,
        },
      }
    } else {
      return {
        id: strike.id,
        strikePrice: new BN(priceLower.toSignificant()),
        token1: {
          size: userReserved.amount1.pow10ofMinus(strike.token1.decimals),
          withdrawable: withdrawableAmount1.pow10ofMinus(strike.token1.decimals),
          symbol: strike.token1.symbol,
          amount: userReserved.amount1,
        },
      }
    }
  })
  const _totalWithdrawableReserveLiquidity = _withdrawableReserveLiquidities.reduce((acc, cur) => {
    if (cur.token0) {
      acc.token0 = {
        size: acc.token0?.size.plus(cur.token0.size) ?? cur.token0.size,
        withdrawable:
          acc.token0?.withdrawable.plus(cur.token0.withdrawable) ?? cur.token0.withdrawable,
        symbol: cur.token0.symbol,
        amount: acc.token0?.amount.plus(cur.token0.amount) ?? cur.token0.amount,
      }
    }
    if (cur.token1) {
      acc.token1 = {
        size: acc.token1?.size.plus(cur.token1.size) ?? cur.token1.size,
        withdrawable:
          acc.token1?.withdrawable.plus(cur.token1.withdrawable) ?? cur.token1.withdrawable,
        symbol: cur.token1.symbol,
        amount: acc.token1?.amount.plus(cur.token1.amount) ?? cur.token1.amount,
      }
    }
    return acc
  }, {} as TotalWithdrawableReserveLiquidity & { token0: { amount: BN }; token1: { amount: BN } })

  const token0TotalAmount = JSBI.BigInt(
    (_totalWithdrawableReserveLiquidity.token0?.amount ?? new BN(0)).toString()
  )
  const token1TotalAmount = JSBI.BigInt(
    (_totalWithdrawableReserveLiquidity.token1?.amount ?? new BN(0)).toString()
  )

  const { totalAmountUSD } = convertTotalAmountEthAndUsd(
    token0TotalAmount,
    token1TotalAmount,
    pool.token0,
    pool.token1,
    ethPriceUSD
  )

  return {
    withdrawableReserveLiquidities: _withdrawableReserveLiquidities,
    totalWithdrawableReserveLiquidity: _totalWithdrawableReserveLiquidity,
    totalAmountUSD,
  }
}
