import Image from 'next/image'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

const defaultColorSet = {
  default: {
    bg: 'bg-main',
    text: 'dark:text-white',
  },
  active: {
    bg: 'dark:bg-gray-850',
    text: 'dark:text-white',
  },
}

type Props = {
  label: string
  href: string
  logoUrl?: any
  iconElement?: JSX.Element
  isActive?: boolean
  isTargetBlank?: boolean
  className?: string
  colorSet?: {
    default: { bg: string; text: string }
    active: { bg: string; text: string }
  }
}

export const MenuButton = ({
  label,
  logoUrl,
  iconElement,
  href,
  isActive,
  isTargetBlank = true,
  className,
  colorSet = defaultColorSet,
}: Props) => {
  const [classStr, labelStr] = isActive
    ? [colorSet.active.bg, colorSet.active.text]
    : [colorSet.default.bg, colorSet.default.text]
  const labelClass = !!logoUrl || !!iconElement ? 'md:ml-2.5 mt-2' : ''

  const content = (
    <div
      className={twMerge(
        `flex flex-col md:flex-row items-center rounded-xl px-2.5 py-3 outline-none md:w-full type-xs-caption md:type-sm-caption justify-center h-full ${classStr} ${className}`
      )}
    >
      {!!iconElement ? (
        iconElement
      ) : (
        <>{logoUrl && <Image src={logoUrl} alt={label} className="w-5  h-5" />}</>
      )}
      <span className={`${labelStr} ${labelClass} ml-0 md:mt-0 text-center`}>{label}</span>
    </div>
  )

  const isExternalLink = /^http/.test(href)

  return (
    <>
      {isExternalLink ? (
        <>
          {isTargetBlank ? (
            <a href={href} target="_blank" rel="noreferrer">
              {content}
            </a>
          ) : (
            <a href={href}>{content}</a>
          )}
        </>
      ) : (
        <Link href={href}>{content}</Link>
      )}
    </>
  )
}
