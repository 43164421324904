import { useResponsiveType, responsiveType } from '@apps-orangefi/hooks'
import { Stats } from '@apps-orangefi/ui/atoms'
import { StatsSkeleton } from '@apps-orangefi/ui/molecules/skeletons'
import { twMerge } from 'tailwind-merge'

type Props = {
  aprs: { label: string; value: string; tooltipText?: string }[]
  fetching: boolean
  className?: string
}

export const APRStats = ({ aprs, fetching, className }: Props) => {
  const rType = useResponsiveType()
  // const type = rType === responsiveType.SmallerThanMd ? 'vertical' : 'horizontal'
  const type = 'vertical'

  return (
    <div
      className={twMerge(
        'flex flex-row flex-wrap md:flex-nowrap gap-2 md:gap-5 justify-between w-full',
        className
      )}
    >
      {fetching ? (
        <>
          <StatsSkeleton type={type} />
          <StatsSkeleton type={type} />
          <StatsSkeleton type={type} />
          <StatsSkeleton type={type} />
        </>
      ) : (
        <>
          {aprs.map((apr, index) => (
            <Stats
              label={apr.label}
              value={apr.value}
              tooltipText={apr.tooltipText}
              type={type}
              key={`apr-${index}`}
              className="whitespace-pre"
            />
          ))}
        </>
      )}
    </div>
  )
}
