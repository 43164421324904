import { useModal } from '@apps-orangefi/hooks'
import {
  AccountAlphaModal,
  ConnectAlphaModal,
  TxAlphaModal,
  SwitchChainAlphaModal,
  AccountModal,
  ConnectModal,
  TxModal,
  WithdrawAndReserveModalContainer,
  V2WithdrawAndReserveModalContainer,
  ReserveAllLPModalContainer,
  SwitchChainModal,
  InvalidAddressModal,
  LockModalContainer,
  ExtendModalContainer,
  UnlockModalContainer,
} from '@apps-orangefi/ui/organisms/modals'

export const MODAL_TYPES = {
  AccountAlphaModal: 'AccountAlphaModal',
  ConnectAlphaModal: 'ConnectAlphaModal',
  TxAlphaModal: 'TxAlphaModal',
  SwitchChainAlphaModal: 'SwitchChainAlphaModal',
  AccountModal: 'AccountModal',
  ConnectModal: 'ConnectModal',
  TxModal: 'TxModal',
  WithdrawAndReserveModalContainer: 'WithdrawAndReserveModalContainer',
  V2WithdrawAndReserveModalContainer: 'V2WithdrawAndReserveModalContainer',
  ReserveAllLPModalContainer: 'WithdrawAllLPModalContainer',
  SwitchChainModal: 'SwitchChainModal',
  InvalidAddressModal: 'InvalidAddressModal',
  LockModalContainer: 'LockModalContainer',
  ExtendModalContainer: 'ExtendModalContainer',
  UnlockModalContainer: 'UnlockModalContainer',
} as const

// export type ModalTypes = typeof MODAL_TYPES[keyof typeof MODAL_TYPES]
export type AccountModalTypes = typeof MODAL_TYPES['AccountAlphaModal' | 'AccountModal']
export type TxModalTypes = typeof MODAL_TYPES['TxAlphaModal' | 'TxModal']
export type SwitchChainModalTypes = typeof MODAL_TYPES['SwitchChainAlphaModal' | 'SwitchChainModal']

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.AccountAlphaModal]: AccountAlphaModal,
  [MODAL_TYPES.ConnectAlphaModal]: ConnectAlphaModal,
  [MODAL_TYPES.TxAlphaModal]: TxAlphaModal,
  [MODAL_TYPES.SwitchChainAlphaModal]: SwitchChainAlphaModal,
  [MODAL_TYPES.AccountModal]: AccountModal,
  [MODAL_TYPES.ConnectModal]: ConnectModal,
  [MODAL_TYPES.TxModal]: TxModal,
  [MODAL_TYPES.WithdrawAndReserveModalContainer]: WithdrawAndReserveModalContainer,
  [MODAL_TYPES.V2WithdrawAndReserveModalContainer]: V2WithdrawAndReserveModalContainer,
  [MODAL_TYPES.ReserveAllLPModalContainer]: ReserveAllLPModalContainer,
  [MODAL_TYPES.SwitchChainModal]: SwitchChainModal,
  [MODAL_TYPES.InvalidAddressModal]: InvalidAddressModal,
  [MODAL_TYPES.LockModalContainer]: LockModalContainer,
  [MODAL_TYPES.ExtendModalContainer]: ExtendModalContainer,
  [MODAL_TYPES.UnlockModalContainer]: UnlockModalContainer,
}

export const GlobalModal = () => {
  const { modal } = useModal()
  const { modalType, modalProps } = modal || {}

  const renderComponent = () => {
    if (!modalType) {
      return null
    }
    const ModalComponent = MODAL_COMPONENTS[modalType]
    return <ModalComponent {...modalProps} />
  }

  return <>{renderComponent()}</>
}
