import { BN } from '@apps-orangefi/lib'

export const calculatePosition = (
  myPositionShare: string | undefined,
  totalAssets: string,
  totalSupply: string,
  decimals: number
): BN => {
  const share = new BN(myPositionShare ?? 0)
  const [assets, supply] = [new BN(totalAssets), new BN(totalSupply)]
  return supply.isZero() ? new BN(0) : share.times(assets).div(supply).pow10ofMinus(decimals)
}
