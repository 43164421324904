import CloseIcon from '@apps-orangefi/assets/images/icons/close.svg'
import {
  txStatus,
  Tx,
  txListAtom,
  migrationTxListAtom,
  isAllowedCloseModalAtom,
} from '@apps-orangefi/lib/store'
import { txLink } from '@apps-orangefi/lib/utils'
import { StatusIcon } from '@apps-orangefi/ui/molecules/icons'
import { BaseModal } from '@apps-orangefi/ui/organisms/modals'
import { useIsSafeWallet } from '@apps-orangefi/wagmi/hooks'
import { Dialog } from '@headlessui/react'
import { useAtomValue } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect } from 'react'
import { Chain } from 'wagmi/chains'

import type { PrimitiveAtom } from 'jotai'

export type TxModalProps = {
  title: string
  chain: Chain | undefined
  handleClose: () => void
  messages?: string[]
  isMigration?: boolean
  isDefaultClosable?: boolean
  children?: JSX.Element
}

type TxRowItemProps = {
  txAtom: PrimitiveAtom<Tx>
  chain: Chain | undefined
  showTxLink?: boolean
}

const TxRowItem = ({ txAtom, chain, showTxLink = true }: TxRowItemProps) => {
  const { t } = useTranslation()

  const tx = useAtomValue(txAtom)
  const { title, status, hash, error } = tx

  return (
    <div className="relative w-full ml-3">
      <div className="flex flex-row dark:bg-gray-800 bg-cards-foreground rounded-2xl flex-1 items-center justify-between px-4 py-4">
        <div className="type-base-semibold">{title}</div>
        <div className="w-8 h-8 text-center dark:text-gray-300">
          <StatusIcon status={status} />
        </div>
      </div>

      <div className="absolute bottom-[1.5px] right-8 md:right-3">
        {showTxLink && !!hash ? (
          <p className="text-weak text-sm text-right">
            <a href={txLink(chain, hash)} target="_blank" rel="noreferrer noopener">
              <span className="invisible md:visible">{t('MODAL.TX.VIEW_EXPLORER')}</span> &#8599;
            </a>
          </p>
        ) : null}
      </div>
      {status === txStatus.Error && !!error ? (
        <p className="absolute bottom-0.5 left-4 text-rose-300 text-sm text-right text-ellipsis whitespace-nowrap overflow-hidden">
          {error}
        </p>
      ) : null}
    </div>
  )
}

export const TxModal = ({
  title,
  chain,
  handleClose,
  messages,
  isMigration,
  isDefaultClosable = false,
  children,
}: TxModalProps) => {
  const _txListAtom = isMigration ? migrationTxListAtom : txListAtom
  const txList = useAtomValue(_txListAtom)
  const resetTxList = useResetAtom(_txListAtom)
  const isAllowedCloseModal = useAtomValue(isAllowedCloseModalAtom)
  const resetIsAllowedCloseModal = useResetAtom(isAllowedCloseModalAtom)
  const { isSafeWallet } = useIsSafeWallet()

  const onClose = useCallback(() => {
    if (isDefaultClosable) {
      resetTxList()
      handleClose()
    } else {
      if (isAllowedCloseModal) {
        resetTxList()
        handleClose()
        resetIsAllowedCloseModal()
      }
    }
  }, [resetTxList, handleClose, isAllowedCloseModal, resetIsAllowedCloseModal, isDefaultClosable])

  return (
    <BaseModal onClose={onClose}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl dark:bg-gray-900 bg-cards p-6 pb-8 text-left align-middle shadow-xl transition-all">
        <Dialog.Title
          as="div"
          className="flex flex-row justify-between type-xl-semibold dark:text-gray-400 text-start"
        >
          <div>{title}</div>
          {(isDefaultClosable || isAllowedCloseModal) && (
            <Image
              src={CloseIcon}
              width={12}
              height={12}
              alt="close"
              onClick={onClose}
              className="hover:cursor-pointer"
            />
          )}
        </Dialog.Title>
        <div className="mt-10">
          <ol className="list-number">
            {txList.map((txAtom, i) => (
              <li key={`modal-action-${i}`} className="flex items-center mb-3">
                <TxRowItem chain={chain} txAtom={txAtom} showTxLink={!isSafeWallet} />
              </li>
            ))}
          </ol>
          {messages && (
            <div className="mt-5 flex flex-col type-sm-normal">
              {messages.map((message, i) => (
                <div key={`tx-modal-msg-${i}`} className="mt-1">
                  {message}
                </div>
              ))}
            </div>
          )}
        </div>
        {!!children && children}
      </Dialog.Panel>
    </BaseModal>
  )
}
