import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts'
import { twMerge } from 'tailwind-merge'

interface PerformanceChartProps {
  token0Prices?: number[]
  token1Prices?: number[]
  vaultPricePerShare?: number[]
  timestamps?: number[]
  token0Name: string
  token1Name: string
}

const ROICard = ({ roi, name, color }: { roi: number; name: string; color?: string }) => {
  return (
    <div className="flex flex-col rounded-xl bg-gray-800 py-3 px-4">
      <div className="flex items-center border-bg-gray-500 border-b border-dashed w-fit font-semibold">
        {color && <div className={`w-2 h-2 rounded-[50%] mr-2 ${color}`}></div>}
        {name}
      </div>
      <p className={twMerge([roi > 0 ? 'text-emerald-400' : 'text-red-400', 'mt-1'])}>
        {roi.toFixed(2)}%
      </p>
    </div>
  )
}

const HistoricalPerformanceChart = ({
  token0Prices,
  token1Prices,
  vaultPricePerShare,
  timestamps,
  token0Name,
  token1Name,
}: PerformanceChartProps) => {
  if (!token0Prices || !token1Prices || !vaultPricePerShare || !timestamps) {
    return <></>
  }
  const dateStrings = timestamps.map(timestamp =>
    new Date(timestamp).toLocaleString('en-US', { month: 'short', day: 'numeric' })
  )

  const token0Pct = token0Prices.map(price => (100 * (price - token0Prices[0])) / token0Prices[0])
  const token1Pct = token1Prices.map(price => (100 * (price - token1Prices[0])) / token1Prices[0])
  const ppsPct = vaultPricePerShare.map(
    price => (100 * (price - vaultPricePerShare[0])) / vaultPricePerShare[0]
  )

  const data = token0Prices.map((price, idx) => ({
    vault: ppsPct[idx],
    initialDeposit: (token0Pct[idx] + token1Pct[idx]) / 2,
    token0: token0Pct[idx],
    token1: token1Pct[idx],
    date: dateStrings[idx],
  }))

  const orangeRoi = ppsPct.slice(-1)[0]
  const token0Roi = token0Pct.slice(-1)[0]
  const token1Roi = token1Pct.slice(-1)[0]
  const initialDepositRoi = (token0Roi + token1Roi) / 2

  return (
    <div
      className="dark:bg-gray-850 p-5 rounded-2xl mt-4"
      style={{
        width: '100%',
        height: '550px',
      }}
    >
      <p className="font-semibold text-lg mb-5">Performance</p>

      <ResponsiveContainer width="100%" height="75%">
        <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid
            id={'bar_chart_container'}
            horizontal={true}
            vertical={false}
            className="stroke-gray-750"
          />
          <XAxis dataKey="date" tickLine={false} interval={Math.floor(ppsPct.length / 8)} />
          <YAxis tickFormatter={value => `${value.toFixed(2)}%`} tickLine={false} />
          <Line activeDot={false} dot={false} type="linear" dataKey="vault" stroke="#FB5B0D" />
          <Line
            activeDot={false}
            dot={false}
            type="linear"
            dataKey="initialDeposit"
            stroke="#FFFFFF"
          />
          <Line activeDot={false} dot={false} type="linear" dataKey="token0" stroke="#D7A82A" />
          <Line activeDot={false} dot={false} type="linear" dataKey="token1" stroke="#9378BB" />
        </LineChart>
      </ResponsiveContainer>

      <div className="grid grid-cols-5 gap-3 mt-4">
        <ROICard roi={orangeRoi - initialDepositRoi} name={`Orange vs HOLD 50:50`} />
        <ROICard roi={orangeRoi} name={`Orange Position`} color={'bg-orange-500'} />
        <ROICard roi={initialDepositRoi} name={`HOLD 50:50`} color={'bg-white'} />
        <ROICard roi={token0Roi} name={`HOLD 100% ${token0Name}`} color={'bg-amber-400'} />
        <ROICard roi={token1Roi} name={`HOLD 100% ${token1Name}`} color={'bg-purple-400'} />
      </div>
    </div>
  )
}

export default HistoricalPerformanceChart
