module.exports = {
  vermilion: '#ff4c00',
  accent: '#ff7a41',
  lightPrimary: '#ffb696',
  blackPearl: '#020613',
  darkGrayishblue: '#282c38',
  charade: '#1f222c',
  brightGray: '#3a4050',
  white: '#ffffff',
  manatee: '#9ea0a8',
  frenchGray: '#bdbfc7',
  lightGray: '#dfe1e6',
  athensGray: '#eaebef',
  trout: '#484b56',
  black: '#000000',
  brick: 'rgba(202, 89, 55, 0.1)',
  darkBrick: '#993F21',
  darkPurple: 'rgba(237, 34, 192, 0.3)',
  thinYellow: 'rgba(255, 175, 29, 0.2)',

  thinGray: '#eeeeee',
  grey02: '#e5e5e5',
  smoke: 'rgba(255, 255, 255, 0.02)',
  red: {
    400: '#f1564c',
  },
  orange: {
    100: 'rgba(251, 91, 13, 0.12)',
    200: 'rgba(251, 91, 13, 0.32)',
    300: 'rgba(251, 91, 13, 0.64)',
    500: '#fb5b0d',
  },
  dark: {
    700: '#313642',
    800: '#20232c',
    950: 'rgba(9, 10, 14, 0.84)',
    980: 'rgba(6, 6, 6, 1)',
  },
  gray: {
    200: '#c9cbd0',
    250: '#BBC0CD',
    300: '#aeb1b9',
    400: '#9397a2',
    500: '#797e8b',
    600: '#5f636f',
    650: '#525661',
    700: '#454953',
    750: '#383b45',
    800: '#2b2e37',
    850: '#1e212a',
    900: '#11141c',
    950: '#0b0d11',
    1000: '#060606',
  },
  emerald: {
    400: '#5fff8b',
  },
  purple: {
    400: '#9378BB',
  },
  amber: {
    400: '#D7A82A',
  },
  point: {
    orange: '#ff4500',
    stryke: '#ebff00',
    pancake: '#49d5dd',
    sushi: '#e3b9db',
    limitless: '#3665ff',
    panoptic: '#8449e4',
    smilee: '#673ffc',
    'infinity-pools': '#00ff84',
    particle: '#f150da',
    camelot: '#ffaf1d',
    rodeo: '#f39c0f',
    ramses: '#eadabf',
    uniswap: '#f52ac8',
    radiant: '#3a62d3',
    umami: '#511cf3',
    'jones-dao': '#dc6a3a',
    vaultka: '#d8b68b',
    plutus: '#02eab1',
    gmx: '#04c9cd',
    aark: '#cc259a',
    'gamma-swap': '#4fbdf8',
    'apx-finance': '#9e3ffd',
  },
}
