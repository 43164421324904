import { useDebounce } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { useEffect, useMemo, useCallback } from 'react'
import { erc20Abi, maxUint256 } from 'viem'
import { BaseError } from 'wagmi'

import '@apps-orangefi/lib/extensions'

// TODO: pass object & use BNAmount
const useApprove = (
  tokenAddress: AddressType | undefined,
  owner: AddressType | undefined,
  spender: AddressType | undefined,
  amount: BN | undefined | 'max',
  decimals: number | undefined,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const _amount =
    amount === 'max' || !amount ? maxUint256 : amount?.pow10(decimals ?? 0).convertBigint()
  const args = useMemo(() => {
    return [spender!, _amount] as const
  }, [spender, JSON.stringify(amount), decimals])
  const enabled = useDebounce(
    !!tokenAddress && !!owner && !!spender && !!amount && _amount > 0 && !!decimals,
    300
  )

  const { data } = useSimulateContractWithErrorHandling({
    address: tokenAddress!,
    abi: erc20Abi,
    functionName: 'approve',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        console.log('useApprove write error', error)
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash }, callback)

  return {
    writeContract,
    data,
    hash,
    isSuccess,
    isReady: !!writeContract && !!data?.request,
    onApprove: useCallback(() => {
      if (!writeContract || !data?.request) {
        return
      }
      writeContract(data.request)
    }, [writeContract, data?.request]),
    waitLoading,
  }
}

export default useApprove
