import { BN } from '@apps-orangefi/lib/bn'
import { ERC20Token, PoolState } from '@apps-orangefi/lib/types'
import { type AbiParameter, Address, encodeAbiParameters } from 'viem'

import { bigintToBN } from './bn'

const Q192 = new BN(2).pow(192)
const ZERO_BN = new BN(0)
const ONE_BN = new BN(1)

export const calculatePriceImpact = (
  swapInAmount: BN,
  swapOutAmount: BN,
  tokenIn: ERC20Token | undefined,
  tokenOut: ERC20Token | undefined,
  poolState: PoolState | null
) => {
  if (!tokenIn || !tokenOut || !poolState) {
    return null
  }

  const { price0, price1 } = sqrtPriceX96ToTokenPrices(
    poolState.sqrtPriceX96,
    poolState.token0.decimals,
    poolState.token1.decimals
  )
  const isToken0SwapIn = tokenIn?.address === poolState?.token0.address
  const priceMarket = isToken0SwapIn ? price1 : price0
  // const fillPrice = swapOutAmount.div(swapInAmount)

  const swapCondition = getSwapCondition(swapInAmount, swapOutAmount, tokenIn, tokenOut)

  return {
    priceImpact: priceMarket.minus(swapCondition.fillPrice.price).div(priceMarket).times(100),
    ...swapCondition,
  }
}

export const getSwapCondition = (
  swapInAmount: BN,
  swapOutAmount: BN,
  tokenIn: ERC20Token,
  tokenOut: ERC20Token
) => {
  const fillPrice = swapOutAmount.div(swapInAmount)

  return {
    swapIn: {
      amount: swapInAmount,
      symbol: tokenIn.symbol,
    },
    swapOut: {
      amount: swapOutAmount,
      symbol: tokenOut.symbol,
    },
    fillPrice: {
      price: fillPrice,
      symbol: `${tokenOut.symbol}/${tokenIn.symbol}`,
    },
  }
}

export const calculateSlippagedAmount = (
  amountIn: bigint,
  sqrtPriceX96: bigint,
  slippageTorelance: number,
  forToken0: boolean
) => {
  const amount = convertAmountWithSqrtPriceX96(amountIn, sqrtPriceX96, forToken0)
  const slippagedAmount = amount.times((1 - slippageTorelance) / 100)

  return slippagedAmount.convertBigint()
}

function sqrtPriceX96ToTokenPrices(
  sqrtPriceX96: bigint,
  token0Decimals: number,
  token1Decimals: number
) {
  const sqrtPriceX96BN = new BN(sqrtPriceX96.toString())
  const priceX192 = sqrtPriceX96BN.pow(2)

  const price1 = priceX192
    .div(Q192)
    .times(10 ** token0Decimals)
    .div(10 ** token1Decimals)

  const price0 = safeDiv(ONE_BN, price1)

  return { price0, price1 }
}

function safeDiv(amount0: BN, amount1: BN): BN {
  if (amount1.isEqualTo(ZERO_BN)) {
    return ZERO_BN
  } else {
    return amount0.div(amount1)
  }
}

function convertAmountWithSqrtPriceX96(amount: bigint, sqrtPriceX96: bigint, forToken0: boolean) {
  const sqrtPriceX96BN = new BN(sqrtPriceX96.toString())
  const priceX192 = sqrtPriceX96BN.pow(2)
  const amountBN = bigintToBN(amount)

  return forToken0
    ? safeDiv(amountBN.times(Q192), priceX192)
    : safeDiv(amountBN.times(priceX192), Q192)
}

const swapActionPayloadStruct = [
  {
    type: 'tuple',
    name: 'swapActionPayload',
    components: [
      { type: 'address', name: 'swapper' },
      { type: 'address', name: 'tokenIn' },
      { type: 'address', name: 'tokenOut' },
      { type: 'uint256', name: 'amountInMax' },
      { type: 'uint256', name: 'amountOutMin' },
      { type: 'bytes', name: 'swapPayload' },
    ],
  },
] as const satisfies AbiParameter[]

export function encodeSwapActionPayload({
  swapper,
  tokenIn,
  tokenOut,
  amountInMax,
  amountOutMin,
  swapPayload,
}: {
  swapper: AddressType
  tokenIn: AddressType
  tokenOut: AddressType
  amountInMax: bigint
  amountOutMin: bigint
  swapPayload: `0x${string}`
}): `0x${string}` {
  return encodeAbiParameters(swapActionPayloadStruct, [
    {
      swapper,
      tokenIn,
      tokenOut,
      amountInMax,
      amountOutMin,
      swapPayload,
    },
  ])
}
