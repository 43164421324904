import { LottieOptions } from 'lottie-react'
import dynamic from 'next/dynamic'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { memo } from 'react'
import { twMerge } from 'tailwind-merge'

const Lottie = dynamic(() => import('lottie-react'), { ssr: false })

type Props = {
  title: string
  description: string
  figureSrc?: StaticImageData
  lottieOptions?: LottieOptions
  readMoreLink?: string
  className?: string
}

export const Feature = memo(function Feature({
  title,
  description,
  figureSrc,
  lottieOptions,
  readMoreLink,
  className,
}: Props) {
  const { t } = useTranslation()

  return (
    <div
      className={twMerge(
        'flex flex-col gap-9 w-fit h-fit rounded-2xl bg-cards font-inter p-5',
        'md:flex-row',
        className
      )}
    >
      <div className="flex flex-col w-full md:w-[55%]">
        <span className="type-xl-medium text-primary mt-2">{title}</span>
        <div className="type-sm-normal text-secondary h-fit mt-3">{description}</div>
        <>
          {!!readMoreLink && (
            <Link
              href={readMoreLink}
              className={twMerge(
                'w-fit mt-6 bg-cards-foreground py-2 px-3.5 rounded-lg type-sm-semibold text-primary self-end',
                'md:self-auto'
              )}
            >
              {t('DOCS.ACTION.READ_MORE')}
            </Link>
          )}
        </>
      </div>
      {!!figureSrc ? (
        <Image src={figureSrc} alt={title} width={325} height={240} className="w-full md:w-[45%]" />
      ) : (
        <>
          {!!lottieOptions && (
            <div className="w-full bg-cards rounded-2xl">
              <Lottie {...lottieOptions} />
            </div>
          )}
        </>
      )}
    </div>
  )
})
