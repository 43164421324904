import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { Abi, ContractFunctionArgs, ContractFunctionName } from 'viem'
import {
  useSimulateContract,
  useReadContract,
  useWriteContract,
  UseSimulateContractParameters,
  UseReadContractParameters,
  UseReadContractReturnType,
  UseWriteContractParameters,
  ResolvedRegister,
  Config,
  UseWriteContractReturnType,
  useReadContracts,
  UseReadContractsParameters,
  UseReadContractsReturnType,
  useWaitForTransactionReceipt,
  UseWaitForTransactionReceiptParameters,
  UseWaitForTransactionReceiptReturnType,
  BaseError,
  UseSimulateContractReturnType,
} from 'wagmi'
import { ReadContractData, ReadContractsData, WaitForTransactionReceiptData } from 'wagmi/query'

export function useSimulateContractWithErrorHandling<
  TAbi extends Abi | readonly unknown[],
  TFunctionName extends ContractFunctionName<TAbi, 'nonpayable' | 'payable'>
>(
  config: UseSimulateContractParameters<TAbi, TFunctionName>
): UseSimulateContractReturnType<TAbi, TFunctionName> {
  const result = useSimulateContract(config)

  useEffect(() => {
    if (result.error) {
      // Sentry にエラーを通知
      Sentry.captureException(result.error)
    }
  }, [result.error]) // errorが更新されるたびに通知を送信

  return result
}

export function useReadContractWithErrorHandling<
  abi extends Abi | readonly unknown[],
  functionName extends ContractFunctionName<abi, 'pure' | 'view'>,
  args extends ContractFunctionArgs<abi, 'pure' | 'view', functionName>,
  config extends Config = ResolvedRegister['config'],
  selectData = ReadContractData<abi, functionName, args>
>(
  parameters: UseReadContractParameters<abi, functionName, args, config, selectData>
): UseReadContractReturnType<abi, functionName, args, selectData> {
  const result = useReadContract(parameters)

  useEffect(() => {
    if (result.error) {
      // send error to sentry
      Sentry.captureException(result.error)
    }
  }, [result.error])

  return result
}

export function useReadContractsWithErrorHandling<
  TContracts extends readonly unknown[],
  TAllowFailure extends boolean = true,
  TConfig extends Config = Config,
  TSelectData = ReadContractsData<TContracts, TAllowFailure>
>(
  parameters: UseReadContractsParameters<TContracts, TAllowFailure, TConfig, TSelectData>
): UseReadContractsReturnType<TContracts, TAllowFailure, TSelectData> {
  const result = useReadContracts(parameters)
  result.error

  useEffect(() => {
    if (result.error) {
      // send error to sentry
      Sentry.captureException(result.error)
    }
  }, [result.error])

  return result
}

export function useWriteContractWithErrorHandling<TConfig extends Config, TContext>(
  config: UseWriteContractParameters<TConfig, TContext>
): UseWriteContractReturnType<TConfig, TContext> {
  const result = useWriteContract(config)

  useEffect(() => {
    if (result.error) {
      // send error to sentry
      Sentry.captureException(result.error)
    }
  }, [result.error])

  return result
}

export function useWaitForTransactionReceiptWithErrorHandling<
  TConfig extends Config,
  TChainId extends TConfig['chains'][number]['id'] = TConfig['chains'][number]['id'],
  TSelectData = WaitForTransactionReceiptData<TConfig, TChainId>
>(
  parameters: UseWaitForTransactionReceiptParameters<TConfig, TChainId, TSelectData>,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
): UseWaitForTransactionReceiptReturnType<TConfig, TChainId, TSelectData> {
  const result = useWaitForTransactionReceipt(parameters)

  useEffect(() => {
    if (result.error) {
      // send error to sentry
      Sentry.captureException(result.error)
    }
  }, [result.error])

  useEffect(() => {
    if (result.isSuccess) {
      if (callback && callback.success) {
        callback.success()
      }
    }
  }, [result.isSuccess])

  useEffect(() => {
    if (result.isError && result.error) {
      if (callback && callback.fail) {
        callback.fail(result.error.message)
      }
    }
  }, [result.isError, result.error])

  return result
}
