type SvgProps = {
  size?: number
  className?: string
}

export function InfoIcon({ size = 22, className }: SvgProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75ZM0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11ZM11 9.25C11.4142 9.25 11.75 9.58579 11.75 10V15C11.75 15.4142 11.4142 15.75 11 15.75C10.5858 15.75 10.25 15.4142 10.25 15V10C10.25 9.58579 10.5858 9.25 11 9.25ZM11 8C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
