import axios from 'axios'

type FetchSwapRouteKey = {
  queryKey: [
    string,
    {
      tokenIn: AddressType
      tokenOut: AddressType
      routerAddress: AddressType
      swapAmountIn: string
      slippageTolerance: bigint
      chainId: number
    }
  ]
}

export type SwapRouteResult = {
  swapAmountIn: string
  swapAmountOut: string
  priceImpact: string
  callData: `0x${string}`
  routerAddress: AddressType
}

const url = process.env.NEXT_PUBLIC_ORANGE_API_URL

export const fetchSwapRoute = async ({ queryKey }: FetchSwapRouteKey) => {
  if (!url) {
    return undefined
  }
  const [_, params] = queryKey

  const { chainId } = params
  const queryParams = {
    tokenIn: params.tokenIn,
    tokenOut: params.tokenOut,
    sender: params.routerAddress,
    recipient: params.routerAddress,
    swapAmountIn: params.swapAmountIn,
    slippageTolerance: params.slippageTolerance.toString(),
  }

  try {
    const res = await axios.get<SwapRouteResult>(`${url}/swap-route/${chainId}`, {
      params: queryParams,
    })

    return res.data
  } catch (error) {
    console.debug('Error fetching swap route', error)
    throw error
  }
}
