import BeraIcon from '@apps-orangefi/assets/images/tokens/bera.svg'
import SykIcon from '@apps-orangefi/assets/images/tokens/syk.svg'
import XSykIcon from '@apps-orangefi/assets/images/tokens/xsyk.svg'
import { BN } from '@apps-orangefi/lib'
import { fetchDexPair } from '@apps-orangefi/lib/api'
import { getDexScreenParam } from '@apps-orangefi/lib/utils'
import { useCurrentEpoch, useEpochRewards, Reward } from '@apps-orangefi/wagmi/hooks'
import { useQuery } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { StaticImageData } from 'next/image'
import { useMemo } from 'react'
import { zeroAddress } from 'viem'

const YEAR_IN_SECONDS = 31536000
const WEEK_IN_SECONDS = 604800
const SYK_TOKEN_ADDRESS = '0xACC51FFDeF63fB0c014c882267C3A17261A5eD50' as AddressType
const XSYK_TOKEN_ADDRESS = '0x50E04E222Fc1be96E94E86AcF1136cB0E97E1d40' as AddressType
const WBERA_TOKEN_ADDRESS = '0x6969696969696969696969696969696969696969' as AddressType

type IVault = { vaultAddress: AddressType; strykeGaugeId: AddressType | undefined; tvl: BN }

type StrykeRewardAPR = IVault & {
  epoch: number
  reward: BN
  totalRewardApr: BN
  aprList: {
    iconUrl: StaticImageData
    symbol: string
    tokenAddress: AddressType
    apr: BN
  }[]
}

export const useStrykeRewardAPR = (
  distributorAddress: AddressType | undefined,
  vaultList: IVault[],
  chainId: number
) => {
  const {
    currentEpoch,
    epochLength,
    controllerAddress,
    isFetching: isFetchingCurrentEpoch,
  } = useCurrentEpoch(distributorAddress)

  const dexScreenParam = getDexScreenParam(chainId)
  const { data: dataDexPair, isFetching: isFetchingDexScreener } = useQuery({
    queryKey: ['fetchDexPair', dexScreenParam!],
    queryFn: fetchDexPair,
    refetchOnWindowFocus: false,
    enabled: !!dexScreenParam,
  })

  const rewardTargets = useMemo(() => {
    if (!currentEpoch) {
      return []
    }
    return vaultList
      .filter(vault => !!vault.strykeGaugeId)
      .map(vault => ({
        vaultAddress: vault.vaultAddress,
        strykeGaugeId: vault.strykeGaugeId!,
        epoch: currentEpoch - 1,
      }))
  }, [vaultList, currentEpoch])

  const { rewards, isFetching: isFetchingRewards } = useEpochRewards(
    controllerAddress,
    rewardTargets
  )

  const strykeRewardsApr = useMemo(() => {
    if (!dexScreenParam || !dataDexPair) {
      return []
    }
    return calculateRewards(
      dexScreenParam.chainKey,
      vaultList,
      dataDexPair.priceUsd,
      rewards ?? [],
      epochLength
    )
  }, [
    dexScreenParam?.chainKey,
    JSON.stringify(vaultList),
    JSON.stringify(rewards),
    epochLength,
    dataDexPair?.priceUsd.toFixed(),
  ])

  return {
    strykeRewardsApr,
    rewardPriceUsd: dataDexPair?.priceUsd,
    isFetching: isFetchingCurrentEpoch || isFetchingRewards || isFetchingDexScreener,
  }
}

const calculateRewards = (
  chainKey: string,
  vaultList: IVault[],
  priceUsd: BN,
  contractRewards: Reward[],
  contractEpochLength?: number // arbitrum用の動的なepochLength
) => {
  const rewardTokens = getRewardTokensForChain(chainKey)
  const epochLength = getEpochLengthForChain(chainKey, contractEpochLength)
  const rewards = getRewardsForChain(chainKey, contractRewards)

  const rewardsWithApr = calculateCommonRewards(
    rewards,
    vaultList,
    epochLength,
    priceUsd,
    rewardTokens
  )

  if (chainKey === 'arbitrum') {
    return rewardsWithApr.map(reward => {
      return {
        ...reward,
        totalRewardApr: new BN(48.75),
      }
    })
  }
  return rewardsWithApr
}

const calculateCommonRewards = (
  rewards: Reward[],
  vaultList: IVault[],
  epochLength: number,
  priceUsd: BN,
  rewardTokens: { iconUrl: any; symbol: string; tokenAddress: AddressType }[]
) => {
  return vaultList.map(vault => {
    const reward = rewards.find(r => r.vaultAddress === vault.vaultAddress)
    const tvl = vault?.tvl

    if (!!tvl && tvl.gt(0) && !isNil(epochLength) && epochLength > 0) {
      const rewardUsd = reward?.reward.multipliedBy(priceUsd) ?? new BN(0)
      const totalRewardApr = rewardUsd
        .multipliedBy(YEAR_IN_SECONDS / epochLength)
        .div(tvl)
        .multipliedBy(100)

      return {
        ...vault,
        epoch: reward?.epoch ?? 0,
        reward: reward?.reward ?? new BN(0),
        totalRewardApr,
        aprList: rewardTokens.map(token => ({
          iconUrl: token.iconUrl,
          symbol: token.symbol,
          tokenAddress: token.tokenAddress,
          apr: totalRewardApr.div(rewardTokens.length), // トークンの数で分割
        })),
      }
    }
  })
}

const getRewardTokensForChain = (chainKey: string) => {
  switch (chainKey) {
    case 'arbitrum':
      return [
        { iconUrl: SykIcon, symbol: 'SYK', tokenAddress: SYK_TOKEN_ADDRESS },
        { iconUrl: XSykIcon, symbol: 'xSYK', tokenAddress: XSYK_TOKEN_ADDRESS },
      ]
    case 'berachain':
      return [{ iconUrl: BeraIcon, symbol: 'WBERA', tokenAddress: WBERA_TOKEN_ADDRESS }]
    default:
      return []
  }
}

const getEpochLengthForChain = (chainKey: string, contractEpochLength?: number) => {
  switch (chainKey) {
    case 'arbitrum':
      return contractEpochLength ?? 0 // use contract value on arbitrum
    case 'berachain':
      return WEEK_IN_SECONDS // use fixed value on berachain
    default:
      return 0
  }
}

const getRewardsForChain = (chainKey: string, rewards: Reward[]): Reward[] => {
  switch (chainKey) {
    case 'arbitrum':
      return rewards
    case 'berachain':
      return [
        {
          vaultAddress: '0x2f852e3102357ffc2283a974d42bd4b4cae9b5aa',
          strykeGaugeId: zeroAddress,
          epoch: 0,
          reward: new BN(875),
        },
        {
          vaultAddress: '0xf45747fde3586563e1fb9d50f815b70822176a46',
          strykeGaugeId: zeroAddress,
          epoch: 0,
          reward: new BN(625),
        },
        {
          vaultAddress: '0x4ea6efbecaabd7ba6e13f4847a518fa34729ac9f',
          strykeGaugeId: zeroAddress,
          epoch: 0,
          reward: new BN(375),
        },
      ]
    default:
      return []
  }
}
