import { useOrangeQuery } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { usdceAddressAtom } from '@apps-orangefi/lib/store'
import { getVaultListQuery } from '@apps-orangefi/lib/subgraph/queries'
import { GetVaultListQuery } from '@apps-orangefi/lib/subgraph/types/orange/graphql'
import {
  VaultInfo,
  ProductCardProps,
  Token,
  ProductType,
  merklRewardStatus,
  Pool,
} from '@apps-orangefi/lib/types'
import { convertUSDCSymbol, getToken, getTokenPair } from '@apps-orangefi/lib/utils'
import { useAtomValue } from 'jotai'
import { chain as _chain, isEqual } from 'lodash'
import { useEffect, useState, useMemo } from 'react'

import { ProductCardInfo, ALMAddressPair } from './type'

export const useV1VaultList = (account: AddressType | undefined, vaultInfoList: VaultInfo[]) => {
  const usdceAddress = useAtomValue(usdceAddressAtom)
  const [productCardList, setProductCardList] = useState<ProductCardInfo[]>([])
  const [almAddressList, setAlmAddressList] = useState<ALMAddressPair[]>([])

  const [result, reexecuteQuery] = useOrangeQuery<GetVaultListQuery>({
    query: getVaultListQuery,
    variables: { account: account?.toLowerCase() ?? '' },
    requestPolicy: 'network-only',
  })
  const { data, fetching, error } = result

  const myVaults = useMemo(() => {
    return data?.user?.positions.map(position => position.vault as AddressType) ?? []
  }, [data?.user?.positions])

  const totalFeeRevenue = useMemo(() => {
    return data?.vaults.reduce((sum: BN, vault) => {
      sum = sum.plus(new BN(vault.totalFeeUSD))
      return sum
    }, new BN(0))
  }, [data?.vaults])

  const allTvl = useMemo(() => {
    return data?.vaults.reduce((sum: BN, vault) => {
      sum = sum.plus(new BN(vault.tvl))
      return sum
    }, new BN(0))
  }, [data?.vaults])

  useEffect(() => {
    if (data) {
      const _productCardList = _chain(data.vaults)
        .map(vault => {
          const vaultInfo = vaultInfoList.find(
            (vaultInfo: VaultInfo) =>
              vaultInfo.VAULT_ADDRESS.toLowerCase() === vault.id.toLowerCase()
          )
          if (!vaultInfo) {
            return null
          }
          const myPosition = data.user?.positions.find(
            position => position.vault === vaultInfo?.VAULT_ADDRESS.toLowerCase()
          )
          // FIXME: exist similar variable name
          const [baseToken, quoteToken] = getTokenPair(vault, vault.pool as Pool)

          const symbol =
            convertUSDCSymbol(baseToken.id as AddressType, usdceAddress) ?? baseToken.symbol ?? ''

          const share = new BN(myPosition?.share ?? 0)
          const [totalAssets, totalSupply] = [new BN(vault.totalAssets), new BN(vault.totalSupply)]
          const amount = totalSupply.isZero()
            ? new BN(0)
            : share.times(totalAssets).div(totalSupply).pow10ofMinus(Number(baseToken.decimals))

          const amountUSD = amount
            .times(new BN(baseToken.derivedETH))
            .times(new BN(data.globalState?.ethPriceUSD ?? 0))

          const productCard: ProductCardInfo = {
            vaultAddress: vaultInfo.VAULT_ADDRESS,
            poolAddress: vault.pool.id.toLowerCase() as AddressType,
            category: vaultInfo.info.category,
            title: vaultInfo.info.productName,
            caption: vaultInfo.info.caption,
            description: vaultInfo.info.description,
            tags: vaultInfo.info.tags,
            totalDeposit: new BN(vault.totalAssets).pow10ofMinus(Number(vault.decimals)),
            tvl: new BN(vault.tvl),
            maxCapacity: new BN(vault.depositCap ?? 0).pow10ofMinus(Number(vault.decimals)),
            apr: null,
            feeApr: new BN(vault.feeAPR).multipliedBy(100),
            // myPosition: new BN(myPosition?.amount ?? 0).pow10ofMinus(vault.decimals),
            myPosition: amount,
            myPositionUSD: amountUSD,
            symbol,
            baseToken,
            quoteToken,
            imageUrls: vaultInfo.info.imageUrls,
            theme: vaultInfo.info.theme,
            platform: vaultInfo.info.platform,
            productType: (vaultInfo.info.productType ?? []) as ProductType[],
            merklRewardStatus: vaultInfo.info.merklRewardStatus ?? merklRewardStatus.Inactive,
          }
          return productCard
        })
        .compact()
        .value()
      setProductCardList(_productCardList)
    }
  }, [data, usdceAddress, vaultInfoList])
  // }, [data, usdceAddress])

  useEffect(() => {
    const _almAddressList = productCardList.map(productCard => {
      return {
        vaultAddress: productCard.vaultAddress.toLowerCase() as AddressType,
        poolAddress: productCard.poolAddress.toLowerCase() as AddressType,
      }
    })
    if (!isEqual(almAddressList, _almAddressList)) {
      setAlmAddressList(_almAddressList)
    }
  }, [productCardList])

  return {
    productCardList,
    myVaults,
    totalFeeRevenue,
    allTvl,
    almAddressList,
    fetching,
    error,
    reexecuteQuery,
  }
}
