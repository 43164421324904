import { useClaimForm, useUniV3Query } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { fetchDune, fetchBlockNumber } from '@apps-orangefi/lib/api'
import {
  getSpaceshipVaultListWithBlockNumberQuery,
  getSpaceshipVaultListQuery,
  uniV3PoolListWithBlockNumberQuery,
  uniV3PoolListQuery,
} from '@apps-orangefi/lib/subgraph/queries'
import { type GetPoolListQueryQuery } from '@apps-orangefi/lib/subgraph/types/uniswap/graphql'
import { VaultInfo, LeaderboardRow, Token, MyRank, category, amm } from '@apps-orangefi/lib/types'
import { SpaceshipDistributorList, getTokenPair } from '@apps-orangefi/lib/utils'
import { useClaimStatus } from '@apps-orangefi/wagmi/hooks'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { isEqual, isEmpty, chain, omit } from 'lodash'
import { useEffect, useState, useMemo } from 'react'
import { useQuery as useUrqlQuery } from 'urql'

type MyClaim = {
  index: BN
  amount: BN
  proof: AddressType[]
}

type TVL = {
  vaultAddress: AddressType
  productName: string
  baseToken: Token
  quoteToken: Token
  tvl: BN
}

export const useSpaceshipData = (
  duneApiKey: string | undefined,
  duneQueryId: string,
  vaultInfoList: VaultInfo[],
  account: AddressType | undefined,
  distibutorCotractAddress: AddressType | undefined,
  distibutorList: SpaceshipDistributorList,
  milestones: { value: BN; reward: number }[],
  mileStone1TopRanks: number,
  chainId: number,
  expiryTimestamp: number,
  arbDecimals: number
) => {
  const [leaderboards, setLeaderboards] = useState<LeaderboardRow[]>([])
  const [tvlList, setTvlList] = useState<TVL[]>([])
  const [myRank, setMyRank] = useState<MyRank | undefined>(undefined)
  const [totalTvl, setTotalTvl] = useState<BN>(new BN(0))
  const [myReward, setMyReward] = useState<BN>(new BN(0))
  const [myClaim, setMyClaim] = useState<MyClaim | undefined>(undefined)
  const [timestamp, setTimestamp] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (dayjs().unix() >= expiryTimestamp) {
      setTimestamp(expiryTimestamp - 1)
    } else {
      setTimestamp(undefined)
    }
  }, [expiryTimestamp])

  // unit of timestamp is seconds
  const { data: dataFindBlock } = useQuery({
    queryKey: ['findBlock', { chainId, timestamp }],
    queryFn: fetchBlockNumber,
    enabled: !!timestamp,
  })

  const { claimStatus } = useClaimStatus(distibutorCotractAddress, myClaim?.index, true)

  useEffect(() => {
    const claims = distibutorList.claims
    const claim = claims[account as AddressType]
    if (claim) {
      setMyReward(new BN(claim.amount).div(10 ** arbDecimals))
      setMyClaim({
        index: new BN(claim.index),
        amount: new BN(claim.amount),
        proof: claim.proof,
      })
    } else {
      setMyReward(new BN(0))
      setMyClaim(undefined)
    }
  }, [distibutorList, account])

  const { onClaim } = useClaimForm(distibutorCotractAddress, account, myClaim, claimStatus)

  const { data, isFetching } = useQuery({
    queryKey: ['spaceship', { queryId: duneQueryId, duneApiKey }],
    queryFn: fetchDune,
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    const _leaderboards =
      data?.result.rows.map(row => {
        return {
          rank: row.rank,
          walletAddress: row.user,
          poolShare: row.TotalShare ?? 0,
          score: row.points,
          isMyRank: row.user.toLowerCase() === account?.toLowerCase(),
        }
      }) ?? []
    if (!isEqual(_leaderboards, leaderboards)) {
      setLeaderboards(_leaderboards)
    }
  }, [data, account])

  const orengeQueryOption = useMemo(() => {
    return dataFindBlock?.number
      ? {
          query: getSpaceshipVaultListWithBlockNumberQuery,
          variables: { blockNumber: dataFindBlock?.number },
        }
      : {
          query: getSpaceshipVaultListQuery,
          variables: { account },
        }
  }, [dataFindBlock, account])

  const [result, reexecuteQuery] = useUrqlQuery(orengeQueryOption)
  const {
    data: dataOrange,
    fetching: fetchingOrange,
    error: error2,
  } = useMemo(() => {
    const { data, fetching, error } = result
    const vaults = (data?.vaults ?? []).map(vault => {
      return omit({ ...vault, pool: vault.pool?.id }, ['__typename'])
    })
    const allVaults = chain(data?.dopexVaults ?? [])
      .concat(vaults)
      .value()

    return { data: { allVaults }, fetching, error }
  }, [result])

  const uniV3QueryOption = useMemo(() => {
    const poolIds = chain(dataOrange.allVaults)
      .map(vault => vault.pool)
      .uniq()
      .value()

    const baseOption = dataFindBlock?.number
      ? {
          query: uniV3PoolListWithBlockNumberQuery,
          variables: { poolIds, blockNumber: dataFindBlock?.number },
        }
      : {
          query: uniV3PoolListQuery,
          variables: { poolIds },
        }
    return {
      ...baseOption,
      pause: isEmpty(dataOrange.allVaults),
    }
  }, [dataFindBlock, dataOrange])

  const [resultUniV3] = useUniV3Query<GetPoolListQueryQuery>(uniV3QueryOption)
  const { data: dataUniV3, fetching: fetchingUniV3, error: errorUniV3 } = resultUniV3

  useEffect(() => {
    const eligibleVaultList = vaultInfoList.filter(vault => !vault.info.spaceshipBlackListed)
    const _tvlList = chain(dataOrange.allVaults)
      .map(vault => {
        const vaultInfo = eligibleVaultList.find(vi => vi.VAULT_ADDRESS.toLowerCase() === vault.id)
        if (vaultInfo?.info.category === category.Closed) return

        const pool = dataUniV3?.pools.find(pool => pool.id === vault?.pool)
        const ethPriceUSD = dataUniV3?.bundle?.ethPriceUSD
        if (!vaultInfo || !pool || !ethPriceUSD) {
          return
        }
        const [baseToken, quoteToken] = getTokenPair(vault, pool)
        const tvl = new BN(vault.totalAssets)
          .dividedBy(10 ** Number(baseToken.decimals))
          .times(baseToken.derivedETH)
          .times(ethPriceUSD)

        const dex = vaultInfo.info.platform?.amm
        const prefix = dex === amm.UNISWAP ? 'Uniswap' : dex === amm.PANCAKESWAP ? 'PCS' : ''

        return {
          vaultAddress: vault.id as AddressType,
          productName: `${vaultInfo.info.tags[0]}${prefix} ${vaultInfo.info.productName}`,
          baseToken,
          quoteToken,
          tvl,
        }
      })
      .compact()
      .sortBy(x => x.tvl.toNumber())
      .reverse()
      .value() as TVL[]

    if (!isEqual(_tvlList, tvlList)) {
      setTvlList(_tvlList)
    }

    const _totalTvl = _tvlList.reduce((acc, cur) => acc.plus(cur.tvl), new BN(0))
    if (!_totalTvl.eq(totalTvl)) {
      setTotalTvl(_totalTvl)
    }
  }, [dataOrange, dataUniV3, vaultInfoList])

  useEffect(() => {
    const matchedRow = leaderboards.find(
      item => item.walletAddress.toLowerCase() === account?.toLowerCase()
    )
    if (!matchedRow) {
      setMyRank(undefined)
      return
    }

    setMyRank({
      rank: matchedRow.rank,
      walletAddress: matchedRow.walletAddress,
      isRewardDistributed: !!myClaim,
      isAchievedMilestone1:
        totalTvl.gte(milestones[0].value) && matchedRow.rank <= mileStone1TopRanks,
      isAchievedMilestone2: totalTvl.gte(milestones[1].value),
      totalReward: myReward,
    })
  }, [leaderboards, account, totalTvl, myReward, myClaim])

  const fetching = useMemo(() => {
    return {
      dune: isFetching,
      subgraph: fetchingOrange || fetchingUniV3,
    }
  }, [isFetching, fetchingOrange, fetchingUniV3])

  return { leaderboards, tvlList, totalTvl, myRank, claimStatus, onClaim, fetching }
}
