import ArbIcon from '@apps-orangefi/assets/images/tokens/arb.svg'
import BeraIcon from '@apps-orangefi/assets/images/tokens/bera.svg'
import BoopIcon from '@apps-orangefi/assets/images/tokens/boop.svg'
import DaiIcon from '@apps-orangefi/assets/images/tokens/dai.svg'
import EzEthIcon from '@apps-orangefi/assets/images/tokens/ezETH.svg'
import HoneyIcon from '@apps-orangefi/assets/images/tokens/honey.svg'
import UsdcIcon from '@apps-orangefi/assets/images/tokens/usdc.svg'
import UsdtIcon from '@apps-orangefi/assets/images/tokens/usdt.svg'
import WbtcIcon from '@apps-orangefi/assets/images/tokens/wbtc.svg'
import WeEthIcon from '@apps-orangefi/assets/images/tokens/weETH.svg'
import WethIcon from '@apps-orangefi/assets/images/tokens/weth.svg'
import WstEthIcon from '@apps-orangefi/assets/images/tokens/wstETH.svg'
import { BN } from '@apps-orangefi/lib'
import { Pool } from '@apps-orangefi/lib/subgraph/types/orange/graphql'
import { Token as UniV3Token } from '@apps-orangefi/lib/subgraph/types/uniswap/graphql'
import { PoolToken, Token } from '@apps-orangefi/lib/types'
import { StaticImageData } from 'next/image'
import { zeroAddress } from 'viem'

export const getTokenIcon = (symbol: string): StaticImageData | null => {
  switch (symbol) {
    case 'ARB':
      return ArbIcon
    case 'USDC':
      return UsdcIcon
    case 'WBTC':
      return WbtcIcon
    case 'WETH':
      return WethIcon
    case 'DAI':
      return DaiIcon
    case 'USDT':
      return UsdtIcon
    case 'Boop':
      return BoopIcon
    case 'wstETH':
      return WstEthIcon
    case 'weETH':
      return WeEthIcon
    case 'ezETH':
      return EzEthIcon
    case 'HONEY':
      return HoneyIcon
    case 'WBERA':
      return BeraIcon
    default:
      return null
  }
}

interface IVaultReversibleTokenPair {
  isTokenPairReversed: boolean
}
const defaultToken: Token = {
  id: zeroAddress,
  symbol: '',
  name: '',
  decimals: '18' as never,
  derivedETH: '0',
}
export const getTokenPair = (
  vault: IVaultReversibleTokenPair,
  pool: Pool | undefined
): [Token, Token] => {
  if (!pool) {
    return [defaultToken, defaultToken]
  }
  const token0 = {
    ...pool.token0,
    id: pool.token0.id.toLowerCase(),
    decimals: Number(pool.token0.decimals),
  } as Token
  const token1 = {
    ...pool.token1,
    id: pool.token1.id.toLowerCase(),
    decimals: Number(pool.token1.decimals),
  } as Token
  return vault.isTokenPairReversed ? [token1, token0] : [token0, token1]
}

export function convertToPoolToken(token: Token | UniV3Token): PoolToken {
  return {
    address: token.id as AddressType,
    name: token.name,
    symbol: token.symbol,
    decimals: Number(token.decimals),
    derivedETH: new BN(token.derivedETH),
  }
}
