import { useVaultQueries, useStrykeQueries } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { Pool } from '@apps-orangefi/lib/types'
import { calculateAPR, DopexStrikeEarningPerVault } from '@apps-orangefi/lib/utils'
import { chain as _chain, isEqual, uniqBy } from 'lodash'
import { useMemo } from 'react'

type Props = {
  account: AddressType | undefined
  vaultAddress: AddressType
  startTime: number
}

export const useStrykeStats = ({ account, vaultAddress, startTime }: Props) => {
  const {
    orangeData,
    uniV3Data,
    fetching: mainFetching,
    error: errorVault,
    reexecuteQuery: reexecuteQueryVault,
  } = useVaultQueries(account, vaultAddress)
  const {
    strykeFirstData,
    strykeSecondData,
    fetching: strykeFetching,
    reexecuteQuery: reexecuteQueryStryke,
  } = useStrykeQueries([vaultAddress], startTime)

  // Process Dopex earnings
  const dopexStrikeEarnings = useMemo((): DopexStrikeEarningPerVault[] => {
    const lpPositions = strykeFirstData?.lppositions ?? []
    const dailyDonations = uniqBy(strykeSecondData?.dailyDonations, 'strike.id')
    const dailyFeeCompounds = uniqBy(strykeSecondData?.dailyFeeCompounds, 'strike.id')

    return _chain(lpPositions)
      .map(lpPosition => {
        const donation = dailyDonations.find(d => d.strike.id === lpPosition.strike.id)
        const compound = dailyFeeCompounds.find(c => c.strike.id === lpPosition.strike.id)
        if (!donation && !compound) return null

        return {
          sqrtPriceX96: donation?.sqrtPriceX96 ?? compound?.sqrtPriceX96,
          donation: donation?.donation ?? '0',
          compound: compound?.compound ?? '0',
          strike: lpPosition?.strike,
          pool: lpPosition.pool,
          shares: lpPosition.shares,
          user: lpPosition.user,
          handler: lpPosition.handler,
        }
      })
      .compact()
      .value()
  }, [strykeFirstData, strykeSecondData])

  // Calculate stats
  const stats = useMemo(() => {
    if (!orangeData?.dopexVault || !uniV3Data?.pool || !uniV3Data?.bundle) return undefined

    return calculateAPR(
      dopexStrikeEarnings,
      orangeData.dopexVault,
      uniV3Data.pool as Pool,
      new BN(uniV3Data.bundle.ethPriceUSD)
    )
  }, [orangeData?.dopexVault, uniV3Data?.pool, uniV3Data?.bundle, dopexStrikeEarnings])

  return {
    stats,
    orangeData,
    uniV3Data,
    strykeFirstData,
    strykeSecondData,
    isFetchingMain: mainFetching.orange || mainFetching.uniV3,
    isFetchingStryke: strykeFetching.first || strykeFetching.second,
    reexecuteQueryVault,
    reexecuteQueryStryke,
    errorVault,
  }
}
