import { twMerge } from 'tailwind-merge'

type Props = {
  label: string | JSX.Element
  disabled?: boolean
  animate?: boolean
  onSubmit?: (e?: Event) => void
  className?: string
}

export const Button = ({
  label,
  disabled = false,
  animate = false,
  onSubmit,
  className,
}: Props) => {
  // Add type-base-bold by default if className does not contain type-.
  const defaultClass = className && className.includes('type-') ? '' : 'type-base-bold'

  return (
    <button
      disabled={disabled}
      className={twMerge(
        `px-10 py-5 rounded-2xl bg-orange-500 w-fit text-white outline-0 hover:opacity-90`,
        disabled
          ? 'dark:bg-gray-800 dark:text-gray-600 bg-muted text-muted cursor-not-allowed'
          : '',
        animate ? 'animate-highlight' : '',
        defaultClass,
        className
      )}
      onClick={() => {
        if (onSubmit) {
          onSubmit()
        }
      }}
    >
      {label}
    </button>
  )
}
