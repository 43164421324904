import { MIN_FACTOR } from '@apps-orangefi/lib/constants'
import { isZeroHash } from '@apps-orangefi/lib/utils'
import { StrykeAssetActionsABI } from '@apps-orangefi/wagmi/abis'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { isEqual } from 'lodash'
import { useMemo, useEffect, useState, useCallback } from 'react'
import { encodeFunctionData, zeroHash } from 'viem'
import { BaseError } from 'wagmi'
import '@apps-orangefi/lib/extensions'

export type RedeemAndSwapParams = {
  shares: bigint
  recipient: AddressType
  receiveToken: AddressType
  amount0Min: bigint
  amount1Min: bigint
  swapData: AddressType | undefined
  isNativeTokenRedeem: boolean
}

type Props = {
  routerAddress: AddressType | undefined
  vaultAddress: AddressType | undefined
  params: RedeemAndSwapParams
  isEnabled: boolean
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
}

export const useRedeemAndSwap = ({
  routerAddress,
  vaultAddress,
  params,
  isEnabled,
  callback,
}: Props) => {
  const [receiveTokenAmountMin, setReceiveTokenAmountMin] = useState<bigint>(BigInt(0))
  const { shares, amount0Min, amount1Min, recipient, swapData, receiveToken, isNativeTokenRedeem } =
    params

  const args = useMemo(() => {
    return [
      vaultAddress!,
      {
        shares,
        recipient: recipient!,
        receiveToken: receiveToken!,
        amount0Min,
        amount1Min,
        swapActionPayloads: !swapData || isZeroHash(swapData!) ? [] : [swapData!],
        receiveTokenAmountMin,
        isNativeTokenRedeem,
      },
    ] as const
  }, [vaultAddress, JSON.stringify(params), receiveTokenAmountMin])

  const enabled = useMemo(() => {
    return (
      isEnabled && !!routerAddress && !!vaultAddress && shares > 0 && !!recipient && !!receiveToken
    )
  }, [isEnabled, routerAddress, vaultAddress, JSON.stringify(params)])

  // debug code
  const calldata = enabled
    ? encodeFunctionData({
        abi: StrykeAssetActionsABI,
        functionName: 'strykeRedeemAndSwap',
        args,
      })
    : undefined

  console.debug({ calldata })

  const {
    data: redeemAndSwapData,
    failureReason,
    isFetching,
  } = useSimulateContractWithErrorHandling({
    address: routerAddress,
    abi: StrykeAssetActionsABI,
    functionName: 'strykeRedeemAndSwap',
    args,
    query: {
      enabled,
    },
  })

  useEffect(() => {
    if (!!failureReason) {
      console.debug('strykeRedeemAndSwap failure reason: ', failureReason)
    }
  }, [failureReason])

  const [receiveAmount, lockedTokens, swapAmountIn, swapAmountOut] = useMemo(() => {
    const [receiveAmount, additionalContext, swapAmountIn, swapAmountOut] =
      redeemAndSwapData?.result ?? [BigInt(0), zeroHash, BigInt(0), BigInt(0)]
    return [
      receiveAmount,
      additionalContext === zeroHash ? [] : [...additionalContext.lockedTokens],
      swapAmountIn,
      swapAmountOut,
    ]
  }, [redeemAndSwapData?.result])

  useEffect(() => {
    if (!receiveAmount) return
    const _receiveTokenAmountMin = (receiveAmount * BigInt(MIN_FACTOR * 100)) / BigInt(100)
    if (!isEqual(_receiveTokenAmountMin, receiveTokenAmountMin)) {
      setReceiveTokenAmountMin(_receiveTokenAmountMin)
    }
  }, [receiveAmount])

  useEffect(() => {
    if (receiveTokenAmountMin !== BigInt(0)) {
      setReceiveTokenAmountMin(BigInt(0))
    }
    return () => {
      setReceiveTokenAmountMin(BigInt(0))
    }
  }, [shares])

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash }, callback)

  return {
    isFetching,
    isWriteReady: !!writeContract && !!redeemAndSwapData?.request && !isFetching,
    hash,
    isSuccess,
    onRedeemAndSwap: useCallback(() => {
      if (!writeContract || !redeemAndSwapData?.request || isFetching) {
        return
      }
      writeContract(redeemAndSwapData.request)
    }, [writeContract, redeemAndSwapData?.request, isFetching]),
    redeemResult: {
      receiveAmount,
      lockedTokens: lockedTokens.sort((a, b) => b.tickLower - a.tickLower),
    },
    swapAmount: {
      amountIn: swapAmountIn,
      amountOut: swapAmountOut,
    },
    // strykeRedeemAndSwapParams: args[1],
  }
}
