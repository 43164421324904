import OptionPremiumIcon from '@apps-orangefi/assets/images/revenues/option-premium.svg'
import SwapFeeIcon from '@apps-orangefi/assets/images/revenues/swap-fee.svg'
import {
  Amm,
  amm,
  Derivative,
  derivative,
  Vault,
  vaultVersion,
  VaultVersion,
} from '@apps-orangefi/lib/types'
import {
  SwapFeeIcon as V2SwapFeeIcon,
  OptionPremiumIcon as V2OptionPremiumIcon,
} from '@apps-orangefi/ui/atoms/icons'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  amm: Amm | undefined
  derivative?: Derivative | undefined
  size?: number
  version?: VaultVersion
  className?: string
}

export const SourceRevenue = ({
  amm,
  derivative,
  size = 20,
  version = vaultVersion.V1,
  className = '',
}: Props) => {
  const revenues = [
    getSourceRevenue(amm, version, size),
    getSourceRevenue(derivative, version, size),
  ].filter(r => !!r)

  return (
    <div
      className={twMerge('flex flex-row flex-wrap justify-start items-center relative', className)}
    >
      {revenues.map((revenue, index) => (
        <div key={index} className="flex">
          {revenue && (
            <div className="flex flex-row">
              <div className="text-primary">{revenue[1]}</div>
              <div className="ml-2">
                {version === 'v1' ? revenue[0]?.toUpperCase() : revenue[0]}
              </div>
            </div>
          )}
          {revenues.length > 1 && index < revenues.length - 1 && (
            <span className="type-sm-caption dark:text-gray-650 mx-3">/</span>
          )}
        </div>
      ))}
    </div>
  )
}

const getSourceRevenue = (
  key: Amm | Derivative | undefined,
  version: VaultVersion,
  size: number
): [string, any] | null => {
  switch (key) {
    case derivative.STRYKE:
      return [
        'Option Premium',
        version === vaultVersion.V2 ? (
          <V2OptionPremiumIcon />
        ) : (
          <Image
            src={OptionPremiumIcon}
            alt="revenue"
            width={size}
            height={size}
            className="rounded-full"
          />
        ),
      ]
    case amm.UNISWAP:
    case amm.PANCAKESWAP:
    case amm.SUSHI:
    case amm.CAMELOT:
    case amm.KODIAK:
      return [
        'Swap Fee',
        version === vaultVersion.V2 ? (
          <V2SwapFeeIcon />
        ) : (
          <Image
            src={SwapFeeIcon}
            alt="revenue"
            width={size}
            height={size}
            className="rounded-full"
          />
        ),
      ]
    default:
      return null
  }
}
