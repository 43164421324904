import CheckIcon from '@apps-orangefi/assets/images/check-orange.svg'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  checked: boolean
  label: string
  onChange: (checked: boolean) => void
}

export const SingleCheckbox = ({ checked, onChange, label }: Props) => {
  const handleChange = () => {
    onChange(!checked)
  }

  return (
    <div className="flex flex-row items-center gap-1.5 hover:cursor-pointer" onClick={handleChange}>
      <div
        className={twMerge(
          'w-5 h-5 border rounded-lg',
          'flex flex-col justify-center items-center',
          checked ? 'border-orange-500 bg-orangeAlpha' : ''
        )}
      >
        {checked && <Image src={CheckIcon} width={12} height={12} alt="Check icon" />}
      </div>
      <span className="type-sm-medium text-primary font-inter">{label}</span>
    </div>
  )
}
