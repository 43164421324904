import {
  VaultInfoList,
  VaultInfoWithKey,
  ProductKey,
  ProductInfo,
  ArbitrumProductKey,
  BartioProductKey,
  BeraProductKey,
} from '@apps-orangefi/lib/types'

export type ProductListType = { [key in ArbitrumProductKey]: ProductInfo } & {
  [key in BartioProductKey]: ProductInfo
} & { [key in BeraProductKey]: ProductInfo }

export const getHelpers = (
  contractAddresses: VaultInfoList,
  supportedChainIds: number[],
  productInfoListByChain: Record<number, ProductListType>
) => {
  const getSupportedContractAddresses = (): VaultInfoList => {
    return Object.keys(contractAddresses)
      .map(k => Number(k))
      .reduce((acc, key) => {
        return supportedChainIds.includes(key)
          ? Object.assign(acc, { [key]: contractAddresses[key] })
          : acc
      }, {})
  }

  const SUPPORTED_CONTRACT_ADDRESSES = getSupportedContractAddresses()

  const getContractInfoListByChainId = (chainId: number): VaultInfoWithKey[] => {
    const targetChainContractInfo = SUPPORTED_CONTRACT_ADDRESSES[chainId] ?? []
    const productInfoList = productInfoListByChain[chainId]

    return targetChainContractInfo.map(contractInfo => {
      const key = contractInfo.key
      const productContractInfo = {
        ...contractInfo,
        info: {
          ...productInfoList[key],
        },
      }
      if (contractInfo.info?.productName) {
        productContractInfo.info.productName = contractInfo.info.productName
      }
      return productContractInfo
    })
  }

  const getContractInfoByVaultAddress = (
    chainId: number,
    vaultAddress: AddressType
  ): VaultInfoWithKey => {
    const productContractInfo = getContractInfoListByChainId(chainId).find(
      contractInfo => contractInfo.VAULT_ADDRESS.toLowerCase() === vaultAddress.toLowerCase()
    )
    if (!productContractInfo) {
      throw new Error(
        `No product contract info found for chainId: ${chainId} and vaultAddress: ${vaultAddress}`
      )
    }
    return productContractInfo
  }

  return {
    getSupportedContractAddresses,
    SUPPORTED_CONTRACT_ADDRESSES,
    getContractInfoListByChainId,
    getContractInfoByVaultAddress,
  }
}
