import { AlertTriangle } from 'lucide-react'
import { twMerge } from 'tailwind-merge'

type Props = {
  message: string | undefined
  className?: string
}

export const ErrorMessage = ({ message, className }: Props) => {
  return (
    <>
      {message && (
        <div
          className={twMerge(
            `flex flex-row text-lightPrimary items-center justify-start px-2 py-2 ${className}`
          )}
        >
          <AlertTriangle size={16} />
          <span className="ml-1.5 text-sm whitespace-pre-wrap text-right">{message}</span>
        </div>
      )}
    </>
  )
}
