import { MIN_FACTOR } from '@apps-orangefi/lib/constants'
import '@apps-orangefi/lib/extensions'
import { StrykeAssetActionsABI } from '@apps-orangefi/wagmi/abis'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { isEqual } from 'lodash'
import { useMemo, useEffect, useState, useCallback } from 'react'
import { encodeFunctionData } from 'viem'
import { BaseError } from 'wagmi'

export type RedeemParams = {
  shares: bigint
  recipient: AddressType | undefined
  receiveNativeToken: boolean
}

type Props = {
  routerAddress: AddressType | undefined
  vaultAddress: AddressType | undefined
  params: RedeemParams
  isEnabled: boolean
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
}

type MinValues = {
  amount0Min: bigint
  amount1Min: bigint
}

const DEFAULT_MIN_VALUES = {
  amount0Min: BigInt(0),
  amount1Min: BigInt(0),
}

export const useRedeem = ({ routerAddress, vaultAddress, params, isEnabled, callback }: Props) => {
  const [minValues, setMinValues] = useState<MinValues>(DEFAULT_MIN_VALUES)

  const { shares, recipient, receiveNativeToken } = params

  const args = useMemo(() => {
    return [
      vaultAddress!,
      {
        shares: shares,
        recipient: recipient!,
        amount0Min: minValues.amount0Min,
        amount1Min: minValues.amount1Min,
        receiveNativeToken,
      },
    ] as const
  }, [vaultAddress, JSON.stringify(params), minValues])

  const enabled = useMemo(() => {
    return isEnabled && !!routerAddress && !!vaultAddress && shares > 0 && !!recipient
  }, [isEnabled, routerAddress, vaultAddress, JSON.stringify(params)])

  // debug code
  const calldata = enabled
    ? encodeFunctionData({
        abi: StrykeAssetActionsABI,
        functionName: 'strykeRedeem',
        args,
      })
    : undefined

  console.debug({ calldata })

  useEffect(() => {
    if (minValues.amount0Min !== BigInt(0) || minValues.amount1Min !== BigInt(0)) {
      setMinValues(DEFAULT_MIN_VALUES)
    }
    return () => {
      setMinValues(DEFAULT_MIN_VALUES)
    }
  }, [shares])

  const {
    data: redeemData,
    failureReason,
    isFetching,
  } = useSimulateContractWithErrorHandling({
    address: routerAddress,
    abi: StrykeAssetActionsABI,
    functionName: 'strykeRedeem',
    args,
    account: recipient,
    query: {
      enabled,
    },
  })

  useEffect(() => {
    if (!!failureReason) {
      console.debug('strykeRedeem failure reason: ', failureReason)
    }
  }, [failureReason])

  const redeemResult = useMemo(() => {
    if (!redeemData?.result) return
    const [amount0Received, amount1Received, additionalContext] = redeemData.result

    return {
      amount0Received,
      amount1Received,
      lockedTokens: [...additionalContext.lockedTokens].sort((a, b) => b.tickLower - a.tickLower),
    }
  }, [JSON.stringify(redeemData?.result)])

  useEffect(() => {
    if (!redeemResult) return
    const { amount0Received, amount1Received } = redeemResult

    const _minValues = {
      amount0Min: (amount0Received * BigInt(MIN_FACTOR * 100)) / BigInt(100),
      amount1Min: (amount1Received * BigInt(MIN_FACTOR * 100)) / BigInt(100),
    }

    if (!isEqual(_minValues, minValues)) {
      setMinValues(_minValues)
    }
  }, [redeemResult])

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash }, callback)

  return {
    isFetching,
    isWriteReady: !!writeContract && !!redeemData?.request && !isFetching,
    hash,
    isSuccess,
    onRedeem: useCallback(() => {
      if (!writeContract || !redeemData?.request || isFetching) {
        return
      }
      writeContract(redeemData.request)
    }, [writeContract, redeemData?.request, isFetching]),
    redeemResult: redeemResult ?? {
      amount0Received: BigInt(0),
      amount1Received: BigInt(0),
      lockedTokens: [],
    },
    amountMin: minValues,
  }
}
