import { getHelpers, ProductListType } from '@apps-orangefi/lib/constants/helpers'
import {
  VaultInfoList,
  ProductContractInfo,
  productKey,
  ProductKey,
} from '@apps-orangefi/lib/types'
import { SpaceshipDistributorList } from '@apps-orangefi/lib/utils'
import { arbitrum, berachain, polygon } from 'wagmi/chains'

import { productInfoList } from './products-info'
import {
  CONTRACT_ADDRESSES as APP_CONTRACT_ADDRESSES,
  SPACESHIP_DISTRIBUTOR_ADDRESS as APP_SPACESHIP_DISTRIBUTOR_ADDRESS,
  STRYKE_VAULT_INSPECTOR_ADDRESS as APP_STRYKE_VAULT_INSPECTOR_ADDRESS,
  ASTRONAUT_NFT_ADDRESS as APP_ASTRONAUT_NFT_ADDRESS,
  ASTRONAUT_PROXY_ADDRESS as APP_ASTRONAUT_PROXY_ADDRESS,
  RESERVE_PROXY_ADDRESS as APP_RESERVE_PROXY_ADDRESS,
  STRYKE_DISTRIBUTOR_ADDRESS as APP_STRYKE_DISTRIBUTOR_ADDRESS,
  STRYKE_REWARD_TOKEN_ADDRESS as APP_STRYKE_REWARD_TOKEN_ADDRESS,
  STRYKE_POSITION_MANAGER_V2_ADDRESS as APP_STRYKE_POSITION_MANAGER_V2_ADDRESS,
} from './stages/app'
import {
  CONTRACT_ADDRESSES as DEV_CONTRACT_ADDRESSES,
  SPACESHIP_DISTRIBUTOR_ADDRESS as DEV_SPACESHIP_DISTRIBUTOR_ADDRESS,
  STRYKE_VAULT_INSPECTOR_ADDRESS as DEV_STRYKE_VAULT_INSPECTOR_ADDRESS,
  ASTRONAUT_NFT_ADDRESS as DEV_ASTRONAUT_NFT_ADDRESS,
  ASTRONAUT_PROXY_ADDRESS as DEV_ASTRONAUT_PROXY_ADDRESS,
  RESERVE_PROXY_ADDRESS as DEV_RESERVE_PROXY_ADDRESS,
  STRYKE_DISTRIBUTOR_ADDRESS as DEV_STRYKE_DISTRIBUTOR_ADDRESS,
  STRYKE_REWARD_TOKEN_ADDRESS as DEV_STRYKE_REWARD_TOKEN_ADDRESS,
  STRYKE_POSITION_MANAGER_V2_ADDRESS as DEV_STRYKE_POSITION_MANAGER_V2_ADDRESS,
} from './stages/dev'
import {
  CONTRACT_ADDRESSES as QA_CONTRACT_ADDRESSES,
  SPACESHIP_DISTRIBUTOR_ADDRESS as QA_SPACESHIP_DISTRIBUTOR_ADDRESS,
  STRYKE_VAULT_INSPECTOR_ADDRESS as QA_STRYKE_VAULT_INSPECTOR_ADDRESS,
  ASTRONAUT_NFT_ADDRESS as QA_ASTRONAUT_NFT_ADDRESS,
  ASTRONAUT_PROXY_ADDRESS as QA_ASTRONAUT_PROXY_ADDRESS,
  RESERVE_PROXY_ADDRESS as QA_RESERVE_PROXY_ADDRESS,
  STRYKE_DISTRIBUTOR_ADDRESS as QA_STRYKE_DISTRIBUTOR_ADDRESS,
  STRYKE_REWARD_TOKEN_ADDRESS as QA_STRYKE_REWARD_TOKEN_ADDRESS,
  STRYKE_POSITION_MANAGER_V2_ADDRESS as QA_STRYKE_POSITION_MANAGER_V2_ADDRESS,
} from './stages/qa'
import spaceshipDistributorList from './whitelist/spaceshipDistributor.json'

export const SUPPORTED_CHAINS =
  process.env.NODE_ENV === 'production' ? { arbitrum, berachain } : { arbitrum, berachain }
export const SUPPORTED_CHAIN_IDS = Object.values(SUPPORTED_CHAINS).map(ch => ch.id)
export const DEFAULT_CHAIN_ID = arbitrum.id
export const APP_PUBLIC_URL: string =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://alpha.orangefinance.io'
export const DEFAULT_SUBGRAPH_URL =
  'https://subgraph.satsuma-prod.com/1563a78cd0f9/pao-tech/orange-finance/api'

export const CONTRACT_ADDRESSES: VaultInfoList =
  process.env.NEXT_PUBLIC_STAGE === 'app'
    ? APP_CONTRACT_ADDRESSES
    : process.env.NEXT_PUBLIC_STAGE === 'qa'
    ? QA_CONTRACT_ADDRESSES
    : DEV_CONTRACT_ADDRESSES

const _productInfoList = productInfoList as Record<number, ProductListType>
export const {
  getSupportedContractAddresses,
  SUPPORTED_CONTRACT_ADDRESSES,
  getContractInfoListByChainId,
  getContractInfoByVaultAddress,
} = getHelpers(CONTRACT_ADDRESSES, SUPPORTED_CHAIN_IDS, _productInfoList)

export const USDCE_ADDRESSES: Record<number, AddressType> = {
  [arbitrum.id]: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  [polygon.id]: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
}

const SPACESHIP_DISTRIBUTOR_LIST = spaceshipDistributorList as SpaceshipDistributorList
export { SPACESHIP_DISTRIBUTOR_LIST }

export const SPACESHIP_DISTRIBUTOR_ADDRESS: AddressType | undefined =
  process.env.NEXT_PUBLIC_STAGE === 'app'
    ? APP_SPACESHIP_DISTRIBUTOR_ADDRESS
    : process.env.NEXT_PUBLIC_STAGE === 'qa'
    ? QA_SPACESHIP_DISTRIBUTOR_ADDRESS
    : DEV_SPACESHIP_DISTRIBUTOR_ADDRESS

export const STRYKE_VAULT_INSPECTOR_ADDRESS: AddressType =
  process.env.NEXT_PUBLIC_STAGE === 'app'
    ? APP_STRYKE_VAULT_INSPECTOR_ADDRESS
    : process.env.NEXT_PUBLIC_STAGE === 'qa'
    ? QA_STRYKE_VAULT_INSPECTOR_ADDRESS
    : DEV_STRYKE_VAULT_INSPECTOR_ADDRESS

export const RESERVE_PROXY_ADDRESS: AddressType =
  process.env.NEXT_PUBLIC_STAGE === 'app'
    ? APP_RESERVE_PROXY_ADDRESS
    : process.env.NEXT_PUBLIC_STAGE === 'qa'
    ? QA_RESERVE_PROXY_ADDRESS
    : DEV_RESERVE_PROXY_ADDRESS

export const MIGRATION_TO_ADDRESS: AddressType | undefined = CONTRACT_ADDRESSES[arbitrum.id]?.find(
  item => item.key === productKey.StrykeWethUsdcVault
)?.VAULT_ADDRESS

export const ASTRONAUT_NFT_ADDRESS: AddressType =
  process.env.NEXT_PUBLIC_STAGE === 'app'
    ? APP_ASTRONAUT_NFT_ADDRESS
    : process.env.NEXT_PUBLIC_STAGE === 'qa'
    ? QA_ASTRONAUT_NFT_ADDRESS
    : DEV_ASTRONAUT_NFT_ADDRESS

export const ASTRONAUT_PROXY_ADDRESS: AddressType =
  process.env.NEXT_PUBLIC_STAGE === 'app'
    ? APP_ASTRONAUT_PROXY_ADDRESS
    : process.env.NEXT_PUBLIC_STAGE === 'qa'
    ? QA_ASTRONAUT_PROXY_ADDRESS
    : DEV_ASTRONAUT_PROXY_ADDRESS

export const STRYKE_DISTRIBUTOR_ADDRESS: AddressType =
  process.env.NEXT_PUBLIC_STAGE === 'app'
    ? APP_STRYKE_DISTRIBUTOR_ADDRESS
    : process.env.NEXT_PUBLIC_STAGE === 'qa'
    ? QA_STRYKE_DISTRIBUTOR_ADDRESS
    : DEV_STRYKE_DISTRIBUTOR_ADDRESS

export const STRYKE_REWARD_TOKEN_ADDRESS: AddressType =
  process.env.NEXT_PUBLIC_STAGE === 'app'
    ? APP_STRYKE_REWARD_TOKEN_ADDRESS
    : process.env.NEXT_PUBLIC_STAGE === 'qa'
    ? QA_STRYKE_REWARD_TOKEN_ADDRESS
    : DEV_STRYKE_REWARD_TOKEN_ADDRESS

export const STRYKE_POSITION_MANAGER_V2_ADDRESS: AddressType =
  process.env.NEXT_PUBLIC_STAGE === 'app'
    ? APP_STRYKE_POSITION_MANAGER_V2_ADDRESS
    : process.env.NEXT_PUBLIC_STAGE === 'qa'
    ? QA_STRYKE_POSITION_MANAGER_V2_ADDRESS
    : DEV_STRYKE_POSITION_MANAGER_V2_ADDRESS
