import strykeImageUrl from '@apps-orangefi/assets/images/products/stryke/concept.svg'
import beraTokenImageUrl from '@apps-orangefi/assets/images/tokens/bera.svg'
import ethTokenImageUrl from '@apps-orangefi/assets/images/tokens/eth-outline.svg'
import honeyTokenImageUrl from '@apps-orangefi/assets/images/tokens/honey.svg'
import {
  ProductInfo,
  category,
  strategy,
  vaultVersion,
  amm,
  derivative,
  productType,
  ProductKey,
  productKey,
  merklRewardStatus,
  BeraProductKey,
} from '@apps-orangefi/lib/types'

export const productInfoList: { [key in BeraProductKey]: ProductInfo } = {
  //
  // Bera
  //
  [productKey.StrykeWethHoneyVault]: {
    productName: 'WETH-HONEY',
    version: vaultVersion.V1,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing WETH into the Orange Stryke WETH/HONEY vault provides liquidity to the Stryke WETH/HONEY pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: ethTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.KODIAK,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykeWbtcHoneyVault]: {
    productName: 'WBTC-HONEY',
    version: vaultVersion.V1,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing HONEY into the Orange Stryke WBTC/HONEY vault provides liquidity to the Stryke WBTC/HONEY pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: honeyTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.KODIAK,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykeBeraHoneyVault]: {
    productName: 'WBERA-HONEY',
    version: vaultVersion.V1,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing HONEY into the Orange Stryke WBERA/HONEY vault provides liquidity to the Stryke WBERA/HONEY pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: honeyTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.KODIAK,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
}
