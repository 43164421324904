import { BN } from '@apps-orangefi/lib'
import { Strike as StrykeStrike } from '@apps-orangefi/lib/subgraph/types/dopex/graphql'
import { DopexVault } from '@apps-orangefi/lib/subgraph/types/orange/graphql'
import {
  Token as UniV3Token,
  Pool as UniPool,
} from '@apps-orangefi/lib/subgraph/types/uniswap/graphql'
import { StaticImageData } from 'next/image'
import { Chain } from 'wagmi/chains'

import { ProductKey as BartioProductKey, productKey as bartioProdcutKey } from './bartio'
import { ProductKey as BeraProductKey, productKey as beraProdcutKey } from './bera'
import { ProductKey as ArbitrumProductKey, productKey as arbitrumProductKey } from './product'

export type { ArbitrumProductKey, BartioProductKey, BeraProductKey }
export type ProductKey = ArbitrumProductKey | BartioProductKey | BeraProductKey
export const productKey = { ...arbitrumProductKey, ...bartioProdcutKey, ...beraProdcutKey } as const
// export * from './product'

export const category = {
  Production: 'production',
  Lab: 'lab',
  Closed: 'closed',
} as const

export type Category = typeof category[keyof typeof category]

export const strategy = {
  NoSwap: 'noSwap',
  Swap: 'swap',
  PeggedPair: 'peggedPair',
  DeltaNeutral: 'deltaNeutral',
} as const

export type Strategy = typeof strategy[keyof typeof strategy]

export const vaultVersion = {
  Alpha: 'alpha',
  DN: 'dn',
  LPDfi: 'lpdfi', // LPDfi is legacy vaults & V1 vaults for deposit fee 0.1%
  V1: 'v1', // V1 is V1 vaults and depsoit fee 1%
  V2: 'v2',
} as const

export type VaultVersion = typeof vaultVersion[keyof typeof vaultVersion]

export const amm = {
  UNISWAP: 'uniswap',
  CAMELOT: 'camelot',
  PANCAKESWAP: 'pancakeswap',
  SUSHI: 'sushi',
  KODIAK: 'kodiak',
} as const

export type Amm = typeof amm[keyof typeof amm]

export const derivative = {
  STRYKE: 'stryke',
} as const

export type Derivative = typeof derivative[keyof typeof derivative]

export const productType = {
  OrangePoints: 'orangePoints',
  DualLiquidity: 'dualLiquidity',
  BlueChip: 'blueChip',
  Pegged: 'pegged',
} as const
export type ProductType = typeof productType[keyof typeof productType]

export const merklRewardStatus = {
  Active: 'active',
  Inactive: 'inactive',
  TBD: 'tbd',
} as const
export type MerklRewardStatus = typeof merklRewardStatus[keyof typeof merklRewardStatus]

export type RewardAPR = {
  iconUrl: any
  symbol: string
  tokenAddress: AddressType
  apr: BN
}

export type AlphaContractAddresses = {
  vaultAddress: AddressType
  peripheryAddress: AddressType
  parametersAddress: AddressType
}

export type V1ContractAddresses = {
  vaultAddress: AddressType
  parametersAddress: AddressType | undefined
}

export type ContractAddresses = AlphaContractAddresses | V1ContractAddresses

export type UserPosition = {
  myPosition: MyPosition
}

export type VaultCapacity = {
  totalDeposit: BN
  maxCapacity: BN
  tokenSymbol: string | undefined
  baseTokenPriceUSD?: BN
}

export type MyPosition = {
  lpBalance: BN
  position: BN
  // depositCapacity: BN
}

export type VaultPerformance = {
  chartData: {
    date: number
    gainValueSinceYieldSatrt: BN
  }[]
  apr: BN | null
  recentPerformance: BN | undefined
  isFetching: boolean
}

export type ProductInfo = {
  productName: string
  version: VaultVersion
  caption: string
  description: string
  overview: string
  tags: [string, string]
  imageUrls?: {
    concept: StaticImageData
    token: StaticImageData
  }
  category: Category
  strategy?: Strategy
  theme: {
    main: string
    tag: string
  }
  whitelist?: { address: AddressType }[]
  showAPR?: boolean
  merklRewardStatus?: MerklRewardStatus
  aprBaseDays?: number
  lottieAnimation?: unknown
  needMigration?: boolean
  platform?: {
    amm: Amm
    derivative?: Derivative
  }
  productType?: ProductType[]
  spaceshipBlackListed?: boolean
}

export type ProductContractInfo = {
  info: ProductInfo
  VAULT_ADDRESS: AddressType
  PERIPHERY_ADDRESS?: AddressType
  PARAMETERS_ADDRESS?: AddressType
  YIELD_START: number
}

export type StrategyVaultInfo = ProductContractInfo & {
  poolAddress: AddressType
  totalDeposit: BN | undefined
  maxCapacity: BN | undefined
  apr: BN | null
  feeApr?: BN
  rewardsApr?: BN
  performanceIsFetching?: boolean
  myPosition: BN | undefined
  myPositionUSD: BN | undefined // unused ??
  baseTokenPriceUSD: BN
  symbol: string | undefined
  baseToken: Token
  quoteToken: Token
  allocation: {
    amm: Amm | undefined
    derivative?: Derivative | undefined
  }
}

export interface AMMAnalyticsData {
  composition?: {
    vaultToken1: string
    vaultToken0: string
    poolToken1: string
    poolToken0: string
  }
  tick?: string
  isTokenPairReversed?: boolean
  pool?: {
    id: string
    tick: string
    sqrtPrice: string
    token0: {
      id: string
      symbol: string
      decimals: string
      derivedETH: string
    }
    token1: {
      id: string
      symbol: string
      decimals: string
      derivedETH: string
    }
  }
}

export type VaultInfo = {
  info: ProductInfo
  VAULT_ADDRESS: AddressType
  STRYKE_GAUGE_ID?: AddressType
}

export type VaultInfoList = Record<
  number,
  (Omit<VaultInfo, 'info'> & { key: ProductKey; info?: { productName: string } })[] | undefined
>
export type VaultInfoWithKey = VaultInfo & { key: ProductKey }

export const depositToken = {
  Usdce: 'USDC.e',
  Usdc: 'USDC',
  Weth: 'WETH',
  Boop: 'Boop',
  WstEth: 'wstETH',
  WeEth: 'weETH',
  EzEth: 'ezETH',
  Honey: 'HONEY',
  Wbera: 'WBERA',
} as const

export type DepositToken = typeof depositToken[keyof typeof depositToken]

export type AssetsToken = DepositToken | 'WBTC' | 'ARB' | 'BTC' | 'DAI' | 'USDT' | 'ETH'

export type ProductCardProps = {
  vaultAddress: AddressType
  strykeGaugeId?: AddressType
  category?: Category
  title: string
  caption: string
  description: string
  tags: [string, string]
  totalDeposit: BN
  tvl: BN
  maxCapacity: BN | null
  apr: BN | null
  feeApr?: BN
  rewardsApr?: BN
  strykeReward?: {
    totalApr: BN
    rewardList: RewardAPR[]
  }
  reservedLpAmountUSD?: BN
  performanceIsFetching?: boolean
  myPosition: BN | undefined
  myPositionUSD?: BN | undefined
  symbol: string | undefined
  baseToken: Token
  quoteToken: Token
  merklRewardStatus: MerklRewardStatus
  imageUrls?: {
    concept: StaticImageData
    token: StaticImageData
  }
  theme: {
    main: string
    tag: string
  }
  platform?: {
    amm: Amm
    derivative?: Derivative
  }
  productType?: ProductType[]
  className?: string
}

export type TokenValueWithDate = {
  date: number
  timestamp: number
  gainValueSinceYieldSatrt: BN
  tokenValue: BN
}

export type MerkleRewardsApr = {
  [key: AddressType]: BN | undefined
}

export type ContractProp = {
  symbol: string
  address: AddressType
  url: string
}

export type WithdrawnLP = {
  strikePrice: BN
  token0?: {
    size: BN
    amount: BN
    symbol: string
  }
  token1?: {
    size: BN
    amount: BN
    symbol: string
  }
}

export type ResultWithdrawSimulation = {
  withdrawnAssets: BN
  withdrawnLP: WithdrawnLP[]
  totalUtilizedLP: {
    token0?: {
      size: BN
      amount: BN
      symbol: string
    }
    token1?: {
      size: BN
      amount: BN
      symbol: string
    }
  }
  totalValue: BN | undefined
}

// TODO: convert to
export type Token = Pick<UniV3Token, 'id' | 'name' | 'symbol' | 'decimals' | 'derivedETH'> & {
  decimals: number
}
export type Pool = Pick<UniPool, 'id' | 'tick' | 'sqrtPrice' | 'token0Price' | 'token1Price'> & {
  token0: Token
  token1: Token
}
export type Strike = Pick<
  StrykeStrike,
  | 'id'
  | 'handler'
  | 'pool'
  | 'hook'
  | 'reservedLiquidity'
  | 'usedLiquidity'
  | 'totalLiquidity'
  | 'tickLower'
  | 'tickUpper'
> & {
  token0: Token
  token1: Token
}

export type Vault = Pick<DopexVault, 'id' | 'totalAssets' | 'decimals' | 'isTokenPairReversed'> & {
  pool: Pool
  baseToken: Token
  quoteToken: Token
}

export type ERC20Token = {
  address: AddressType
  symbol: string
  decimals: number
}
export type PoolToken = ERC20Token & { name: string; derivedETH: BN }

export type SimulateWithdrawProps = {
  simulateWithdrawLPDfi: () => void
  resultWithdrawSimulation?: ResultWithdrawSimulation | undefined
  hasUtilizedLP: boolean
  isSimulating: boolean
  isLpPositionFetching?: boolean
  resetResultWithdrawSimulation: () => void
}

export type LeaderboardRow = {
  rank: number
  walletAddress: AddressType
  poolShare: number
  score: number
  isMyRank: boolean
}

export type MyRank = {
  rank: number
  walletAddress: string
  isRewardDistributed: boolean
  isAchievedMilestone1: boolean
  isAchievedMilestone2: boolean
  totalReward: BN
}

export type ClaimStatus = {
  isClaimed: boolean
  isExpired: boolean
  endTime: number | null
}

export const stepState = {
  Pending: 'pending',
  Active: 'active',
  Completed: 'completed',
} as const

export type StepState = typeof stepState[keyof typeof stepState]

export type UserClaimEligiblity = {
  remainingDays: number
  latestCommulativeEthAmount: BN
  latestCommulativeUsdAmount: BN
}

export type WithdrawableReserveLiquidity = {
  id: string
  strikePrice: BN
  token0?: {
    size: BN
    withdrawable: BN
    symbol: string
  }
  token1?: {
    size: BN
    withdrawable: BN
    symbol: string
  }
}
export type TotalWithdrawableReserveLiquidity = {
  token0?: {
    size: BN
    withdrawable: BN
    symbol: string
  }
  token1?: {
    size: BN
    withdrawable: BN
    symbol: string
  }
}

export type VaultReward = {
  vaultAddress: AddressType
  pairName: string
  baseTokenSymbol: string
  quoteTokenSymbol: string
  amm: Amm | undefined
  derivative: Derivative | undefined
  rewards: {
    amount: BN
    amountUsd: BN | null
    symbol: string
  }[]
}

export type ReservedPosition = {
  tokenId: string
  pool: AddressType
  hook: AddressType
  tickLower: number
  tickUpper: number
  liquidity: bigint
}

export type StrykeLP = {
  title: string
  baseTokenSymbol: string
  quoteTokenSymbol: string
  amm: Amm
  derivative: Derivative
  chain: Chain
  positionManagerAddress: AddressType
  handlerAddress: AddressType | undefined
  poolAddress: AddressType
  lps: {
    tokenId: string
    share: bigint
  }[]
  totalAmountUSD: BN
  tokenTotalLiquidity:
    | {
        token0: {
          size: BN
          amount: BN
          symbol: string
          decimals: number
        }
        token1: {
          size: BN
          amount: BN
          symbol: string
          decimals: number
        }
      }
    | undefined
}

export type PoolState = {
  poolAddress: AddressType
  sqrtPriceX96: bigint
  tick: number
  tickSpacing: number
  fee: number
  token0: ERC20Token
  token1: ERC20Token
}

export type BNAmount = {
  value: BN
  decimals: number
}
