import { BN } from '@apps-orangefi/lib'
import {
  ConvertToAssetsParams,
  GetTokenPairAmountsParams,
} from '@apps-orangefi/lib/types/stryke-lp'
import { StrykeUniV3HandlerV2ABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { encodeAbiParameters, parseAbiParameters, zeroAddress } from 'viem'

// Convert LP shares to assets by using stryke handler contract
export const useConvertLpSharesToAssets = (params: ConvertToAssetsParams[]) => {
  const contracts = params.map(param => ({
    address: param.handler as AddressType,
    abi: StrykeUniV3HandlerV2ABI,
    functionName: 'convertToAssets',
    args: [param.share.convertBigint(), new BN(param.tokenId).convertBigint()],
  }))

  const { data: assetList, isFetching } = useReadContractsWithErrorHandling({
    contracts,
    query: {
      enabled: params.length > 0 && params.every(param => param.handler !== zeroAddress),
    },
  })

  return {
    assetList: (assetList ?? []).map(item => item.result as bigint),
    isFetching,
  }
}

// Fetch token pair amounts from stryke handler contract
export const useGetTokenPairAmounts = (params: GetTokenPairAmountsParams[]) => {
  const contracts = params.map(param => {
    const args = encodeAbiParameters(
      parseAbiParameters('address, address, int24, int24, uint128'),
      [
        param.pool as AddressType,
        zeroAddress,
        param.tickLower,
        param.tickUpper,
        param.asset.convertBigint(),
      ]
    )

    return {
      address: param.handler as AddressType,
      abi: StrykeUniV3HandlerV2ABI,
      functionName: 'tokensToPullForMint',
      args: [args as AddressType],
    }
  })

  const {
    data: lpAmountData,
    isFetching,
    isError,
  } = useReadContractsWithErrorHandling({
    contracts,
    query: {
      enabled: params.length > 0,
    },
  })

  return {
    lpAmountData: (lpAmountData ?? []).map(
      item => item.result as unknown as [[AddressType, AddressType], [bigint, bigint]]
    ),
    isFetching,
    isError,
  }
}
