import Logo from '@apps-orangefi/assets/images/logo/logo-white.svg'
import ImgBybit from '@apps-orangefi/assets/images/wallets/bybit-wallet.svg'
import ImgCoinbase from '@apps-orangefi/assets/images/wallets/coinbase-wallet.png'
import ImgMetamask from '@apps-orangefi/assets/images/wallets/metamask-fox.svg'
import ImgRabby from '@apps-orangefi/assets/images/wallets/rabby.svg'
import ImgWalletconnext from '@apps-orangefi/assets/images/wallets/walletconnect-circle-white.svg'
import { LineLoader } from '@apps-orangefi/ui/atoms'
import { BaseModal } from '@apps-orangefi/ui/organisms/modals'
import { Dialog } from '@headlessui/react'
import { cloneDeep, remove, indexOf, chain } from 'lodash'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'
import { useConnect, Connector } from 'wagmi'

export type ConnectModalProps = {
  handleClose: () => void
  handleConnect: (conn: Connector) => void
}

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const onClickUrl = (url: string) => openInNewTab(url)

type ConnectWalletButtonProps = {
  conn: Connector
  isPending: boolean
  handleConnect: (conn: Connector) => void
  walletIcon: JSX.Element
}

const ConnectWalletButton = ({
  conn,
  isPending,
  // pendingConnector,
  handleConnect,
  walletIcon,
}: ConnectWalletButtonProps) => {
  const [isShowNotAvailable, setIsShowNotAvailable] = useState(false)

  return (
    <button
      onClick={() => {
        handleConnect(conn)
      }}
      className="flex-row justify-center w-full rounded-2xl py-2 dark:bg-gray-850 bg-cards-foreground dark:text-white hover:brightness-95 disabled:cursor-not-allowed disabled:opacity-70"
    >
      <div className="flex flex-row justify-start items-center m-auto w-56">
        <div className="mr-2 shrink-0 relative">
          <div className="w-12 h-12 flex flex-row justify-center items-center">{walletIcon}</div>
        </div>
        {conn.name}
        {/* {isPending && pendingConnector?.id === conn.id && ( */}
        {isPending && <LineLoader type="line" className="ml-4" />}
      </div>
      {isShowNotAvailable && (
        <div className="-mt-2 type-xs-medium text-rose-300">Wallet is not available</div>
      )}
    </button>
  )
}

const walletOrders = [
  'MetaMask',
  'Rabby Wallet',
  'Bybit Wallet',
  'Coinbase Wallet',
  'WalletConnect',
]

export const ConnectModal = ({ handleClose, handleConnect }: ConnectModalProps) => {
  const { t } = useTranslation()
  const { connectors, isPending } = useConnect()

  const WalletIcon: any = {
    metaMask: <Image src={ImgMetamask} height={30} width={30} alt="Metamask" />,
    rabby: <Image height={40} width={40} src={ImgRabby} alt="Rabby" />,
    bybitWallet: <Image src={ImgBybit} height={34} width={34} alt="Bybit" />,
    coinbaseWalletSDK: <Image src={ImgCoinbase} height={36} width={36} alt="CoinbaseWallet" />,
    walletConnect: <Image src={ImgWalletconnext} height={32} width={32} alt="WalletConnect" />,
  }

  const supportedInjectedConnectors: { [key: string]: { name: string; icon: any; url: string } } = {
    metaMask: {
      name: 'MetaMask',
      icon: <Image src={ImgMetamask} height={30} width={30} alt="Metamask" />,
      url: 'https://metamask.io/',
    },
    rabby: {
      name: 'Rabby Wallet',
      icon: <Image height={40} width={40} src={ImgRabby} alt="Rabby" />,
      url: 'https://rabby.io',
    },
    bybitWallet: {
      name: 'Bybit Wallet',
      icon: <Image src={ImgBybit} height={34} width={34} alt="Bybit" />,
      url: 'https://chromewebstore.google.com/detail/bybit-wallet/pdliaogehgdbhbnmkklieghmmjkpigpa',
    },
  }

  const _connectors = chain(connectors)
    .sort((c1, c2) => {
      const c1Index = indexOf(walletOrders, c1.name)
      const c2Index = indexOf(walletOrders, c2.name)
      return c1Index < c2Index ? -1 : 1
    })
    .uniqBy(conn => conn.name)
    .value()

  return (
    <BaseModal onClose={() => handleClose()}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl dark:bg-gray-900 bg-cards pt-6 px-6 pb-8 text-left align-middle shadow-xl transition-all">
        <Dialog.Title as="h3" className="text-center mb-6">
          <div className="flex flex-row">
            <Image src={Logo} alt="Orange Finance" width={108} height={24} className="self-end" />
            <span className="ml-2 type-xl-semibold dark:text-gray-400">{t('WALLET.LOGIN')}</span>
          </div>
        </Dialog.Title>
        <div className="flex flex-col gap-3">
          {_connectors.map((conn, index) => (
            <ConnectWalletButton
              key={index}
              conn={conn}
              isPending={isPending}
              handleConnect={handleConnect}
              walletIcon={
                WalletIcon[conn.id] ?? (
                  <Image src={conn.icon!} alt={conn.name} width="32" height="32" />
                )
              }
            />
          ))}
        </div>
      </Dialog.Panel>
    </BaseModal>
  )
}
