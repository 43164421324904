import { BaseModal } from '@apps-orangefi/ui/organisms/modals'
import { Dialog } from '@headlessui/react'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export type InvalidAddressModalProps = {
  handleClose: () => void
}

export const InvalidAddressModal = ({ handleClose }: InvalidAddressModalProps) => {
  const { t } = useTranslation()

  return (
    <BaseModal onClose={() => handleClose()}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white px-10 py-14 text-left align-middle shadow-xl transition-all">
        <Dialog.Title as="h3" className="text-style-body1-sb text-center">
          {t('MODAL.INVALID_ADDRESS.TITLE')}
        </Dialog.Title>
        <div className="mt-10 text-style-info whitespace-pre-wrap">
          <div>
            <Trans
              i18nKey="MODAL.INVALID_ADDRESS.DESC"
              components={{
                link: (
                  <a
                    href="https://discord.gg/NFuGtecCAS"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="font-bold"
                  />
                ),
              }}
            />
          </div>
        </div>
      </Dialog.Panel>
    </BaseModal>
  )
}
