import { useApproveLPForm, useReserveStrykeLp } from '@apps-orangefi/hooks'
import { LineLoader } from '@apps-orangefi/ui/atoms'
import { MODAL_TYPES, TxModal } from '@apps-orangefi/ui/organisms/modals'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

export type ReserveAllLPModalContainerProps = {
  title: string
  positionManagerAddress: AddressType | undefined
  handlerAddress: AddressType | undefined
  lps: {
    tokenId: string
    share: bigint
  }[]
  handleClose: () => void
}

export const ReserveAllLPModalContainer = ({
  title,
  positionManagerAddress,
  handlerAddress,
  lps,
  handleClose,
}: ReserveAllLPModalContainerProps) => {
  const { t } = useTranslation()
  const { address: account, chain } = useAccount()
  const [isProcessStarted, setIsProcessStarted] = useState(false)

  const {
    onReserve,
    isReserveLPWaiting,
    isReservedLiquidity,
    isReserveLPSubmitted,
    isReserveReady,
  } = useReserveStrykeLp({
    positionManagerAddress,
    handlerAddress,
    chain,
    lps,
  })

  useEffect(() => {
    if (isProcessStarted) return
    if (!isReserveLPSubmitted && !isReservedLiquidity && isReserveReady) {
      onReserve()
      setIsProcessStarted(true)
    }

    return () => {
      setIsProcessStarted(false)
    }
  }, [isReserveLPSubmitted, isReservedLiquidity, isReserveReady])

  return (
    <>
      <TxModal
        title={title}
        chain={chain}
        handleClose={handleClose}
        isDefaultClosable
        messages={[]}
      >
        <div className="flex justify-center w-full">{!isReserveReady && <LineLoader />}</div>
      </TxModal>
    </>
  )
}
