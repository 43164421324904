import { BN } from '@apps-orangefi/lib'
import { TokenList } from '@apps-orangefi/ui/atoms'
import { twMerge } from 'tailwind-merge'

type Props = {
  rewardAPR: BN
  rewardIcons: string[]
  className?: string
}

export const StrykeAPR = ({ rewardAPR, rewardIcons, className = '' }: Props) => {
  return (
    <div className={twMerge('flex flex-row dark:bg-gray-750 rounded p-1', className)}>
      <TokenList iconUrls={rewardIcons} size={16} />
      <span className="type-sm-semibold dark:text-white font-mono ml-3">
        {rewardAPR.toFixed(2)}%
      </span>
    </div>
  )
}
