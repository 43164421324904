import Image from 'next/image'
import { StaticImageData } from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  token0IconUrl: string | StaticImageData | undefined
  token1IconUrl: string | StaticImageData | undefined
  size?: number
  className?: string
}

export const TokenPair = ({ token0IconUrl, token1IconUrl, size = 40, className = '' }: Props) => {
  const shift = size * 0.75

  return (
    <div className={twMerge('flex flex-row justify-start items-center relative', className)}>
      {token0IconUrl && token1IconUrl && (
        <>
          <Image
            src={token0IconUrl}
            alt="token0"
            width={size}
            height={size}
            className="rounded-full max-w-none"
          />
          <Image
            src={token1IconUrl}
            alt="token1"
            width={size}
            height={size}
            className="absolute z-10 border-2 border-gray-850 border-background rounded-full box-content max-w-none"
            style={{ left: shift }}
          />
        </>
      )}
    </div>
  )
}

type TokenListProps = {
  iconUrls: string[]
  size?: number
  className?: string
}
export const TokenList = ({ iconUrls, size = 40, className = '' }: TokenListProps) => {
  return (
    <div className={twMerge('flex flex-row justify-start items-center relative', className)}>
      {iconUrls.map((iconUrl, index) => {
        const [shift, iconClass] =
          index === 0
            ? [0, '']
            : [
                size * 0.75,
                `z-[${10 + index}] absolute border-2 border-gray-850 border-background `,
              ]

        return (
          <Image
            src={iconUrl}
            alt="token1"
            width={size}
            height={size}
            className={twMerge('rounded-full box-content max-w-none', iconClass)}
            style={{ left: shift }}
            key={index}
          />
        )
      })}
    </div>
  )
}
