import { arbitrum, berachainTestnetbArtio, berachain } from 'viem/chains'

import { ProductListType } from '../helpers'

import { productInfoList as arbitrumProductList } from './arbitrum'
import { productInfoList as beraProductList } from './bera'
import { productInfoList as beraBartioProductList } from './bera-bartio'

export type ProductInfoListType =
  | typeof arbitrumProductList
  | typeof beraBartioProductList
  | typeof beraProductList

export const productInfoList: { [chainId: number]: ProductInfoListType } = {
  [arbitrum.id]: arbitrumProductList,
  [berachainTestnetbArtio.id]: beraBartioProductList,
  [berachain.id]: beraProductList,
}
