import { useTx, useModal, useGetMerklProof } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import {
  Tx,
  txListAtom,
  txStatus,
  vaultDecimalsAtom,
  tokenAtom,
  isAllowedCloseModalAtom,
  needReloadAtom,
} from '@apps-orangefi/lib/store'
import { vaultVersion, VaultVersion } from '@apps-orangefi/lib/types'
import { MODAL_TYPES, TxModalTypes } from '@apps-orangefi/ui/organisms/modals'
import {
  useAllowance,
  useApprove,
  useConvertToShares,
  ResultUseDeposit,
} from '@apps-orangefi/wagmi/hooks'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { chain as _chain, every, some } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAccount, BaseError } from 'wagmi'

import type { DistributorList } from '@apps-orangefi/lib/utils'

type DepostHook = (
  contractAddress: AddressType | undefined,
  account: AddressType | undefined,
  assets: BN,
  shares: BN | undefined,
  merkleProof: AddressType[],
  allowance: BN,
  vaultDecimals: number | null,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => ResultUseDeposit

const txApproveDefault: Tx = {
  title: 'Approve',
  hash: undefined,
  status: txStatus.Wait,
}

const txDepositDefault: Tx = {
  title: 'Deposit',
  hash: undefined,
  status: txStatus.Wait,
}

const txApproveAtom = atom<Tx>(txApproveDefault)
const txDepositAtom = atom<Tx>(txDepositDefault)

export const useDepositForm = (
  tokenAddress: AddressType | undefined,
  vaultAddress: AddressType | undefined,
  toDepositAddress: AddressType | undefined,
  account: AddressType | undefined,
  tokenBalance: BN | undefined,
  whiteList: DistributorList,
  useDeposit: DepostHook,
  modalType: TxModalTypes = MODAL_TYPES.TxModal,
  version?: VaultVersion
) => {
  const { t } = useTranslation()
  const [txList, setTxList] = useAtom(txListAtom)
  const [isAllowedCloseModal, setIsAllowedCloseModal] = useAtom(isAllowedCloseModalAtom)
  const setNeedReload = useSetAtom(needReloadAtom)

  const vaultDecimals = useAtomValue(vaultDecimalsAtom)
  const token = useAtomValue(tokenAtom)
  const { chain } = useAccount()
  const [amount, setAmount] = useState(new BN(0))

  const {
    tx: txApprove,
    setTx: setTxApprove,
    moveToPending: txApprovePending,
    moveToError: txApproveError,
    moveToSuccess: txApproveSuccess,
  } = useTx(txApproveAtom)

  const {
    tx: txDeposit,
    setTx: setTxDeposit,
    moveToPending: txDepositPending,
    moveToError: txDepositError,
    moveToSuccess: txDepositSuccess,
  } = useTx(txDepositAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxApprove(txApproveDefault)
    setTxDeposit(txDepositDefault)
  }

  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txApproveAtom, txDepositAtom])
    }
  }

  const allowance = useAllowance(tokenAddress, account, toDepositAddress, token?.decimals)
  const approve = useApprove(tokenAddress, account, toDepositAddress, amount, token?.decimals, {
    success: allowance.refetch,
    fail: txApproveError,
  })

  const { shares } = useConvertToShares(vaultAddress, amount, vaultDecimals)
  const { merkleProof, isAllowedWallet } = useGetMerklProof(whiteList, account)

  const deposit = useDeposit(
    toDepositAddress,
    account,
    amount,
    shares,
    merkleProof,
    allowance.data,
    vaultDecimals,
    {
      success: txDepositSuccess,
      fail: txDepositError,
    }
  )

  useEffect(() => {
    if (!approve.hash || !!txApprove.hash) return
    setTxApprove(prev => {
      return { ...prev, hash: approve.hash }
    })
  }, [approve.hash])

  useEffect(() => {
    if (!deposit.hash || !!txDeposit.hash) return
    setTxDeposit(prev => {
      return { ...prev, hash: deposit.hash }
    })
  }, [deposit.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (txApprove.status === txStatus.Wait) {
      if (amount.lte(0)) return
      if (approve.waitLoading) return
      if (allowance.data.lt(amount) && !!approve.writeContract && !!approve.data?.request) {
        txApprovePending()
        approve.onApprove()
      }
    }

    if (txApprove.status === txStatus.Wait || txApprove.status === txStatus.Pending) {
      if (amount.gt(0) && allowance.data.gte(amount)) {
        txApproveSuccess()
      }
    }
  }, [
    txList,
    txApprove,
    amount.toFixed(),
    allowance.data.toFixed(),
    approve.isSuccess,
    approve.writeContract,
    approve.data?.request,
  ])

  useEffect(() => {
    if (!approve.isSuccess) return
    if (allowance.isRefetching || !allowance.isLoaded) return
    if (txApprove.status !== txStatus.Pending) return
    if (allowance.data.lt(amount)) {
      txApproveError(t('WIDGET.ERROR.ALLOWANCE_NOT_ENOUGH'))
    }
  }, [JSON.stringify(allowance.data), allowance.isLoaded])

  useEffect(() => {
    if (txList.length === 0) return
    if (!deposit.isWriteReady) return
    if (txApprove.status === txStatus.Success && txDeposit.status === txStatus.Wait) {
      txDepositPending()
      deposit.onDeposit()
    }
  }, [txList, txApprove, txDeposit, deposit.isWriteReady, deposit.onDeposit, amount.toFixed()])

  const { showModal, hideModal } = useModal()

  const onSetMaxBalance = useCallback(() => {
    if (!!tokenBalance) {
      setAmount(tokenBalance)
    }
  }, [tokenBalance?.toFixed()])

  useEffect(() => {
    const isTransactionEnd =
      every([txApprove, txDeposit], ['status', txStatus.Success]) ||
      some([txApprove, txDeposit], ['status', txStatus.Error])
    if (isTransactionEnd !== isAllowedCloseModal) {
      setIsAllowedCloseModal(isTransactionEnd)
    }
  }, [txApprove, txDeposit, setIsAllowedCloseModal])

  const onDeposit = useCallback(
    (data: { amount: string }) => {
      if (!isAllowedWallet) return
      const messages =
        version === vaultVersion.LPDfi || vaultVersion.V1
          ? []
          : [t('MODAL.TX.DEPOSIT_CAUTION_MESSAGE')]
      resetTx()
      initTxList()
      setIsAllowedCloseModal(false)
      showModal({
        modalType,
        modalProps: {
          title: 'Deposit transaction',
          chain,
          handleClose: () => {
            setNeedReload(true)
            hideModal()
          },
          // FIXME: view resposibility
          messages,
        },
      })
    },
    [isAllowedWallet, version]
  )

  return {
    amount,
    setAmount,
    allowance: allowance.data,
    initTxList,
    onDeposit,
    onSetMaxBalance,
    isAllowedWallet,
  }
}
