import { BN } from '@apps-orangefi/lib'
import { StrykeLPAutomatorV2ABI } from '@apps-orangefi/wagmi/abis'
import { useSimulateContractWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { isEmpty } from 'lodash'
import { useMemo, useState, useEffect, useCallback } from 'react'

// NOTE: similar to WithdrawLP
type DopexLpShare = {
  tokenId: string
  share: BN
}

type ResultRedeemSimulation = {
  withdrawnAssets: BN | undefined
  dopexLpShares: DopexLpShare[]
}

export const useSimulateRedeem = (
  vaultAddress: AddressType | undefined,
  shares: BN | undefined,
  vaultDecimals: number | null
) => {
  const [resultSimulation, setResultSimulation] = useState<ResultRedeemSimulation>({
    withdrawnAssets: undefined,
    dopexLpShares: [],
  })
  const [hasUtilizedLP, setHasUtilizedLP] = useState(false)
  const [isSimulating, setIsSimulating] = useState(false)

  const args = useMemo(() => {
    if (!shares) return [BigInt(0), BigInt(0)] as const
    const _shares = shares
      .pow10(vaultDecimals ?? 0)
      .convertBigint()
      .valueOf()
    const minAssets = new BN(0).convertBigint().valueOf()
    return [_shares, minAssets!] as const
  }, [shares, vaultDecimals])

  const [enabled, setEnabled] = useState(false)

  const { data } = useSimulateContractWithErrorHandling({
    address: vaultAddress!,
    abi: StrykeLPAutomatorV2ABI,
    functionName: 'redeem',
    args,
    query: {
      enabled,
    },
  })

  const simulateWithdrawLPDfi = useCallback(() => {
    if (!isSimulating) {
      setIsSimulating(true)
      setEnabled(true)
    }
  }, [enabled])

  useEffect(() => {
    if (!vaultAddress || !vaultDecimals) {
      return
    }

    if (data && !isEmpty(data.result)) {
      const typedResult = data.result as [bigint, { tokenId: bigint; shares: bigint }[]]
      const withdrawnAssets = typedResult[0]
        ? new BN(typedResult[0].toString()).pow10ofMinus(vaultDecimals)
        : new BN(0)
      const dopexLpShares = typedResult[1].map(share => {
        return {
          tokenId: share.tokenId.toString(),
          share: new BN(share.shares.toString()),
        }
      })
      const totalShare = dopexLpShares.reduce((acc, cur) => {
        acc = acc.plus(cur.share)
        return acc
      }, new BN(0))

      setResultSimulation({
        withdrawnAssets,
        dopexLpShares,
      })
      setHasUtilizedLP(totalShare.gt(0))
      setIsSimulating(false)
    }
  }, [data])

  return {
    resultSimulation,
    simulateWithdrawLPDfi,
    hasUtilizedLP,
    isSimulating,
  }
}
