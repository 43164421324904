import OrangeLogo from '@apps-orangefi/assets/images/logo/logo-symbol.svg'
import Logo from '@apps-orangefi/assets/images/logo/logo-white.svg'
import IconLPDfi from '@apps-orangefi/assets/images/menus/lpdfi.svg'
import IconAstronaut from '@apps-orangefi/assets/images/menus/trophy.svg'
// import OrangeLogo from '@apps-orangefi/assets/images/orange-logo.svg'
import { usePathByChain } from '@apps-orangefi/hooks'
import { shortAddress } from '@apps-orangefi/lib/utils'
import { MenuButton } from '@apps-orangefi/ui/atoms'
import { ChainSelector } from '@apps-orangefi/ui/molecules'
import { Library } from 'lucide-react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { Chain } from 'wagmi/chains'

const ConnectWalletButton = dynamic(
  () => import('@apps-orangefi/ui/molecules/buttons/WalletButton'),
  { ssr: false }
)

type Props = {
  networkStatus: {
    isConnected: boolean
    isActiveChainSupported: boolean
    isActiveChainIsSelected: boolean
  }
  account: AddressType | undefined
  openConnectWalletModal: () => void
  openAccountModal: () => void
  openSwitchChainModal: () => void
  chainSelectorProps: {
    selectedChain: Chain | undefined
    supportedChains: { [key: string]: Chain }
    onSelectChain: (chain: Chain) => void
  }
  currentPath: string
  pointsUrlBase: string
}

export const AppHeader = ({
  networkStatus,
  account,
  openConnectWalletModal,
  openAccountModal,
  openSwitchChainModal,
  chainSelectorProps,
  currentPath,
  pointsUrlBase,
}: Props) => {
  const { t } = useTranslation()
  const shortenAddress = shortAddress(account)
  const path = usePathByChain()

  return (
    <div className="bg-main max-h-20 md:max-h-24 py-4 md:py-6 fixed top-0 right-0 left-0 z-30">
      <div className="flex flex-row justify-between items-center container px-5 md:px-8">
        <div className="flex flex-row text-style-sub items-center justify-start">
          <Link href={`/${path}`}>
            <Image
              src={Logo}
              alt="Orange Finance"
              width={184}
              height={40}
              className="hidden md:block"
            />
            <Image
              src={OrangeLogo}
              alt="Orange Finance"
              width={40}
              height={38.5}
              className="md:hidden"
            />
          </Link>
          <div className="flex-row hidden md:flex ml-8">
            <MenuButton
              label={t('MENU.LIQUIDITY_VAULTS')}
              logoUrl={IconLPDfi}
              href="/"
              isActive={currentPath === '/arbitrum' || currentPath === '/berachain'}
              className="ml-2"
            />
            <MenuButton
              label={t('MENU.POINTS')}
              logoUrl={OrangeLogo}
              href={pointsUrlBase}
              isTargetBlank={false}
              className="ml-2"
            />
            <MenuButton
              label={t('MENU.LEGACY')}
              iconElement={<Library className="w-5 h-5" />}
              href="https://legacy.orangefinance.io"
              isTargetBlank={false}
              className="ml-2"
            />
            {/*
            <MenuButton
              label={t('MENU.ASTRONAUT')}
              logoUrl={IconAstronaut}
              href="/astronaut"
              isActive={currentPath === '/astronaut'}
              className="ml-2"
            /> */}
          </div>
        </div>
        <div className="flex flex-row items-center">
          {networkStatus.isConnected && chainSelectorProps.selectedChain && (
            <ChainSelector {...chainSelectorProps} className="mr-3" />
          )}
          <ConnectWalletButton
            networkStatus={networkStatus}
            shortenAddress={shortenAddress}
            openConnectWalletModal={openConnectWalletModal}
            openAccountModal={openAccountModal}
            openSwitchChainModal={openSwitchChainModal}
            // className="dark:bg-orange-500 dark:text-white font-bold font-urbanist"
          />
        </div>
      </div>
    </div>
  )
}
