import { BN } from '@apps-orangefi/lib'
import { Token, PoolToken } from '@apps-orangefi/lib/types'
import { DopexLpAsset } from '@apps-orangefi/lib/types/stryke-lp'
import { convertTotalAmountEthAndUsd } from '@apps-orangefi/lib/utils'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { Price, Token as UniToken } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import { chain, zipWith, zipObject } from 'lodash'

type ResultCalculateWithdrawnLP = (
  | ReturnType<typeof calculateInRangeLP>
  | ReturnType<typeof calculateBelowRangeLP>
  | ReturnType<typeof calculateAboveRangeLP>
)[]

type CalculationTickProperty = {
  token0: Pick<PoolToken, 'symbol' | 'decimals'>
  token1: Pick<PoolToken, 'symbol' | 'decimals'>
  priceLower: Price<UniToken, UniToken>
  priceUpper: Price<UniToken, UniToken>
}

export const calculateTokenPairAmounts = <
  T extends { token0: { address: AddressType }; token1: { address: AddressType } }
>(
  lpAmountData: [[AddressType, AddressType], [bigint, bigint]][] | undefined,
  utilizeLpList: T[]
): (T & { token0Amount: BN; token1Amount: BN })[] => {
  const tokenPairAmountList = chain(lpAmountData ?? [])
    .compact()
    .value()

  const amountPerToken = chain(tokenPairAmountList)
    .map(tokenPairAmount =>
      zipObject(
        tokenPairAmount[0].map((token: AddressType) => token.toLowerCase()),
        tokenPairAmount[1].map((amount: bigint) => bigintToBN(amount))
      )
    )
    .value()

  return zipWith(utilizeLpList, amountPerToken, (utilizedLp, tokenPair) => ({
    ...utilizedLp,
    token0Amount: tokenPair?.[utilizedLp.token0.address] ?? new BN(0),
    token1Amount: tokenPair?.[utilizedLp.token1.address] ?? new BN(0),
  }))
}

export const calculateWithdrawnLP = (
  strikeTokenAmountList: (CalculationTickProperty & { token0Amount: BN; token1Amount: BN })[],
  currentPrice: Price<UniToken, UniToken>
) => {
  return strikeTokenAmountList.map(strike => {
    const [priceLower, priceUpper] = [strike.priceLower, strike.priceUpper]

    if (currentPrice.greaterThan(priceLower) && currentPrice.lessThan(priceUpper)) {
      return calculateInRangeLP(strike, currentPrice, priceLower, priceUpper)
    } else if (currentPrice.lessThan(priceLower)) {
      return calculateBelowRangeLP(strike, priceUpper)
    } else {
      return calculateAboveRangeLP(strike, priceLower)
    }
  })
}

const createTokenInfo = (amount: BN, decimals: number, symbol: string) => ({
  size: amount.pow10ofMinus(decimals),
  amount,
  symbol,
})

const calculateInRangeLP = (
  strike: CalculationTickProperty & { token0Amount: BN; token1Amount: BN },
  currentPrice: Price<UniToken, UniToken>,
  priceLower: Price<UniToken, UniToken>,
  priceUpper: Price<UniToken, UniToken>
) => {
  const deltaLower = currentPrice.subtract(priceLower)
  const deltaUpper = priceUpper.subtract(currentPrice)
  const strikePrice = deltaLower.lessThan(deltaUpper) ? priceLower : priceUpper

  return {
    strikePrice: new BN(strikePrice.toSignificant()),
    token0: createTokenInfo(strike.token0Amount, strike.token0.decimals, strike.token0.symbol),
    token1: createTokenInfo(strike.token1Amount, strike.token1.decimals, strike.token1.symbol),
  }
}

const calculateBelowRangeLP = (
  strike: CalculationTickProperty & { token0Amount: BN; token1Amount: BN },
  priceUpper: Price<UniToken, UniToken>
) => ({
  strikePrice: new BN(priceUpper.toSignificant()),
  token0: createTokenInfo(strike.token0Amount, strike.token0.decimals, strike.token0.symbol),
  token1: undefined,
})

const calculateAboveRangeLP = (
  strike: CalculationTickProperty & { token0Amount: BN; token1Amount: BN },
  priceLower: Price<UniToken, UniToken>
) => ({
  strikePrice: new BN(priceLower.toSignificant()),
  token0: undefined,
  token1: createTokenInfo(strike.token1Amount, strike.token1.decimals, strike.token1.symbol),
})

export const calculateTotalUtilizedLP = (withdrawnLP: ResultCalculateWithdrawnLP) => {
  return withdrawnLP.reduce((acc, lp) => {
    if (lp.token0) {
      acc.token0 = {
        size: acc.token0?.size.plus(lp.token0.size) ?? lp.token0.size,
        amount: acc.token0?.amount.plus(lp.token0.amount) ?? lp.token0.amount,
        symbol: lp.token0.symbol,
      }
    }
    if (lp.token1) {
      acc.token1 = {
        size: acc.token1?.size.plus(lp.token1.size) ?? lp.token1.size,
        amount: acc.token1?.amount.plus(lp.token1.amount) ?? lp.token1.amount,
        symbol: lp.token1.symbol,
      }
    }
    return acc
  }, {} as { token0?: { size: BN; amount: BN; symbol: string }; token1?: { size: BN; amount: BN; symbol: string } })
}

export const calculateTotalLPValue = (
  totalLP: {
    token0?: { amount: BN }
    token1?: { amount: BN }
  },
  poolTick: { token0: PoolToken | undefined; token1: PoolToken | undefined },
  ethPriceUSD: BN | undefined,
  assetToken?: PoolToken | Token | undefined
) => {
  const lpValue =
    !!poolTick.token0 && !!poolTick.token1 && !!ethPriceUSD
      ? convertTotalAmountEthAndUsd(
          // JSBI.BigInt(totalLP.token0?.amount.toFixed(0, BN.ROUND_DOWN) ?? 0),
          // JSBI.BigInt(totalLP.token1?.amount.toFixed(0, BN.ROUND_DOWN) ?? 0),
          totalLP.token0?.amount ?? new BN(0),
          totalLP.token1?.amount ?? new BN(0),
          poolTick.token0,
          poolTick.token1,
          ethPriceUSD
        )
      : undefined

  return {
    totalValueAsset: assetToken
      ? (lpValue?.totalAmountETH ?? new BN(0)).div(assetToken?.derivedETH ?? 1)
      : null,
    totalValueUSD: lpValue?.totalAmountUSD ?? new BN(0),
  }
}
