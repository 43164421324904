import { IconInfo } from '@apps-orangefi/ui/molecules/icons'
import { twMerge } from 'tailwind-merge'

type Props = {
  label: string
  value: string
  type?: 'vertical' | 'horizontal'
  tooltipText?: string
  className?: string
}

export const Stats = ({ label, value, type = 'vertical', className = '', tooltipText }: Props) => {
  const [componentClass, labelClass, valueClass] =
    type === 'vertical'
      ? ['flex-col', '', 'mt-1 md:mt-3']
      : ['flex-row justify-between items-center', '', '']

  return (
    <div
      className={twMerge(
        'flex box-border dark:bg-gray-850 bg-cards rounded-2xl px-4 py-3 md:px-5 md:py-4 w-full',
        componentClass,
        className
      )}
    >
      <div className={twMerge('type-sm-normal text-secondary font-inter', labelClass)}>
        <div className="flex flex-row items-center">
          <span className="leading-4">{label}</span>
          {tooltipText && (
            <IconInfo
              tooltipText={tooltipText}
              place="top"
              arrow={false}
              size={16}
              className="ml-2"
            />
          )}
        </div>
      </div>
      <div className={twMerge('type-xl-medium text-primary font-inter', valueClass)}>{value}</div>
    </div>
  )
}
