import { BN } from '@apps-orangefi/lib'
import { BNAmount } from '@apps-orangefi/lib/types'

export const bigintToBN = (value: bigint | undefined): BN => {
  return new BN(value?.toString() ?? 0)
}

export const BNAmountToBigint = (amount: BNAmount): bigint => {
  return amount.value.pow10(amount.decimals).convertBigint()
}
