type SvgProps = {
  className?: string
}

export function SwapFeeIcon({ className }: SvgProps) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.33301 13.1667H14.6663M14.6663 13.1667L11.333 9.83333M14.6663 13.1667L11.333 16.5M14.6663 4.83333H1.33301M1.33301 4.83333L4.66634 1.5M1.33301 4.83333L4.66634 8.16667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
