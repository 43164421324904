import React from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  value: string
  prefix?: string
  suffix?: string
  disabled?: boolean
  className?: string
  wrapperClassName?: string
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void
}

export const TextField = ({
  value,
  prefix,
  suffix,
  disabled = false,
  className = '',
  wrapperClassName = '',
  onChange,
}: Props) => {
  const suffixLength = suffix?.length ?? 0
  const offset = suffixLength * 0.6 + 2
  const style = { '--suffix-offset': `${offset}rem` } as React.CSSProperties

  const onFocusClear = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '0') {
      e.target.value = ''
    }
  }
  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      e.target.value = '0'
    }
  }

  return (
    <div
      className={twMerge(
        'flex flex-col justify-center',
        wrapperClassName,
        disabled ? 'opacity-60' : ''
      )}
    >
      {prefix && (
        <span className="inline-block type-base-medium dark:text-white text-primary absolute self-start ml-5">
          {prefix}
        </span>
      )}
      <input
        type="text"
        value={value}
        onChange={e => {
          if (onChange) {
            onChange(e)
          }
        }}
        onFocus={e => onFocusClear(e)}
        onBlur={e => onBlur(e)}
        disabled={disabled}
        style={style}
        className={twMerge(
          `dark:bg-gray-800 border border-base focus:border-focus focus:border-accent type-base-medium dark:text-gray-500 h-12 px-5 py-8 pr-[var(--suffix-offset)] rounded-2xl text-right ${
            disabled ? 'cursor-not-allowed' : ''
          } ${className}`
        )}
      />
      {suffix && (
        <span className="inline-block text-style-form absolute self-end mr-5">{suffix}</span>
      )}
    </div>
  )
}
