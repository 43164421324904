import strykeImageUrl from '@apps-orangefi/assets/images/products/stryke/concept.svg'
import beraTokenImageUrl from '@apps-orangefi/assets/images/tokens/bera.svg'
import honeyTokenImageUrl from '@apps-orangefi/assets/images/tokens/honey.svg'
import {
  ProductInfo,
  category,
  strategy,
  vaultVersion,
  amm,
  derivative,
  productType,
  ProductKey,
  productKey,
  merklRewardStatus,
  BartioProductKey,
} from '@apps-orangefi/lib/types'

export const productInfoList: { [key in BartioProductKey]: ProductInfo } = {
  //
  // Bera bArtio
  //
  [productKey.StrykeDeprecatedHoneyUsdcVault]: {
    productName: 'HONEY-USDC(deprecated)',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing USDC into the Orange Stryke HONEY/USDC vault provides liquidity to the Stryke HONEY/USDC pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: honeyTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.Swap,
    platform: {
      amm: amm.KODIAK,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykeHoneyUsdcVault]: {
    productName: 'HONEY-USDC',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing USDC into the Orange Stryke HONEY/USDC vault provides liquidity to the Stryke HONEY/USDC pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: honeyTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.KODIAK,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykeWberaHoneyVault]: {
    productName: 'WBERA-HONEY',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing WBERA into the Orange Stryke WBERA/HONEY vault provides liquidity to the Stryke WBERA/HONEY pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: beraTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.KODIAK,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
}
