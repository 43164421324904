import { Button, ErrorMessage, TextField } from '@apps-orangefi/ui/atoms'
import { RadioGroup } from '@headlessui/react'
import { Transition } from '@headlessui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash'
import { ChevronLeft } from 'lucide-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import { z } from 'zod'

type Props = {
  selected: number
  onChange: (value: number) => void
  onClose: () => void
  show: boolean
  className?: string
}

const presetSlippageTolerance = [0.1, 0.5, 1] as const
type PresetSlippageTolerance = typeof presetSlippageTolerance[number]

const slippageToleranceOptions: (PresetSlippageTolerance | 'custom')[] = [0.1, 0.5, 1, 'custom']

const schema = z.object({
  customValue: z
    .string()
    .transform(value => Number(value))
    .refine(value => !isNaN(value), { message: 'Please enter a valid number.' })
    .refine(value => value >= 0, { message: 'Value must be greater than or equal to 0.' })
    .refine(value => value <= 100, { message: 'Value must be less than or equal to 100.' })
    .refine(
      value => {
        const decimalPart = value.toString().split('.')[1]
        return !decimalPart || decimalPart.length <= 3
      },
      { message: 'Value must have at most 3 decimal places.' }
    ),
})
type FormData = z.infer<typeof schema>

export const SlippageToleranceForm = ({ selected, onChange, onClose, show, className }: Props) => {
  const [radio, setRadio] = useState<PresetSlippageTolerance | 'custom'>(0.5)
  const formRef = useRef<HTMLDivElement>(null)

  const {
    control,
    watch,
    formState: { isValid },
    setValue,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  })

  const watchCustomValue = watch('customValue')

  const onSubmit = useCallback(
    (customValue: number) => {
      if (radio === 'custom') {
        if (!customValue) return
        onChange(Number(customValue))
      } else {
        onChange(radio)
      }
      onClose()
    },
    [radio]
  )

  const isPresetValue = useMemo(() => presetSlippageTolerance.some(sp => sp === radio), [radio])

  useEffect(() => {
    const _selected = isPresetValue ? (selected as PresetSlippageTolerance) : 'custom'
    setRadio(_selected)
    setValue('customValue', isPresetValue ? 0 : selected)
  }, [])

  const updateHeight = useCallback(() => {
    if (!formRef.current || !show) return

    const height = formRef.current?.offsetHeight
    const parentElement = formRef.current.parentElement?.parentElement
    if (height && parentElement) {
      parentElement.style.height = `${height}px`
      parentElement.style.transition = 'height 300ms ease-in-out'
    }
  }, [show])

  useEffect(() => {
    if (!formRef.current || !show) return

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateHeight)
    })
    resizeObserver.observe(formRef.current)
    updateHeight()

    return () => {
      if (formRef.current) {
        resizeObserver.unobserve(formRef.current)
      }
    }
  }, [show, updateHeight])

  useEffect(() => {
    const timeoutId = setTimeout(updateHeight, 0)
    return () => clearTimeout(timeoutId)
  }, [radio, updateHeight, isValid])

  return (
    <Transition
      show={show}
      enter="transition-all ease-in-out duration-300"
      enterFrom="opacity-0 -translate-x-full"
      enterTo="opacity-100 translate-x-0"
      leave="transition-all ease-in-out duration-300"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 -translate-x-full"
      className={twMerge('absolute inset-0 bg-cards z-20', className)}
      beforeLeave={() => {
        const parentElement = formRef.current?.parentElement?.parentElement
        if (parentElement) {
          parentElement.style.removeProperty('height')
          parentElement.style.removeProperty('transition')
        }
      }}
    >
      <div ref={formRef} className="flex flex-col">
        <div className="flex flex-row justify-start items-center gap-1.5 text-primary px-5 py-4">
          <ChevronLeft size={20} onClick={onClose} className="cursor-pointer" />
          <div className="type-sm-semibold">Slippage tolerance</div>
        </div>

        <div className="bg-cards-foreground w-full">
          <RadioGroup value={radio} onChange={setRadio} className="flex flex-row">
            {slippageToleranceOptions.map((option, i) => (
              <RadioGroup.Option
                value={option}
                key={i}
                className={({ checked }) =>
                  `${
                    checked ? 'bg-cards rounded' : 'hover:cursor-pointer'
                  } px-2 py-4 w-1/4 text-center my-0.5`
                }
              >
                {option === 'custom' ? option : `${option}%`}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>

        <div className="p-5">
          {radio === 'custom' && (
            <div className="mb-4">
              <Controller
                name="customValue"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div className="flex flex-col">
                    <TextField
                      value={value?.toString()}
                      onChange={onChange}
                      className="w-full bg-transparent font-inter text-md font-medium text-left px-4 py-3 md:pr-5 pl-5 border border-border bg-background rounded-2xl"
                    />
                    <ErrorMessage message={error?.message} />
                  </div>
                )}
              />
            </div>
          )}
          <Button
            label="Confirm"
            disabled={!isPresetValue && (isEmpty(watchCustomValue) || !isValid)}
            onSubmit={() => {
              onSubmit(watchCustomValue)
            }}
            className="w-full"
          />
        </div>
      </div>
    </Transition>
  )
}
