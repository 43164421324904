import Skeleton from 'react-loading-skeleton'

export const VaultInfoSkeleton = () => {
  return (
    <div className="flex flex-row w-full justify-between -mt-5">
      <div className="flex flex-col md:flex-row mt-6 md:mt-12 w-full md:items-end">
        <div className="flex flex-col">
          <h2 className="type-base-semibold dark:text-orange-500 md:text-2xl leading-8">
            <Skeleton width={370} />
          </h2>
          <div className="flex flex-row items-center mt-3 mb-2 md:mt-5 md:mb-0">
            <div className="w-10 h-10 md:w-14 md:h-14">
              <Skeleton circle className="w-10 h-10 md:w-14 md:h-14" />
            </div>
            <h1 className="dark:text-white text-3xl md:text-7xl font-bold leading-9 md:leading-[4.5rem] ml-5">
              <Skeleton width={400} />
            </h1>
          </div>
        </div>
      </div>
      <Skeleton circle width={196} className="w-24 h-24 self-end md:w-48 md:h-48 md:self-auto" />
    </div>
  )
}
