import Image from 'next/image'
import { memo } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  text?: string
  icon?: JSX.Element
  iconUrl?: any
  isActive?: boolean
  onSubmit?: () => void
  children?: JSX.Element
  className?: string
}

export const SimpleButton = memo(function SimpleButton({
  text,
  icon,
  iconUrl,
  isActive,
  onSubmit,
  children,
  className,
}: Props) {
  const defaultTypeClass = className && className.includes('type-') ? '' : 'type-base-medium'

  const bgClass = isActive ? 'dark:bg-gray-750' : 'dark:bg-gray-850'
  return (
    <button
      type="button"
      className={twMerge(
        'w-fit dark:text-white py-3 px-4 flex flex-row items-center hover:opacity-80 rounded-2xl',
        defaultTypeClass,
        bgClass,
        className
      )}
      onClick={() => {
        if (onSubmit) {
          onSubmit()
        }
      }}
    >
      <>
        {!!icon && <span className="mr-2">{icon}</span>}
        {!!iconUrl && <Image src={iconUrl} alt={text ?? ''} className="w-6 h-6 mr-3" />}
        {!!text && <span className="tracking-tighter">{text}</span>}
        {/* {!!children && { children }} */}
        {children}
      </>
    </button>
  )
})
