import { useSimulateRedeem, useStrykeQuery } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { getDopexStrikeListQuery } from '@apps-orangefi/lib/subgraph/queries'
import { GetDopexStrikeListQuery } from '@apps-orangefi/lib/subgraph/types/dopex/graphql'
import { Token, PoolToken, ResultWithdrawSimulation } from '@apps-orangefi/lib/types'
import { DopexLpShareWithPrice, DopexLpAsset } from '@apps-orangefi/lib/types/stryke-lp'
import {
  convertTicksToPriceRange,
  getToken,
  bigintToBN,
  bnComparator,
} from '@apps-orangefi/lib/utils'
import { convertBNToString } from '@apps-orangefi/lib/utils/debug'
import {
  calculateTokenPairAmounts,
  calculateTotalLPValue,
  calculateTotalUtilizedLP,
  calculateWithdrawnLP,
} from '@apps-orangefi/lib/utils/utilized-lp-calculator'
import {
  useConvertLpSharesToAssets,
  useGetTokenPairAmounts,
} from '@apps-orangefi/wagmi/hooks/stryke'
import { tickToPrice } from '@uniswap/v3-sdk'
import { omit, chain, zipWith, isNil, isEqual, isEqualWith } from 'lodash'
import { useMemo, useState, useEffect, useCallback } from 'react'

export const useSimulateWithdrawLPDfi = (
  vaultAddress: AddressType | undefined,
  account: AddressType | undefined,
  shares: BN,
  vaultDecimals: number | null,
  chainId: number,
  poolTick: {
    token0: PoolToken | undefined
    token1: PoolToken | undefined
    tick: number | undefined
  },
  vaultBaseToken: Token | undefined,
  ethPriceUSD: BN | undefined
) => {
  const [resultWithdrawSimulation, setResultWithdrawSimulation] = useState<
    ResultWithdrawSimulation | undefined
  >(undefined)
  const [dopexLpShareWithPriceList, setDopexLpShareWithPriceList] = useState<
    DopexLpShareWithPrice[]
  >([])
  const [dopexLpAssetList, setDopexLpAssetList] = useState<DopexLpAsset[]>([])
  const [isLpPositionFetching, setIsLpPositionFetching] = useState<boolean>(false)

  const poolBaseToken = useMemo(() => {
    if (!poolTick.token0) return
    return getToken(
      chainId,
      poolTick.token0.address as AddressType,
      Number(poolTick.token0.decimals)
    )
  }, [poolTick.token0])

  const poolQuoteToken = useMemo(() => {
    if (!poolTick.token1) return
    return getToken(
      chainId,
      poolTick.token1.address as AddressType,
      Number(poolTick.token1.decimals)
    )
  }, [poolTick.token1])

  // 1. simulate redeem LP token from vault.
  //    receive estimated withdrable assets and stryke LP shares
  const { simulateWithdrawLPDfi, resultSimulation, hasUtilizedLP, isSimulating } =
    useSimulateRedeem(vaultAddress, shares, vaultDecimals)

  // 2. Fetch strike list from stryke subgraph
  //    to get tickLower, tickUpper to calculate price range
  //    Merge strike and price range with dopexLpShares
  const [resultDopex, _] = useStrykeQuery<GetDopexStrikeListQuery>({
    query: getDopexStrikeListQuery,
    variables: {
      tokenIds: resultSimulation.dopexLpShares.map(dopexLpShare => dopexLpShare.tokenId),
    },
    pause: resultSimulation.dopexLpShares.length === 0,
  })
  const { data: dataDopex, fetching: fetchingDopexSubgraph } = useMemo(
    () => resultDopex,
    [resultDopex]
  )

  useEffect(() => {
    const _shareList = chain(dataDopex?.strikes)
      .map(strike => {
        const dopexLpShare = resultSimulation.dopexLpShares.find(
          dopexShare => dopexShare.tokenId === strike.id
        )
        if (dopexLpShare) {
          if (!!poolBaseToken && !!poolQuoteToken) {
            const { priceLower, priceUpper } = convertTicksToPriceRange(
              strike.tickLower,
              strike.tickUpper,
              poolBaseToken,
              poolQuoteToken
            )

            return {
              ...dopexLpShare,
              ...omit(strike, ['id', '__typename']),
              token0: {
                address: strike.token0.id as AddressType,
                symbol: strike.token0.symbol,
                decimals: Number(strike.token0.decimals),
              },
              token1: {
                address: strike.token1.id as AddressType,
                symbol: strike.token1.symbol,
                decimals: Number(strike.token1.decimals),
              },
              priceLower,
              priceUpper,
            }
          }
        }
      })
      .compact()
      .value()

    if (!isEqualWith(_shareList, dopexLpShareWithPriceList, bnComparator)) {
      setDopexLpShareWithPriceList(_shareList)
    }
  }, [dataDopex, resultSimulation.dopexLpShares, poolBaseToken, poolQuoteToken])

  // 3. Convert LP shares to assets by using stryke handler contract
  const convertToAssetsParams = dopexLpShareWithPriceList.map(share => ({
    handler: share.handler,
    share: share.share,
    tokenId: share.tokenId,
  }))

  const { assetList, isFetching: isAssetFetching } =
    useConvertLpSharesToAssets(convertToAssetsParams)

  useEffect(() => {
    const _list = zipWith(
      dopexLpShareWithPriceList,
      assetList,
      (dopexShare: DopexLpShareWithPrice, asset: bigint) => {
        return {
          ...dopexShare,
          asset: bigintToBN(asset),
        }
      }
    )
    if (!isEqualWith(_list, dopexLpAssetList, bnComparator)) {
      setDopexLpAssetList(_list)
    }
  }, [JSON.stringify(dopexLpShareWithPriceList), JSON.stringify(assetList)])

  // 4. Fetch token pair amounts from stryke handler contract
  const tokenPairAmountsParams = dopexLpAssetList.map(asset => ({
    handler: asset.handler,
    pool: asset.pool,
    tickLower: asset.tickLower,
    tickUpper: asset.tickUpper,
    asset: asset.asset,
  }))

  const { lpAmountData, isFetching: isLpAmountFetching } =
    useGetTokenPairAmounts(tokenPairAmountsParams)

  // 5. Calculate withdrawable token pair amounts
  useEffect(() => {
    if (
      !poolBaseToken ||
      !poolQuoteToken ||
      isNil(poolTick.tick) ||
      !resultSimulation.withdrawnAssets
    ) {
      return
    }

    const currentPrice = tickToPrice(poolBaseToken, poolQuoteToken, poolTick.tick)
    const withdrawnAssets = resultSimulation.withdrawnAssets

    const strikeTokenAmountList = calculateTokenPairAmounts(lpAmountData, dopexLpAssetList)
    const withdrawnLP = calculateWithdrawnLP(strikeTokenAmountList, currentPrice)
    const totalUtilizedLP = calculateTotalUtilizedLP(withdrawnLP)
    const { totalValueAsset: totalUtilizedBaseAsset } = calculateTotalLPValue(
      totalUtilizedLP,
      poolTick,
      ethPriceUSD,
      vaultBaseToken
    )

    const totalValue = withdrawnAssets.plus(totalUtilizedBaseAsset ?? 0)
    const _result = {
      withdrawnAssets,
      withdrawnLP,
      totalUtilizedLP,
      totalValue,
    }
    if (!isEqualWith(_result, resultWithdrawSimulation, bnComparator)) {
      setResultWithdrawSimulation(_result)
    }
  }, [
    resultSimulation,
    lpAmountData,
    dopexLpAssetList,
    poolBaseToken,
    poolQuoteToken,
    poolTick,
    ethPriceUSD,
    vaultBaseToken,
  ])

  useEffect(() => {
    const _isLpPositionFetching = fetchingDopexSubgraph || isAssetFetching || isLpAmountFetching
    if (_isLpPositionFetching !== isLpPositionFetching) {
      setIsLpPositionFetching(_isLpPositionFetching)
    }
  }, [fetchingDopexSubgraph, isAssetFetching, isLpAmountFetching])

  const resetResultWithdrawSimulation = useCallback(() => {
    setResultWithdrawSimulation(undefined)
  }, [])

  return {
    simulateWithdrawLPDfi,
    resultWithdrawSimulation,
    hasUtilizedLP,
    isSimulating,
    isLpPositionFetching,
    resetResultWithdrawSimulation,
  }
}
