import OrangeLogo from '@apps-orangefi/assets/images/logo/logo-symbol.svg'
import Logo from '@apps-orangefi/assets/images/logo/logo-white.svg'
import IconLPDfi from '@apps-orangefi/assets/images/menus/lpdfi.svg'
import IconAstronaut from '@apps-orangefi/assets/images/menus/trophy.svg'
// import OrangeLogo from '@apps-orangefi/assets/images/orange-logo.svg'
import { usePathByChain } from '@apps-orangefi/hooks'
import { MenuButton } from '@apps-orangefi/ui/atoms/buttons'
import { Library } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

type Props = {
  currentPath: string
  pointsUrlBase: string
}

export const AppFooter = ({ currentPath, pointsUrlBase }: Props) => {
  const { t } = useTranslation()
  const path = usePathByChain()

  return (
    <div className="flex flex-col bg-main items-start pt-8 pb-8 md:pt-12">
      <div className="container px-8">
        <div className="flex flex-row justify-between items-center">
          <Image src={Logo} alt="Orange Finance" width={146} height={32} />
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col md:flex-row mt-8 type-base-medium">
            <a
              href="https://github.com/orange-finance"
              target="_blank"
              rel="noreferrer"
              className="mr-10"
            >
              {t('FOOTER.SOCIAL.LINK1')}
            </a>
            <a
              href="https://twitter.com/0xOrangeFinance"
              target="_blank"
              rel="noreferrer"
              className="mr-10"
            >
              {t('FOOTER.SOCIAL.LINK2')}
            </a>
            <a
              href="https://mirror.xyz/0x6FA2aF9a4d6fFe654361F713780963C10412e7c3"
              target="_blank"
              rel="noreferrer"
              className="mr-10"
            >
              {t('FOOTER.SOCIAL.LINK3')}
            </a>
            <a
              href="https://discord.com/invite/mNT399UnSd"
              target="_blank"
              rel="noreferrer"
              className="mr-10"
            >
              {t('FOOTER.SOCIAL.LINK4')}
            </a>
          </div>
          <div className="flex flex-col md:flex-row md:justify-end type-base-semibold dark:text-gray-200 relative md:-top-8 mt-8 md:mt-0">
            <Link href="/" className="mr-10">
              {t('FOOTER.ABOUT.LINK1')}
            </Link>
            <a
              href="https://orange-finance.gitbook.io/orange-finance/"
              target="_blank"
              rel="noreferrer"
              className="mr-10"
            >
              {t('FOOTER.ABOUT.LINK2')}
            </a>
            <a
              href="https://orange-finance.gitbook.io/orange-finance/risk-and-security/audit"
              target="_blank"
              rel="noreferrer"
            >
              {t('FOOTER.ABOUT.LINK3')}
            </a>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between sm:items-center mt-10 text-frenchGray text-sm">
          <div className="">2023 Orange Finance All right reserved.</div>
          <ul className="flex flex-col sm:flex-row whitespace-nowrap">
            <li className="mt-3 sm:mt-0">
              <a
                href="https://orange-finance.gitbook.io/orange-finance/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li className="mt-3 sm:ml-4 sm:mt-0">
              <a
                href="https://orange-finance.gitbook.io/orange-finance/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
            </li>
          </ul>
        </div>
      </div>

      {path === 'arbitrum' && (
        <div className="fixed md:hidden bottom-0 w-full p-1 z-20">
          <div className="flex flex-row bg-dark-980 w-full justify-between p-1">
            <MenuButton
              label={t('MENU.LIQUIDITY_VAULTS')}
              logoUrl={IconLPDfi}
              href="/arbitrum"
              isActive={currentPath === '/arbitrum'}
              className="w-[74px]"
            />
            <MenuButton
              label={t('MENU.POINTS')}
              logoUrl={OrangeLogo}
              href={pointsUrlBase}
              isTargetBlank={false}
              className="ml-2"
            />
            <MenuButton
              label={t('MENU.LEGACY')}
              iconElement={<Library className="w-5 h-5" />}
              href="https://legacy.orangefinance.io"
              isTargetBlank={false}
              className="w-[74px]"
            />
            {/* <MenuButton
            label={t('MENU.ASTRONAUT')}
            logoUrl={IconAstronaut}
            href="/astronaut"
            isActive={currentPath === '/astronaut'}
            className="w-[74px]"
          /> */}
          </div>
        </div>
      )}
    </div>
  )
}
