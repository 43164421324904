import { Button } from '@apps-orangefi/ui/atoms'
import { AlertOctagon } from 'lucide-react'
import { ChevronDown } from 'lucide-react'
import useTranslation from 'next-translate/useTranslation'
import { twMerge } from 'tailwind-merge'

type Props = {
  networkStatus: {
    isConnected: boolean
    isActiveChainSupported: boolean
    isActiveChainIsSelected: boolean
  }
  shortenAddress: string
  openConnectWalletModal: () => void
  openAccountModal: () => void
  openSwitchChainModal: () => void
  className?: string
}

const ConnectWalletButton = ({
  networkStatus: { isConnected, isActiveChainSupported },
  shortenAddress,
  openConnectWalletModal,
  openAccountModal,
  openSwitchChainModal,
  className,
}: Props) => {
  const { t } = useTranslation()
  return (
    <div>
      {isConnected ? (
        <>
          {isActiveChainSupported ? (
            <Button
              label={
                <div className="flex flex-row items-center">
                  <span>{shortenAddress}</span>
                  <ChevronDown size={16} className="ml-2" />
                </div>
              }
              className={twMerge(
                'dark:bg-dark-800 dark:text-white px-4 py-3 rounded-2xl whitespace-nowrap',
                className
              )}
              onSubmit={openAccountModal}
            />
          ) : (
            <Button
              label={
                <span className="flex items-center">
                  <span className={'text-accent mr-1.5'}>
                    <AlertOctagon size={20} />
                  </span>
                  Wrong network
                </span>
              }
              className={twMerge(
                'bg-white text-black px-4 py-3 rounded-2xl whitespace-nowrap',
                className
              )}
              onSubmit={openSwitchChainModal}
            />
          )}
        </>
      ) : (
        <Button
          label={t('WALLET.CONNECT_WALLET')}
          className={twMerge(
            'dark:bg-orange-500 dark:text-white font-bold font-urbanist px-4 py-3 rounded-2xl whitespace-nowrap',
            className
          )}
          onSubmit={() => openConnectWalletModal()}
        />
      )}
    </div>
  )
}
export default ConnectWalletButton
