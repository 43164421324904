import { useReserveLPFormWithPositionManager } from '@apps-orangefi/hooks'
import { WithdrawnLP } from '@apps-orangefi/wagmi/hooks'
import { useCallback } from 'react'
import { useAccount } from 'wagmi'
import { Chain } from 'wagmi/chains'

export const useReserveStrykeLp = ({
  positionManagerAddress,
  handlerAddress,
  chain,
  lps,
}: {
  positionManagerAddress: AddressType | undefined
  handlerAddress: AddressType | undefined
  chain: Chain | undefined
  lps: WithdrawnLP[]
}) => {
  const { address: account, isConnected } = useAccount()

  const {
    onReserveLP,
    isReserveReady,
    isReservedLiquidity,
    hasReserveLP,
    hasNotReservedLiquidity,
    isSubmitted: isReserveLPSubmitted,
    isWaiting: isReserveLPWaiting,
    txReserveLiquidityAtom,
    resetTx: resetReserveTx,
    initTxList,
  } = useReserveLPFormWithPositionManager({
    lastWithdrawnLPs: lps,
    isEnabledReserve: isConnected,
    positionManagerAddress,
    handlerAddress,
  })

  const onReserve = useCallback(() => {
    resetReserveTx()
    initTxList()
    onReserveLP()
  }, [onReserveLP])

  return {
    onReserve,
    isReserveLPWaiting,
    isReservedLiquidity,
    isReserveLPSubmitted,
    isReserveReady,
  }
}
