import CheckCircle from '@apps-orangefi/assets/images/check-circle.svg'
import { BN } from '@apps-orangefi/lib'
import { UserPosition } from '@apps-orangefi/lib/types'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { TextField, Button, TagButton, ErrorMessage, Position } from '@apps-orangefi/ui/atoms'
import { IconInfo } from '@apps-orangefi/ui/molecules/icons'
import { WalletProps } from '@apps-orangefi/ui/molecules/strategy'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty, isEqual } from 'lodash'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'

export type DepositProps = {
  depositAmount: BN
  minDepositAmount?: BN
  depositCap: BN
  tokenBalance: BN | undefined
  remainingCapacity: BN
  onSetDepositAmount: (amount: BN) => void
  onDeposit: (data: { amount: string }) => void
  onSetMaxBalance: () => void
  isShowDepositCap: boolean
  protcolFee: {
    deposit: number
    performance: number
  }
  tokenSymbol: string | undefined
}

type Props = { deposit: DepositProps; wallet: WalletProps }

export const DepositForm = ({
  deposit: {
    depositAmount: amount,
    minDepositAmount: minAmount,
    depositCap,
    tokenBalance,
    remainingCapacity,
    protcolFee,
    onSetDepositAmount,
    onDeposit: onSubmit,
    onSetMaxBalance: onClickMax,
    isShowDepositCap,
    tokenSymbol,
  },
  wallet: {
    onConnect,
    onShowInvalidAddressModal,
    onSwitchChainModal,
    isConnected,
    isActiveChainSupported,
  },
}: Props) => {
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)
  const [editable, setEditable] = useState(false)

  const schema = z.object({
    amount: z.string().superRefine((value, ctx) => {
      const amountValue = new BN(value)

      if (!isEmpty(value) && amountValue.isNaN()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Amount must be a valid Value',
        })
      } else if (amountValue.isNegative()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Amount must be positive',
        })
      } else if (!!minAmount && amountValue.lt(minAmount)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Amount must be at least ${minAmount.toFixed()} ${tokenSymbol} or more`,
        })
      } else if (amountValue.gt(tokenBalance ?? new BN(0))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Balance is insufficient',
        })
      } else if (amountValue.gt(remainingCapacity)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Deposits exceeding the maximum capacity are not accepted',
        })
      }
    }),
  })

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: '0',
    },
    resolver: zodResolver(schema),
  })
  const watchAmount = watch('amount')

  useEffect(() => {
    if (amount.gt(0)) {
      setValue('amount', amount.toFixed())
    }
  }, [amount, setValue])

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    setEditable(isConnected && isActiveChainSupported)
  }, [isConnected, isActiveChainSupported])

  useEffect(() => {
    const newAmount = new BN(watchAmount)
    if (!newAmount.isNaN() && !newAmount.isEqualTo(amount)) {
      onSetDepositAmount(new BN(watchAmount))
    }
  }, [watchAmount])

  const enabled = editable && Number(watchAmount) > 0

  return (
    <div className="flex flex-col p-5">
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-baseline">
            <label className="type-sm-medium dark:text-gray-400">
              {!!tokenSymbol && <>{t('MODEL.DEPOSIT.AMOUNT')}</>}
            </label>
            <div className="flex flex-row type-sm-medium dark:text-gray-400">
              <span>{t('MODEL.USER.BALANCE')}:</span>
              <span className="ml-2 font-mono">{numberWithShortScale(tokenBalance, 3)}</span>
            </div>
          </div>
          {mounted && (
            <form className="flex flex-col mt-2" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="amount"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="flex flex-col justify-center relative">
                    <TextField
                      value={value.toString()}
                      prefix={tokenSymbol}
                      onChange={onChange}
                      disabled={!editable}
                      className="dark:bg-gray-800 border-gray-750 font-mono text-md font-medium text-left px-4 py-3 md:pr-5 pl-24 rounded-lg md:rounded-xl"
                    />
                    <TagButton
                      label="MAX"
                      disabled={false}
                      onClick={onClickMax}
                      className="absolute right-0 mr-2 md:mr-5 py-1.5 px-3 rounded-lg"
                    />
                  </div>
                )}
              />
              <ErrorMessage message={errors.amount?.message} />
              {isConnected && isActiveChainSupported && (
                <Button
                  className="self-center w-full mt-2 px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
                  label={t('WIDGET.ACTION.DEPOSIT')}
                  disabled={!enabled}
                  onSubmit={onShowInvalidAddressModal}
                />
              )}
            </form>
          )}
          {mounted && isConnected && !isActiveChainSupported && (
            <Button
              className="self-center w-full mt-2 px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
              label={t('WIDGET.ACTION.SWITCH_NETWORK')}
              onSubmit={onSwitchChainModal}
            />
          )}
          {mounted && !isConnected && (
            <Button
              onSubmit={onConnect}
              className="self-center w-full mt-2 px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
              label={t('WIDGET.ACTION.CONNECT')}
            />
          )}
          <div className="flex flex-col mt-3 px-2 py-2">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center type-sm-medium dark:text-gray-400">
                <span className="mr-2">{t('MODEL.DEPOSIT.DEPOSIT_FEE')}</span>
                <IconInfo size={16} tooltipText={t('MODEL.DEPOSIT.HINTS.DEPOSIT_FEE')} />
              </div>
              <div className="type-sm-semibold dark:text-white font-mono">
                {protcolFee.deposit.toFixed(1)}
                <span className="ml-0.5">%</span>
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center type-sm-medium dark:text-gray-400">
                <span className="mr-2">{t('MODEL.DEPOSIT.PERFORMANCE_FEE')}</span>
                <IconInfo size={16} tooltipText={t('MODEL.DEPOSIT.HINTS.PERFORMANCE_FEE')} />
              </div>
              <div className="type-sm-semibold dark:text-white font-mono">
                {protcolFee.performance.toFixed(1)}
                <span className="ml-0.5">%</span>
              </div>
            </div>
          </div>
          {isShowDepositCap && (
            <div className="flex flex-row justify-center items-center mt-2 py-3">
              <IconInfo size={16} />
              <span className="type-sm-medium dark:text-gray-300 ml-2 font-mono">
                {t('MODEL.USER.MAX_INDIVIDUAL_CAPACITY', {
                  capacity: numberWithShortScale(depositCap, 0),
                  unit: tokenSymbol,
                })}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
